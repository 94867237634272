import { createSlice } from "@reduxjs/toolkit";
import { setFeedback } from "./feedbackSlice";
import axios from "axios";
axios.defaults.withCredentials = true;

const initialState = {
  isLoading: false,
  success: false,
  error: null,
};

const changePasswordSlice = createSlice({
  name: "changePassword",
  initialState,
  reducers: {
    changePasswordStart: (state) => {
      state.isLoading = true;
      state.success = false;
      state.error = null;
    },
    changePasswordSuccess: (state) => {
      state.isLoading = false;
      state.success = true;
      state.error = null;
    },
    changePasswordFailure: (state, action) => {
      state.isLoading = false;
      state.success = false;
      state.error = action.payload;
    },
  },
});

export const {
  changePasswordStart,
  changePasswordSuccess,
  changePasswordFailure,
} = changePasswordSlice.actions;

export default changePasswordSlice.reducer;

export const changePassword = (credentials, token) => async (dispatch) => {
  try {
    dispatch(changePasswordStart());

    const response = await axios.post(
      `${process.env.REACT_APP_API_URL}/users/change-password`,
      credentials,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      }
    );

    const data = response.data;
    dispatch(changePasswordSuccess());
    return { success: true, message: data.message };
  } catch (error) {
    dispatch(changePasswordFailure(error.message));
    dispatch(
      setFeedback({
        status: "fail",
        message: error.response.data.message,
        code: error.response.status,
      })
    );
    return { success: false, error: error.message };
  }
};
