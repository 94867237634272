import { Box, Typography } from "@mui/material";
import React from "react";
import Applicationlist from "./application/applicationlist";

const Applications = () => {
  return (
    <>
      <Box className="content-header">
        <Typography variant="h2" component="h2" className="title-with-btn">
          Applications
        </Typography>
      </Box>
      <Box className="content-layout applist-wrapper">
        <Applicationlist />
      </Box>
    </>
  );
};

export default Applications;
