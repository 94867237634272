import { createSlice } from '@reduxjs/toolkit';
import { setFeedback } from './feedbackSlice';
import axios from 'axios';
axios.defaults.withCredentials = true;

const initialState = {
  isLoading: false,
  success: false,
  error: null,
};

const resetPasswordSlice = createSlice({
  name: 'resetPassword',
  initialState,
  reducers: {
    resetPasswordStart: (state) => {
      state.isLoading = true;
      state.success = false;
      state.error = null;
    },
    resetPasswordSuccess: (state) => {
      state.isLoading = false;
      state.success = true;
      state.error = null;
    },
    resetPasswordFailure: (state, action) => {
      state.isLoading = false;
      state.success = false;
      state.error = action.payload;
    },
  },
});

export const { resetPasswordStart, resetPasswordSuccess, resetPasswordFailure } = resetPasswordSlice.actions;

export default resetPasswordSlice.reducer;

export const resetPassword = (credentials, token) => async (dispatch) => {
  try {
    dispatch(resetPasswordStart());

    const response = await axios.post(
      `${process.env.REACT_APP_API_URL}/users/reset-password`, credentials,
      {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`,
        },
      }
    );

    const data = response.data;
    dispatch(resetPasswordSuccess());
    return { success: true, message: data.message };
  } catch (error) {
    dispatch(resetPasswordFailure(error.response.data.message));
    dispatch(
      setFeedback({
        status: 'fail',
        message: error.response.data.message,
        code: error.response.status,
      })
    );
    return { success: false, error: error.message };
  }
};
