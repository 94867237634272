import {
  Box,
  TextField,
  Typography,
  FormControl,
  Select,
  MenuItem,
} from "@mui/material";
import React, { useCallback } from "react";
import Button from "@mui/material/Button";
import Userlist from "./userlist";
import {
  RiAddCircleLine,
  RiSearchLine,
  RiCloseCircleLine,
  RiArrowDownSLine,
} from "react-icons/ri";
import Skeleton from "@mui/material/Skeleton";
import { useLocation, useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { fetchUsersByType } from "../../store/slices/userSlice";
import getTokenFromLocalStorage from "../../utils/token";
import { useState, useEffect } from "react";

const UserManagement = () => {
  const location = useLocation();
  let { state } = location;
  if (state === null) {
    state = {
      status: 0,
      currentPage: 1,
      rowsPerPage: 10,
      search: "",
      company: "1",
    };
  }
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const token = getTokenFromLocalStorage();
  const [value, setValue] = useState(state.status);
  const [searchUser, setSearchUser] = useState(state.search);
  const [currentPage, setcurrentPage] = useState(state.currentPage);
  const [rowsPerPage, setRowsPerPage] = useState(state.rowsPerPage);
  const [loading, setLoading] = useState(true);
  const [status, setStatus] = useState("1");
  const [company, setCompanyId] = useState(state.company);
  const currentStatus = value === 0 || value === 1 ? "active" : "archive";
  const handleChildValueChange = (newValue) => {
    setValue(newValue);
    setcurrentPage(1)
  };
  const handlePageChange = (newPage) => {
    setcurrentPage(newPage);
  };

  const clearSearchHandler = () => {
    setSearchUser("");
  };

  useCallback(() => {
    if (
      currentPage>1
    ) {

      setcurrentPage(1);
    }
  }, [searchUser]);

  useEffect(() => {
    const userType = value === 0 ? "internal" : value === 1 ? "external" : "";


    dispatch(
      fetchUsersByType({
        userType,
        searchQuery: searchUser,
        token,
        page: currentPage,
        status: currentStatus,
        company,
        rowsPerPage,
      })
    )
      .unwrap()
      .then((data) => {
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
      });
  }, [
    dispatch,
    value,
    searchUser,
    token,

    currentPage,
    rowsPerPage,
    currentStatus,
    company,
  ]);

  const handleCompanyChange = (event) => {
    setCompanyId(event.target.value);

    setcurrentPage(1);
  };

  return (
    <>
      <Box className="content-header users-module-wrapper">
        <Typography variant="h2" component="h2">
          Users
        </Typography>

        <Box variant="div" component="div" className="content-header-right">
          <Select
            id="demo-simple-select"
            className="select-field"
            value={company}
            onChange={handleCompanyChange}
            classNamePrefix="select"
            size="small"
            IconComponent={RiArrowDownSLine}
            sx={{
              width: "225px",
              backgroundColor: "white",
              borderRadius: "8px",
              boxShadow: "0px 4px 4px 0px rgba(0, 0, 0, 0.04)",
              border: "0",
            }}
          >
            <MenuItem value={"1"}>All Companies</MenuItem>
            <MenuItem value={"2"}>Crest Infosystems Pvt. Ltd.</MenuItem>
            <MenuItem value={"3"}>Crest Skillserve Pvt. Ltd.</MenuItem>
          </Select>

          <Box variant="div" component="div" className="search-bar">
            <RiSearchLine />
            <TextField
              fullWidth
              id="outlined-basic"
              label=""
              variant="outlined"
              size="small"
              placeholder="Search by User..."
              onChange={(e) => {
                 if (currentPage > 1 ) {
                  setcurrentPage(1);
                }
                setSearchUser(e.target.value)}}
              value={searchUser}
            />
            {searchUser && (
              <RiCloseCircleLine
                className="cross-line"
                onClick={clearSearchHandler}
              />
            )}
          </Box>

          <Button
            variant="contained"
            size="large"
            className="primary-btn btn add-btn"
            endIcon={<RiAddCircleLine />}
            disableRipple
            onClick={() => navigate("/users/create")}
          >
            <Typography variant="body1" component="p">
              Create User
            </Typography>
          </Button>
        </Box>
      </Box>
      <Box className="content-layout">
        <Userlist
          searchUser={searchUser}
          onValueChange={handleChildValueChange}
          onPageChange={handlePageChange}
          currentPage={currentPage}
          rowsPerPage={rowsPerPage}
          setRowsPerPage={setRowsPerPage}
          currentStatus={currentStatus}
          company={company}
          value={value}
        />
      </Box>
    </>
  );
};

export default UserManagement;
