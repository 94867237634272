import { Box, Fade, Modal, Typography } from "@mui/material";
import React from "react";
import Button from "@mui/material/Button";

const DeleteReleaseNoteModal = ({ isDeleteModalOpen, handleDeleteModalClose, deleteReleaseNoteHandler }) => {
  return (
    <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={isDeleteModalOpen}
        onClose={handleDeleteModalClose}
        closeAfterTransition
      >
        <Fade in={isDeleteModalOpen}>
          <Box className="modal-wrapper modal-ui">
            <Box className="modal-body">
              <Box variant="div" component="div" className="title">
                <Typography variant="h3" component="h3">
                  Do you want to delete this release note?
                </Typography>
                <Typography variant="body1" component="p">
                  This action can’t be undone.
                </Typography>
              </Box>
              <Box variant="div" component="div" className="footer">
                <Box variant="div" component="div" className="btn-group">
                  <Button
                    variant="contained"
                    size="large"
                    className="primary-btn btn"
                    disableRipple
                    onClick={() => deleteReleaseNoteHandler()}
                  >
                    {" "}
                    Delete
                  </Button>
                  <Button
                    variant="contained"
                    size="large"
                    className="secondary-btn btn"
                    disableRipple
                    onClick={handleDeleteModalClose}
                  >
                    {" "}
                    Cancel
                  </Button>
                </Box>
              </Box>
            </Box>
          </Box>
        </Fade>
      </Modal>
  )
}

export default DeleteReleaseNoteModal