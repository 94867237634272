import { Box, Fade, Grid, Modal, TextField, Typography } from "@mui/material";
import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { changePasswordByAdmin } from "../../store/slices/changePasswordByAdminSlice";
import getTokenFromLocalStorage from "../../utils/token";
import { successToast } from "../../responses/successToast";
import { passwordformat } from "../../utils/constant";
import { displayErrorMessage1 } from "../../utils/constant";
import { commonerror } from "../../utils/errormessage";
import { passwordformaterror } from "../../utils/errormessage";
import Button from "@mui/material/Button";

const ChangepasswordModal = ({
  open,
  handleClose,
  selectedUserId,
  username,
}) => {
  const dispatch = useDispatch();
  const [inputName] = useState(
    `password_${Math.random().toString(36).substring(7)}`
  );
  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [erromessage1, setErrorMessage1] = useState("");
  const [erromessage3, setErrorMessage3] = useState("");
  const [erromessage4, setErrorMessage4] = useState("");
  const [erromessage5, setErrorMessage5] = useState("");
  const userId = selectedUserId;
  const passwordRegex = passwordformat();

  const cancelHandler = () => {
    handleClose();
    setConfirmPassword("");
    setNewPassword("");
    setErrorMessage1("");
    setErrorMessage3("");
    setErrorMessage4("");
    setErrorMessage5("");
  };
  const submitHandler = async (event) => {
    event.preventDefault();

    if (!newPassword && !confirmPassword) {
      displayErrorMessage1(commonerror, setErrorMessage3);
      displayErrorMessage1(commonerror, setErrorMessage4);

      return;
    }

    if (!newPassword) {
      displayErrorMessage1(commonerror, setErrorMessage3);
      return;
    }
    if (!confirmPassword) {
      displayErrorMessage1(commonerror, setErrorMessage4);
      return;
    }

    if (!passwordRegex.test(newPassword)) {
      displayErrorMessage1(passwordformaterror, setErrorMessage5);
      return;
    }

    if (newPassword !== confirmPassword) {
      displayErrorMessage1(
        "New and confirm password should be same",
        setErrorMessage5
      );
      return;
    }

    try {
      const token = getTokenFromLocalStorage();

      const credentials = {
        new_password: newPassword,
        confirm_password: confirmPassword,
      };
      const changePasswordResult = await dispatch(
        changePasswordByAdmin(userId, credentials, token)
      );
      if (changePasswordResult.success) {
        successToast(changePasswordResult.message);
        handleClose();
        setConfirmPassword("");
        setNewPassword("");
      } else {
      }
    } catch (error) {
      console.error("Password change failed", error);
    }
  };

  const handleKeyPress = (event) => {
    if (event.key === "Enter") {
      submitHandler(event);
    }
  };

  return (
    <>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={open}
        onClose={handleClose}
        closeAfterTransition
      >
        <Fade in={open}>
          <Box className="modal-wrapper modal-ui">
            <Box className="modal-body">
              <Box variant="div" component="div" className="title">
                <Typography variant="h3" component="h3">
                  Change Password of {username}
                  <Typography variant="body1" component="p" sx={{marginBottom:"0px !important"}}>
                    Strengthen your User's account security with a new password.
                  </Typography>
                </Typography>
              </Box>
              <Box variant="div" component="div" className="body">
                <Grid container spacing={2} mt={1}>
                  <Grid item xs={12} sm={12} md={12} lg={12}>
                    <Box variant="div" component="div">
                      <label>
                        New Password
                        <Typography
                          variant="body1"
                          component="span"
                          className="asterisk"
                        >
                          *
                        </Typography>
                      </label>
                      <TextField
                        fullWidth
                        id="outlined-basic"
                        label=""
                        variant="outlined"
                        placeholder="Enter New Password"
                        size="small"
                        value={newPassword}
                        onChange={(e) => {
                          setNewPassword(e.target.value);
                          if (e.target.value) {
                            setErrorMessage3("");
                          }
                        }}
                        type="password"
                        onKeyPress={handleKeyPress}
                        autoComplete="new-password"
                        name={inputName}
                      />
                    </Box>
                    {erromessage1 && (
                      <Typography variant="body1" component="p" className="error">
                        {erromessage1}
                      </Typography>
                    )}
                    {erromessage3 && (
                      <Typography variant="body1" component="p" className="error">
                        {erromessage3}
                      </Typography>
                    )}
                  </Grid>
                  <Grid item xs={12} sm={12} md={12} lg={12}>
                    <Box variant="div" component="div">
                      <label>
                        Confirm Password
                        <Typography
                          variant="body1"
                          component="span"
                          className="asterisk"
                        >
                          *
                        </Typography>
                      </label>
                      <TextField
                        fullWidth
                        id="outlined-basic"
                        label=""
                        variant="outlined"
                        placeholder="Confirm Password"
                        size="small"
                        value={confirmPassword}
                        onChange={(e) => {
                          setConfirmPassword(e.target.value);
                          if (e.target.value) {
                            setErrorMessage4("");
                            setErrorMessage5("");
                          }
                        }}
                        type="password"
                        onKeyPress={handleKeyPress}
                      />
                    </Box>
                    {erromessage1 && (
                      <Typography variant="body1" component="p" className="error">
                        {erromessage1}
                      </Typography>
                    )}
                    {erromessage4 && (
                      <Typography variant="body1" component="p" className="error">
                        {erromessage4}
                      </Typography>
                    )}
                    {erromessage5 && (
                      <Typography variant="body1" component="p" className="error">
                        {erromessage5}
                      </Typography>
                    )}
                  </Grid>
                </Grid>
              </Box>
              <Box variant="div" component="div" className="footer">
                <Box variant="div" component="div" className="btn-group">
                  <Button
                    variant="contained"
                    size="large"
                    className="primary-btn btn"
                    disableRipple
                    type="submit"
                    onClick={submitHandler}
                  >
                    {" "}
                    Submit
                  </Button>
                  <Button
                    variant="contained"
                    size="large"
                    className="secondary-btn btn"
                    disableRipple
                    onClick={cancelHandler}
                  >
                    {" "}
                    Cancel
                  </Button>
                </Box>
              </Box>
            </Box>
          </Box>
        </Fade>
      </Modal>
    </>
  );
};

export default ChangepasswordModal;
