import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { setFeedback } from './feedbackSlice';
import axios from 'axios';
axios.defaults.withCredentials = true;
// Async thunk action to delete the user by ID
export const deleteUserById = createAsyncThunk(
  'users/deleteUserById',
  async ({userId, token},{dispatch}) => {// Assuming you have a token stored in the auth slice
    try {
      const response = await axios.delete(`${process.env.REACT_APP_API_URL}/users/${userId}`, {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
      });
      return { success: true, message: response.data.message };
    } catch (error) {
      dispatch(
        setFeedback({
          status: "fail",
          message: error.response.data.message,
          code: error.response.status,
        })
      );
      throw new Error(error.response.data.message || 'Failed to delete user.');
    }
  }
);

// Create the user slice with initial state
const deleteByIdSlice = createSlice({
  name: 'deleteUser',
  initialState: {
    loading: false,
    error: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(deleteUserById.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(deleteUserById.fulfilled, (state) => {
        state.loading = false;
        state.error = null;
      })
      .addCase(deleteUserById.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      });
  },
});

export default deleteByIdSlice.reducer;
