import {
  Box,
  Button,
  Grid,
  Select,
  TextField,
  Typography,
  FormControl,
  MenuItem,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { RiArrowLeftLine, RiArrowDownSLine } from "react-icons/ri";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import Skeleton from "@mui/material/Skeleton";
import ReactQuill from "react-quill";
import { useDispatch } from "react-redux";
import { DatePicker } from "rsuite";
import axios from "axios";
import { setFeedback } from "../../store/slices/feedbackSlice";
import { getLocalTimeZone } from "../../utils/timezone";
import { successToast } from "../../responses/successToast";
import "react-quill/dist/quill.snow.css";

const quillModules = {
  toolbar: [
    [{ header: "1" }, { header: "2" }, { font: [] }],
    [{ size: [] }],
    ["bold", "italic", "underline", "strike", "blockquote"],
    [
      { list: "ordered" },
      { list: "bullet" },
      { indent: "-1" },
      { indent: "+1" },
    ],
    ["link", "image", "video"],
    ["clean"],
  ],
  clipboard: {
    matchVisual: false,
  },
};

const versionRegex = /^[\d.]+$/;

export const convertDateIntoYYYYMMDD = (date) => {
  const originalDate = new Date(date);
  const formattedDate = `${originalDate
    .getFullYear()
    .toString()
    .padStart(2, "0")}-${(originalDate.getMonth() + 1)
    .toString()
    .padStart(2, "0")}-${originalDate.getDate().toString().padStart(2, "0")}`;
  return formattedDate;
};

const AddEditReleaseNote = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const location = useLocation();
  const params = useParams();

  const MODE = location.pathname.includes("create")
    ? "ADD"
    : location.pathname.includes("edit")
    ? "EDIT"
    : "";
  if (!MODE) {
    navigate("/release-notes", { replace: true });
  }

  const [appList, setAppList] = useState([]);

  const [form, setForm] = useState({
    application: "",
    version: "",
    date: null,
    description: "",
  });
  const [error, setError] = useState({
    application: "",
    version: "",
    date: "",
    description: "",
  });

  const onChangeApplicationHandler = (e) => {
    setForm((prev) => {
      return { ...prev, application: e.target.value };
    });
    if (e.target.value) {
      setError((prev) => ({ ...prev, application: "" }));
    }
  };
  const onChangeVersionHandler = (e) => {
    setForm((prev) => {
      return { ...prev, version: e.target.value };
    });
    if (e.target.value) {
      setError((prev) => ({ ...prev, version: "" }));
    }
  };
  const onChangeDateHandler = (date) => {
    setForm((prev) => {
      return { ...prev, date: date };
    });
    if (date) {
      setError((prev) => ({ ...prev, date: "" }));
    }
  };
  const onChangeDescriptionHandler = (value) => {
    setForm((prev) => {
      return { ...prev, description: value };
    });
    if (value) {
      setError((prev) => ({ ...prev, description: "" }));
    }
  };

  const resetForm = () => {
    setForm(() => {
      return { application: "", version: "", date: null, description: "" };
    });
    setError(() => {
      return { application: "", version: "", date: "", description: "" };
    });
  };

  const onSubmitHandler = async (e) => {
    try {
      let flag=0
      e.preventDefault();
      setError({ application: "", version: "", date: "", description: "" });

      if (form.application === "") {
        setError((prev) => ({
          ...prev,
          application: "Please select application",
        }));
        flag=1
      }
      if (form.version === "") {
        setError((prev) => ({ ...prev, version: "Please enter version" }));
        flag=1
      }
      if (form.date === "" || form.date === null) {
        setError((prev) => ({ ...prev, date: "Please enter date" }));
        flag=1
      }
      if (form.description === "" || form.description === "<p><br></p>") {
        setError((prev) => ({
          ...prev,
          description: "Please enter description",
        }));
        flag=1
      }

      if (!versionRegex.test(form.version)) {
        setError((prev) => ({
          ...prev,
          version: "Please enter valid version",
        }));
        flag=1
      }

      if(flag!==0){
        return;
      }

      if (MODE === "ADD") {
        const payload = {
          app_id: form.application,
          version_name: `v${form.version}`,
          date: convertDateIntoYYYYMMDD(form.date),
          description: form.description,
          timezone: getLocalTimeZone(),
        };

        const response = await axios.post(
          `${process.env.REACT_APP_API_URL}/release-note`,
          payload,
          {
            headers: {
            },
          }
        );

        if (response.data.status) {
          successToast(response.data.data);
          resetForm();
          navigate("/release-notes");
        } else {
          dispatch(
            setFeedback({
              status: "fail",
              message: response.data.message,
              code: response.status,
            })
          );
        }
      }
      else if (MODE === "EDIT") {
        const payload = {
          new_app_id: form.application,
          new_version_name: `v${form.version}`,
          new_date: convertDateIntoYYYYMMDD(form.date),
          description: form.description,
          timezone: getLocalTimeZone(),
        };

        const response = await axios.patch(
          `${process.env.REACT_APP_API_URL}/release-note/${params.releaseId}`,
          payload,
          {
            headers: {
            },
          }
        );
        if (response.data.status) {
          successToast(response.data.data);
          resetForm();
          navigate("/release-notes");
        } else {
          dispatch(
            setFeedback({
              status: "fail",
              message: response.data.message,
              code: response.status,
            })
          );
        }
      }
    } catch (error) {
      dispatch(
        setFeedback({
          status: "fail",
          message:
            error.response.data.message ||
            error.response.data.data ||
            "Something went wrong",
          code: error.response.status,
        })
      );
    }
  };

  useEffect(() => {
    const fetchApplication = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_API_URL}/applications?fields=id,app_name`,
          {
            headers: {
              "Content-Type": "application/json",
            },
          }
        );
        if (response.data.status) {
          setAppList(response.data.data);
        } else {
          dispatch(
            setFeedback({
              status: "fail",
              message: response.data.message,
              code: response.status,
            })
          );
        }
      } catch (error) {
        dispatch(
          setFeedback({
            status: "fail",
            message: error.response.data.message,
            code: error.response.status,
          })
        );
      }
    };
    const getSingleReleaseNote = async () => {
      try {
        setLoading(true);
        const paramsData = {
          id: params.releaseId,
          timezone: getLocalTimeZone(),
        };

        const response = await axios.get(
          `${process.env.REACT_APP_API_URL}/release-note`,
          { params: paramsData },
          {
            headers: {
              "Content-Type": "application/json",
            },
          }
        );
        if (response.data.status) {
          const data = response.data.data;
          setForm((prev) => {
            return {
              ...prev,
              version: data.version_name.replace("v", ""),
              date: new Date(data.date),
              description: data.description,
              application: data.applications.id,
            };
          });
          setLoading(false);
        } else {
          dispatch(
            setFeedback({
              status: "fail",
              message: response.data.message,
              code: response.status,
            })
          );
        }
      } catch (error) {
        dispatch(
          setFeedback({
            status: "fail",
            message: error.response.data.message,
            code: error.response.status,
          })
        );
      } finally {
        setLoading(false);
      }
    };
    fetchApplication();
    if (MODE === "EDIT") {
      getSingleReleaseNote();
    }
  }, [dispatch, params.releaseId, MODE]);

  return (
    <>
      <Box className="content-header">
        <Typography variant="h2" component="h2" className="title-with-btn">
          {" "}
          <Button
            variant="outlined"
            className="back-btn"
            startIcon={<RiArrowLeftLine />}
            disableRipple
            onClick={() => navigate("/release-notes")}
          ></Button>{" "}
          {MODE === "ADD" ? "Create" : MODE === "EDIT" && "Edit"} Release Note
        </Typography>
      </Box>
      <Box className="content-layout release-form-wrapper">
        <Box className="p-24 create-release-note-wrapper">
          <Grid container spacing={2}>
            <Grid item xs={12} sm={12} md={4} lg={4}>
              <Box variant="div" component="div">
                <label>
                  Application
                  <Typography
                    variant="body1"
                    component="span"
                    className="asterisk"
                  >
                    *
                  </Typography>
                </label>
                {loading ? (
                  <Skeleton variant="rect" width="100%" height={40} />
                ) : (
                  <FormControl fullWidth>
                    <Select
                      value={form.application}
                      onChange={onChangeApplicationHandler}
                      placeholder="Select application"
                      id="demo-simple-select"
                      size="small"
                      IconComponent={RiArrowDownSLine}
                    >
                      {appList.map((item) => (
                        <MenuItem key={item.id} value={item.id}>
                          {item.app_name}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                )}
              </Box>
              {error.application && (
                <Typography variant="body1" component="p" className="error">
                  {error.application}
                </Typography>
              )}
            </Grid>

            <Grid item xs={12} sm={12} md={4} lg={4}>
              <Box variant="div" component="div">
                <label>
                  Version{" "}
                  <Typography
                    variant="body1"
                    component="span"
                    className="asterisk"
                  >
                    *
                  </Typography>
                </label>
                {loading ? (
                  <Skeleton variant="rect" width="100%" height={40} />
                ) : (
                  <TextField
                    fullWidth
                    id="outlined-basic"
                    label=""
                    variant="outlined"
                    size="small"
                    placeholder="Enter version e.g., 1.0.0"
                    onChange={onChangeVersionHandler}
                    value={form.version}
                  />
                )}
              </Box>
              {error.version && (
                <Typography variant="body1" component="p" className="error">
                  {error.version}
                </Typography>
              )}
            </Grid>

            <Grid item xs={12} sm={12} md={4} lg={4}>
              <Box variant="div" component="div">
                <label>
                  Date{" "}
                  <Typography
                    variant="body1"
                    component="span"
                    className="asterisk"
                  >
                    *
                  </Typography>
                </label>
                {loading ? (
                  <Skeleton variant="rect" width="100%" height={40} />
                ) : (
                  <DatePicker
                    oneTap
                    name="date"
                    appearance="subtle"
                    format="dd-MM-yyyy"
                    value={form.date}
                    onChange={onChangeDateHandler}
                    className={`calendar-ui add-release-note-datepicker`}
                    shouldDisableDate={(date) => {
                      const today = new Date();
                      today.setHours(0, 0, 0, 0);
                      date.setHours(0, 0, 0, 0);
                      return date > today;
                    }}
                  />
                )}
              </Box>
              {error.date && (
                <Typography variant="body1" component="p" className="error">
                  {error.date}
                </Typography>
              )}
            </Grid>
          </Grid>

          <Grid container spacing={2} mt={1}>
            <Grid item xs={12}>
              <Box variant="div" component="div">
                <label>
                  Description{" "}
                  <Typography
                    variant="body1"
                    component="span"
                    className="asterisk"
                  >
                    *
                  </Typography>
                </label>
                {loading ? (
                  <Skeleton variant="rect" width="100%" height={150} />
                ) : (
                  <ReactQuill
                    placeholder="Write here..."
                    modules={quillModules}
                    theme="snow"
                    className="description-text-box"
                    onChange={onChangeDescriptionHandler}
                    value={form.description}
                  />
                )}
              </Box>
              {error.description && (
                <Typography variant="body1" component="p" className="error">
                  {error.description}
                </Typography>
              )}
            </Grid>
          </Grid>

        </Box>
      </Box>
      <Box variant="div" component="div" className="footer-btn">
        <Box variant="div" component="div" className="btn-group">
          <Button
            variant="contained"
            size="large"
            className="primary-btn btn"
            disableRipple
            onClick={onSubmitHandler}
          >
            Save
          </Button>
          <Button
            variant="contained"
            size="large"
            className="secondary-btn btn"
            disableRipple
            onClick={() => {
              navigate("/release-notes");
            }}
          >
            Cancel
          </Button>
        </Box>
      </Box>
    </>
  );
};

export default AddEditReleaseNote;
