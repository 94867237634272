import { createSlice } from "@reduxjs/toolkit";
// import { setFeedback } from "./feedbackSlice";
import { persistor } from "../store";
// import Cookies from "js-cookie";
import axios from "axios";
axios.defaults.withCredentials = true;

const initialState = {
  isLoading: false,
  error: null,
};

const logoutSlice = createSlice({
  name: "logout",
  initialState,
  reducers: {
    logoutStart: (state) => {
      state.isLoading = true;
      state.error = null;
    },
    logoutSuccess: (state) => {
      state.isLoading = false;
      state.error = null;
    },
    logoutFailure: (state, action) => {
      state.isLoading = false;
      state.error = action.payload;
    },
  },
});

export const { logoutStart, logoutSuccess, logoutFailure } =
  logoutSlice.actions;

export default logoutSlice.reducer;

export const logout = (token) => async (dispatch) => {
  try {
    dispatch(logoutStart());

    await axios.post(
      `${process.env.REACT_APP_API_URL}/users/logout`,
      {},
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      }
    );

    dispatch(logoutSuccess());
    // Cookies.remove("userToken");
    // localStorage.removeItem("userData");
    // localStorage.removeItem("userId");
    persistor.purge("persist:root");
  } catch (error) {
    dispatch(logoutFailure(error.response.data.message));
  }
};
