import { createSlice } from "@reduxjs/toolkit";
import { setFeedback } from "./feedbackSlice";
import axios from "axios";
axios.defaults.withCredentials = true;

const initialState = {
  user: null,
  loading: false,
  error: null,
};

const editByIdSlice = createSlice({
  name: "edituser",
  initialState,
  reducers: {
    editUserByIdStart(state) {
      state.loading = true;
      state.error = null;
    },
    editUserByIdSuccess(state, action) {
      state.loading = false;
      state.user = action.payload;
    },
    editUserByIdFailure(state, action) {
      state.loading = false;
      state.error = action.payload;
    },
  },
});

export const { editUserByIdStart, editUserByIdSuccess, editUserByIdFailure } =
  editByIdSlice.actions;
export default editByIdSlice.reducer;

export const editUserById = (userId, token, editData) => async (dispatch) => {
  try {
    dispatch(editUserByIdStart());
    const response = await axios.patch(
      `${process.env.REACT_APP_API_URL}/users/${userId}`,
      editData,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      }
    );
    const user = response;
    const data = response.data;
    dispatch(editUserByIdSuccess(user));
    return { success: true, message: data.message };
  } catch (error) {
    dispatch(editUserByIdFailure(error.message));
    dispatch(
      setFeedback({
        status: "fail",
        message: error.response.message,
        code: error.response.status,
      })
    );
    return { success: false, error: error.response.data.message };
  }
};
