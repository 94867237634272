import { Box, Button, Fade, Modal, Typography, styled } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { clearFeedback } from "../store/slices/feedbackSlice"; // Import clearFeedback from feedbackSlice
import ErrorOutlinedIcon from "@mui/icons-material/ErrorOutlineOutlined";
import CheckCircleOutlinedIcon from "@mui/icons-material/CheckCircleOutlined";
import WarningOutlinedIcon from "@mui/icons-material/WarningOutlined";
import { persistor } from "../store/store";
import Cookies from "js-cookie";

const FeedbackModal = () => {
  const feedback = useSelector((state) => state.feedback);
  const dispatch = useDispatch();
  const [isModalOpen, setIsModalOpen] = useState(false); // Set initial state to false

  const modalCloseHandler = () => {
    setIsModalOpen(false);
    dispatch(clearFeedback());
    if (feedback.code === 401 || feedback.code === 403) {
      window.location.href = "/signin";
      Cookies.remove(process.env.REACT_APP_COOKIE_NAME);
      Cookies.remove(process.env.REACT_APP_USER_DATA_COOKIE);
      Cookies.remove(process.env.REACT_APP_USER_ID_COOKIE);
      persistor.purge("persist:root");
    }
  };

  useEffect(() => {
    if (feedback.message) {
      // Check if there is a feedback message
      setIsModalOpen(true); // If there is a feedback message, open the modal
      const timer = setTimeout(() => {
        setIsModalOpen(false);
        dispatch(clearFeedback());
        if (feedback.code === 401 || feedback.code === 403) {
          window.location.href = "/signin";
          Cookies.remove(process.env.REACT_APP_COOKIE_NAME);
      Cookies.remove(process.env.REACT_APP_USER_DATA_COOKIE);
      Cookies.remove(process.env.REACT_APP_USER_ID_COOKIE);
          // persistor.purge("persist:root");
        }
      }, 7000);

      return () => {
        clearTimeout(timer);
      };
    }
  }, [dispatch, feedback.code, feedback.message]);

  return (
    <>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={isModalOpen}
        onClose={modalCloseHandler}
        closeAfterTransition
        slotProps={{
          backdrop: {
            timeout: 500,
          },
        }}
      >
        <Fade in={isModalOpen}>
          <Box sx={styled} className="modal-wrapper modal-ui">
            <Typography variant="h6" component="h6" className="status-icon">
              {feedback.status === "fail" && (
                <ErrorOutlinedIcon className="fail-icon" />
              )}
              {feedback.status === "success" && (
                <CheckCircleOutlinedIcon className="success-icon" />
              )}
              {feedback.status === "warning" && (
                <WarningOutlinedIcon className="warning-icon" />
              )}
            </Typography>

            <Box className="modal-body">
              <Box className={`feedback-text ${feedback.status}-text`}>
                <Typography paragraph>
                  {feedback.message?.charAt(0).toUpperCase() +
                    feedback.message?.slice(1)}
                </Typography>
              </Box>
              <Box className="modal-footer">
                <div style={{ textAlign: "center" }}>
                  <Button
                    className="btn btn-secondary"
                    onClick={modalCloseHandler}
                    style={{ width: "160px" }}
                  >
                    {feedback.code === 401 || feedback.code === 403
                      ? `LOGIN`
                      : "OK"}
                  </Button>
                </div>
              </Box>
            </Box>

          </Box>
        </Fade>
      </Modal>
    </>
  );
};

export default FeedbackModal;
