import { configureStore, combineReducers } from "@reduxjs/toolkit";
// import thunk from "redux-thunk";
import sidebarReducer from "./slices/drawerHandlerSlice.js";
import authReducer from "./slices/authSlice";
import feedbackReducer from "./slices/feedbackSlice";
import forgetPasswordReducer from "./slices/forgetPasswordSlice";
import changePasswordReducer from "./slices/changePasswordSlice";
import resetPasswordReducer from "./slices/resetPasswordSlice";
import logoutReducer from "./slices/logoutSlice";
import designationReducer from "./slices/designationSlice";
import createUserReducer from "./slices/createUserSlice";
import userReducer from "./slices/userSlice";
import userByIdReducer from "./slices/userByIdSlice";
import editByIdReducer from "./slices/editByIdSlice";
import designationByIdReducer from "./slices/designationByIdSlice";
import editUserByAdminReducer from "./slices/editUserByAdminSlice";
import deleteByIdReducer from "./slices/deleteByIdSlice";
import applicationReducer from "./slices/applicationSlice";
import applicationPermissionReducer from "./slices/applicationPermissionSlice";
import roleReducer from "./slices/roleSlice";
import viewreducer from "./slices/viewSlice";
import rolecreatereducer from "./slices/rolecreateSlice";
import roleeditreducer from "./slices/roleeditSlice";
import userDashboardReducer from "./slices/userDashboardSlice";
import deleteImgByIdReducer from "./slices/deleteProfileImgSlice";
import changePassWordByAdminReducer from "./slices/changePasswordByAdminSlice";
import releaseNoteReducer from "./slices/releaseNoteSlice";

// import { createStore } from "redux";
import { persistStore, persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage"; // Defaults to localStorage for web

const rootReducer = combineReducers({
  sidebar:sidebarReducer,
  auth: authReducer,
  feedback: feedbackReducer,
  forgetPassword: forgetPasswordReducer,
  changePassword: changePasswordReducer,
  resetPassword: resetPasswordReducer,
  logout: logoutReducer,
  userDashboard: userDashboardReducer,
  designation: designationReducer,
  createUser: createUserReducer,
  users: userReducer,
  userById: userByIdReducer,
  editUserById: editByIdReducer,
  designationById: designationByIdReducer,
  applications: applicationReducer,
  roles: roleReducer,
  views: viewreducer,
  rolecreate: rolecreatereducer,
  roleedit: roleeditreducer,
  editUserByAdmin: editUserByAdminReducer,
  deleteImgById: deleteByIdReducer,
  deleteProfileImg: deleteImgByIdReducer,
  changePassWordByAdmin: changePassWordByAdminReducer,
  releaseNote: releaseNoteReducer,
  applicationPermissions: applicationPermissionReducer,
});
const persistConfig = {
  key: "root", // The key to use for storing the data in storage
  storage,
  whitelist: ["rolecreate", "roleedit", "roles", "auth", "userById"],
};

// Wrap the rootReducer with persistReducer
const persistedReducer = persistReducer(persistConfig, rootReducer);

const store = configureStore({
  reducer: persistedReducer,
  // middleware: [thunk],
  middleware: (getDefaultMiddleware) => getDefaultMiddleware(),
});
export const persistor = persistStore(store);

export default store;
