import React from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Button from "@mui/material/Button";
import { RiEyeLine } from "react-icons/ri";
import { Box, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { fetchApplications } from "../../../store/slices/applicationSlice";
import "./application.scss";
import Skeleton from "@mui/material/Skeleton";
import { fetchApplicationPermissions } from "../../../store/slices/applicationPermissionSlice";

const Applicationlist = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const application = useSelector((state) => state.applications);

  useEffect(() => {
    dispatch(fetchApplications());

  }, [dispatch]);

  return (
    <>
      <TableContainer component={Table}>
        <Table
          sx={{ minWidth: 650 }}
          aria-label="table"
          className="listing-table"
        >
          <TableHead>
            <TableRow>
              <TableCell align="left">Application Name</TableCell>
              <TableCell align="left">No of Users</TableCell>
              <TableCell align="left">No of Roles</TableCell>
              <TableCell align="left">Action</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {application.length > 0 
              ? application.map((app) => (
                  <TableRow
                    sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                  >
                    <TableCell align="left">
                      <Box
                        variant="div"
                        component="div"
                        className="app-name d-flex"
                      >
                        <img
                          src={app.img_path}
                          alt="app-logo"
                          loading="lazy"
                          width={48}
                          height={48}
                        />
                        {app.app_name}
                      </Box>
                    </TableCell>
                    <TableCell align="left">
                      {app.totalUsers} {app.totalUsers === 1 ? "User" : "Users"}
                    </TableCell>
                    <TableCell align="left">
                      <Button
                        variant="contained"
                        size="large"
                        className="primary-btn btn roles-btn"
                        disableRipple
                        onClick={() =>
                          navigate(`/applications/${app.id}/roles`)
                        }
                      >
                        <Typography variant="body1" component="span">
                          {app.totalRoles}
                        </Typography>
                        <Typography variant="body1" component="p">
                          Roles
                        </Typography>
                      </Button>
                    </TableCell>
                    <TableCell align="left">
                      <Button
                        variant="outlined"
                        className="action-btn view-btn"
                        startIcon={<RiEyeLine />}
                        disableRipple
                        onClick={() => navigate(`/applications/${app.id}/view`)}
                        title="View"
                      ></Button>
                    </TableCell>
                  </TableRow>
                ))
              : Array.from({ length: application?.length || 3 }).map(
                  (_, index) => (
                    <TableRow
                      sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                      key={`skeleton-${index}`}
                    >
                      <TableCell>
                        <Skeleton animation="wave" height={20} width="100%" />
                      </TableCell>
                      <TableCell>
                        <Skeleton animation="wave" height={40} width="100%" />
                      </TableCell>
                      <TableCell>
                        <Skeleton animation="wave" height={40} width={40} />
                      </TableCell>
                      <TableCell>
                        <Skeleton animation="wave" height={40} width={40} />
                      </TableCell>
                    </TableRow>
                  )
                )}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
};

export default Applicationlist;
