import React from "react";
import { Navigate } from "react-router-dom";
import getTokenFromLocalStorage from "../utils/token";
// import Cookies from "js-cookie";
// import Layout from "../layout";

const ProtectedRoute = ({ children }) => {
  let token = getTokenFromLocalStorage();

  return token ? children : <Navigate to="/signin" replace={true} />;
};

export default ProtectedRoute;
