export const usersPerPage = 10;

export const emailformat = () => {
  const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
  return emailRegex;
};

export const passwordformat = () => {
  const passwordRegex =
    /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&#])[A-Za-z\d@$!%*?&#]{8,}$/;
  return passwordRegex;
};

export const displayErrorMessage = (message, setMessage) => {
  setMessage(message);
  setTimeout(() => {
    setMessage("");
  }, 7000);
};

export const displayErrorMessage1 = (message, setMessage) => {
  setMessage(message);
};

export const applications = [
  {
    id: 1,
    label: "TMS",
    logo: "TMS",
    link: "http://dev-central.crestinfosystems.net/",
  },
  {
    id: 2,
    label: "PMS",
    logo: "PMS",
    link: "http://dev-pms.crestinfosystems.net/",
  },
  {
    id: 3,
    label: "TMS",
    logo: "TMS",
    link: "http://dev-central.crestinfosystems.net/",
  },
  {
    id: 4,
    label: "HRMS",
    logo: "HRMS",
    link: "http://dev-central.crestinfosystems.net/",
  },
];
