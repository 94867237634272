// import { Typography } from "@mui/material";

import "./styles/globals.scss";

import Roots from "./routes";
import { BrowserRouter } from "react-router-dom";

function App() {
  return (
    <>
      <BrowserRouter>
        {/* <Layout> */}
        <Roots />
        {/* </Layout> */}
      </BrowserRouter>
    </>
  );
}

export default App;
