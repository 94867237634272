import React from "react";

import { Routes, Route, Navigate } from "react-router-dom";

import Applications from "../pages/applications";
import UserManagement from "../pages/user-management";
import Applicationview from "../pages/applications/application/applicationview";
import Rolelist from "../pages/applications/role/rolelist";
import Rolecreate from "../pages/applications/role/rolecreate";
import Roleedit from "../pages/applications/role/roleedit";
import Usercreate from "../pages/user-management/usercreate";
import Edituser from "../pages/user-management/edituser";
import Userview from "../pages/user-management/userview";
import Login from "../pages/auth/login";
import ForgetPassword from "../pages/auth/forgetPassword";
import EmailSuccess from "../pages/auth/emailSuccess";
import ResetPassword from "../pages/auth/resetPassword";
import Layout from "../layout";
import UserProfileview from "../pages/profile/userprofileview";
import Dashboard from "../pages/dashboard";
import UserDashboard from "../pages/user-dashboard";
import { ToastContainer } from "react-toastify";
import ProtectedRoute from "./ProtectedRoute";
import getTokenFromLocalStorage from "../utils/token";
import Pagenotfound from "../pages/pagenotfound/index";
import ChangePassword from "../pages/auth/changePassword";
import ReleaseNotes from "../pages/releasenotes";
import AddEditReleaseNote from "../pages/releasenotes/AddEditReleaseNote";

const PublicRouteWrapper = ({ children }) => {
  const token = getTokenFromLocalStorage();

  return !token ? children : <Navigate to="/dashboard" />
}

const Roots = () => {
  const token = getTokenFromLocalStorage();

  return (
    <>
      <ToastContainer />
      <Routes>
        <Route
          path="/signin"
          element={<PublicRouteWrapper><Login /></PublicRouteWrapper>}
        />
        <Route
          path="/"
          element={!token ? <Login /> : <Navigate to="/dashboard" />}
        />

        <Route
          path="/forgetpassword"
          element={!token ? <ForgetPassword /> : <Navigate to="/dashboard" />}
        />
        <Route
          path="/emailsuccess"
          element={!token ? <EmailSuccess /> : <Navigate to="/dashboard" />}
        />
        <Route
          path="/set-new-password"
          element={!token ? <ResetPassword /> : <Navigate to="/dashboard" />}
        />
        <Route
          path="/change-password"
          element={
            <ProtectedRoute>
              <Layout>
                <ChangePassword />
              </Layout>
            </ProtectedRoute>
          }
        />
        <Route
          path="/dashboard"
          element={
            <ProtectedRoute>
              <Layout>
                <Dashboard />
              </Layout>
            </ProtectedRoute>
          }
        />
        {/* <Route
          path="/dashboard"
          element={
            <ProtectedRoute>
              <Layout>
                <UserDashboard />
              </Layout>
            </ProtectedRoute>
          }
        /> */}
        <Route
          path="/applications"
          element={
            <ProtectedRoute>
              <Layout>
                <Applications />
              </Layout>
            </ProtectedRoute>
          }
        />
        <Route
          path="/applications/:id/view"
          element={
            <ProtectedRoute>
              <Layout>
                <Applicationview />
              </Layout>
            </ProtectedRoute>
          }
        />
        <Route
          path="/applications/:id/roles"
          element={
            <ProtectedRoute>
              <Layout>
                <Rolelist />
              </Layout>
            </ProtectedRoute>
          }
        />
        <Route
          path="/applications/:id/roles/create"
          element={
            <ProtectedRoute>
              <Layout>
                <Rolecreate />
              </Layout>
            </ProtectedRoute>
          }
        />

        <Route
          path="/applications/:id/roles/:id/edit"
          element={
            <ProtectedRoute>
              <Layout>
                <Roleedit />
              </Layout>
            </ProtectedRoute>
          }
        />

        <Route
          path="/users"
          element={
            <ProtectedRoute>
              <Layout>
                <UserManagement />
              </Layout>
            </ProtectedRoute>
          }
        />
        <Route
          path="/users/create"
          element={
            <ProtectedRoute>
              <Layout>
                <Usercreate />
              </Layout>
            </ProtectedRoute>
          }
        />
        <Route
          path="/users/:userId/view"
          element={
            <ProtectedRoute>
              <Layout>
                <Userview />
              </Layout>
            </ProtectedRoute>
          }
        />
        <Route
          path="/users/:userId/edit"
          element={
            <ProtectedRoute>
              <Layout>
                <Edituser />
              </Layout>
            </ProtectedRoute>
          }
        />

        <Route
          path="/profile"
          element={
            <ProtectedRoute>
              <Layout>
                <UserProfileview />
              </Layout>
            </ProtectedRoute>
          }
        />

        <Route
          path="/release-notes"
          element={
            <ProtectedRoute>
              <Layout>
                <ReleaseNotes />
              </Layout>
            </ProtectedRoute>
          }
        />

        <Route
          path="/release-notes/create"
          element={
            <ProtectedRoute>
              <Layout>
                <AddEditReleaseNote />
              </Layout>
            </ProtectedRoute>
          }
        />

        <Route
          path="/release-notes/:releaseId/edit"
          element={
            <ProtectedRoute>
              <Layout>
                <AddEditReleaseNote />
              </Layout>
            </ProtectedRoute>
          }
        />

        <Route path="*" element={<Pagenotfound />} />
      </Routes>
    </>
  );
};

export default Roots;
