import {
  Box,
  Button,
  Checkbox,
  FormControlLabel,
  Grid,
  TextField,
  Typography,
} from "@mui/material";
import React, { useEffect } from "react";
import { useState } from "react";
import logo from "../../assets/images/cs-logo-white.svg";
import PatternBig from "../../assets/images/Big-Ellipse.png";
import PatternSmall from "../../assets/images/Small-Ellipse.png";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { login } from "../../store/slices/authSlice";
import { successToast } from "../../responses/successToast";
import { emailformat } from "../../utils/constant";
import { displayErrorMessage } from "../../utils/constant";
import { displayErrorMessage1 } from "../../utils/constant";
import { commonerror } from "../../utils/errormessage";
import { emailerror } from "../../utils/errormessage";
import { passworderror } from "../../utils/errormessage";
import { emailinvaliderror } from "../../utils/errormessage";
import "./auth.scss";
import {
  getRememberMeStorage,
  removeRememberMeStorage,
  setRememberMeStorage,
} from "../../utils/rememberMe";
import { encrypt, decrypt } from "n-krypta";

const SECRET_KEY = process.env.REACT_APP_SECRET_KEY;

const Login = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [erromessage1, setErrorMessage1] = useState("");
  const [erromessage2, setErrorMessage2] = useState("");
  const [erromessage3, setErrorMessage3] = useState("");
  const [rememberMe, setRememberMe] = useState(false);
  const emailRegex = emailformat();

  const submitHandler = async (event) => {
    event.preventDefault();
    let flag = 0;

    if (!email) {
      displayErrorMessage1(emailerror, setErrorMessage1);
      flag = 1;
    }
    if (!password) {
      displayErrorMessage(passworderror, setErrorMessage3);
      flag = 1;
    }
    if (email && !emailRegex.test(email)) {
      displayErrorMessage1(emailinvaliderror, setErrorMessage2);
      flag = 1;
    }

    if (flag !== 0) {
      return;
    }

    try {
      const credentials = {
        email: email,
        password: password,
      };
      const loginResult = await dispatch(login(credentials));
      if (loginResult.success) {
        successToast(loginResult.message);
        if (rememberMe) {
          const encryptedPassword = encrypt(password, SECRET_KEY);
          setRememberMeStorage({ email, password: encryptedPassword });
        } else {
          removeRememberMeStorage();
        }
        navigate("/dashboard");
      } else {
      }
    } catch (error) {
      return;
    }
  };

  const handleKeyPress = (event) => {
    if (event.key === "Enter") {
      submitHandler(event);
    }
  };

  useEffect(() => {
    const userCredential = getRememberMeStorage();
    if (userCredential) {
      const decryptedPassword = decrypt(userCredential.password, SECRET_KEY);
      setEmail(userCredential?.email);
      setPassword(decryptedPassword);
      setRememberMe(true);
    }
  }, []);

  return (
    <>
      <Box variant="div" component="div" className="main-wrap ">
        <Box variant="div" component="div" className="logo-wrap">
          <img src={logo} alt="logo-main" loading="lazy" />
        </Box>

        <Box variant="div" component="div" className="form-wrap p-24">
          <Box variant="div" component="div" className="title">
            <Typography variant="h3" component="h3">
              Sign In
            </Typography>
            <Typography variant="body1" component="p">
              Enter your details to sign in to your account.
            </Typography>
          </Box>

          <Grid container spacing={2} mt={1}>
            <Grid item xs={12}>
              <Box variant="div" component="div">
                <label>
                  Email Id
                  <Typography
                    variant="body1"
                    component="span"
                    className="asterisk"
                  >
                    *
                  </Typography>
                </label>
                <TextField
                  fullWidth
                  id="outlined-basic"
                  label=""
                  variant="outlined"
                  placeholder="Enter Email"
                  size="small"
                  value={email}
                  required
                  onChange={(e) => {
                    setEmail(e.target.value);
                    if (e.target.value) {
                      setErrorMessage1(""); // Clear the error message when user enters data
                    }
                    if (!emailRegex.test(email)) {
                      setErrorMessage2("");
                    }
                  }}
                  onKeyPress={handleKeyPress}
                />
              </Box>
              {erromessage1 && (
                <Typography variant="body1" component="p" className="error">
                  {erromessage1}
                </Typography>
              )}
              {erromessage2 && (
                <Typography variant="body1" component="p" className="error">
                  {erromessage2}
                </Typography>
              )}
            </Grid>
            <Grid item lg={12} md={12} sm={12} xs={12} mt={1}>
              <Box variant="div" component="div">
                <label>
                  Password
                  <Typography
                    variant="body1"
                    component="span"
                    className="asterisk"
                  >
                    *
                  </Typography>
                </label>
                <TextField
                  fullWidth
                  id="outlined-basic"
                  label=""
                  variant="outlined"
                  placeholder="Enter Password"
                  size="small"
                  type="password"
                  required
                  value={password}
                  onChange={(e) => {
                    setPassword(e.target.value);
                    if (e.target.value) {
                      setErrorMessage3(""); // Clear the error message when user enters data
                    }
                  }}
                  onKeyPress={handleKeyPress}
                />
                {erromessage3 && (
                  <Typography variant="body1" component="p" className="error">
                    {erromessage3}
                  </Typography>
                )}
                <Box variant="div" component="div" className="forgot-pw">
                  <Button className="link-btn" disableRipple>
                    <Link to="/forgetpassword">Forgot Password?</Link>
                  </Button>
                </Box>
              </Box>
            </Grid>
          </Grid>
          <FormControlLabel
            control={
              <Checkbox
                disableRipple
                checked={rememberMe}
                onChange={(e) => setRememberMe(e.target.checked)}
              />
            }
            label="Remember Me"
            className="remember-me"
          />
          <Button
            variant="contained"
            size="large"
            className="primary-btn btn"
            disableRipple
            type="submit"
            onClick={submitHandler}
          >
            {" "}
            Sign In
          </Button>
        </Box>

        <Box variant="div" component="div" className="pattern-big">
          <img src={PatternBig} alt="pattern" loading="lazy" />
        </Box>
        <Box variant="div" component="div" className="pattern-small">
          <img src={PatternSmall} alt="pattern" loading="lazy" />
        </Box>
      </Box>
    </>
  );
};

export default Login;
