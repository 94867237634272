// designationByIdSlice.js
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { setFeedback } from "./feedbackSlice";
import axios from "axios";
axios.defaults.withCredentials = true;
// Define the initial state
const initialState = {
  designation: null,
  loading: false,
  error: null,
};

// Create the asynchronous thunk for fetching a designation by ID
export const fetchDesignationById = createAsyncThunk(
  "designationById/fetchDesignationById",
  async ({ designationId, token }, { rejectWithValue, dispatch }) => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/designations/${designationId}?fields=id,designation_name`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );

      // const designationName = response.data.data.designation_name;
      return response.data.data;
    } catch (error) {
      dispatch(
        setFeedback({
          status: "fail",
          message: error.response.data.message,
          code: error.response.status,
        })
      );
      return rejectWithValue(error.message);
    }
  }
);

// Create the slice
const designationByIdSlice = createSlice({
  name: "designationById",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchDesignationById.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchDesignationById.fulfilled, (state, action) => {
        state.loading = false;
        state.designation = action.payload;
      })
      .addCase(fetchDesignationById.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      });
  },
});

export default designationByIdSlice.reducer;
