import {
  Box,
  Grid,
  Modal,
  Paper,
  Typography,
  Fade,
  CircularProgress,
  Skeleton,
  Divider,
} from "@mui/material";
import React, { useState, useEffect } from "react";
import Button from "@mui/material/Button";
import { RiCameraFill, RiUploadCloud2Line } from "react-icons/ri";
import { useDropzone } from "react-dropzone";
import BigDummyImage from "../../assets/images/BigDummyImg.png";
import styled from "@emotion/styled";
import RemovepicModal from "../../common/removeprofilepicmodal";
import EditprofileModal from "./Editprofilemodal";
import getTokenFromLocalStorage from "../../utils/token";
import getIdFromLocalStorage from "../../utils/userid";
import { useDispatch, useSelector } from "react-redux";
import { fetchUserById } from "../../store/slices/userByIdSlice";
import { fetchDesignationById } from "../../store/slices/designationByIdSlice";
import axios from "axios";
import "./userprofile.scss";
import { successToast } from "../../responses/successToast";
import { setFeedback } from "../../store/slices/feedbackSlice";
axios.defaults.withCredentials = true;

const UserProfileview = () => {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(true);
  const [open, setOpen] = useState(false);
  const [profileModalOpen, setProfileModalOpen] = useState(false);
  const [errormessage, setErrorMessage] = useState("");
  const [editprofileModalOpen, setEditProfileModalOpen] = useState(false);
  const [uploading, setUploading] = useState(false);
  const handleEditprofileModalClose = () => setEditProfileModalOpen(false);

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const { getRootProps, getInputProps } = useDropzone({
    accept: {
      "image/png": [".png"],
      "image/jpeg": [".jpeg"],
      "image/svg": [".svg"],
    },
    maxFiles: 1,
    maxSize: 2 * 1024 * 1024,
    onDrop: (acceptedFiles, fileRejections) => {
      if (fileRejections.length > 0) {
        const rejectedFile = fileRejections[0];

        if (rejectedFile.errors.some((err) => err.code === "file-too-large")) {
          setErrorMessage(
            "Image size greater than 5MB, Could not upload, please try again"
          );
        } else if (rejectedFile.file) {
          const acceptedFormats = ["image/png", "image/jpeg", "image/svg+xml"];
          const fileFormat = rejectedFile.file.type;

          if (!acceptedFormats.includes(fileFormat)) {
            setErrorMessage(
              "Unsupported image format, Could not upload, please try again"
            );
          }
        }
      } else if (acceptedFiles.length > 0) {
        setSelectedFile(acceptedFiles[0]);
        setErrorMessage("");
      }
    },
  });
  const { ref, ...rootProps } = getRootProps();

  const token = getTokenFromLocalStorage();
  const userId = getIdFromLocalStorage();
  useEffect(() => {
    dispatch(fetchUserById(userId, token))
      .then(() => {
        setLoading(false); 
      })
      .catch((error) => {
        setLoading(false); 
        console.error("Error fetching user data:", error);
      });
  }, [dispatch, token, userId]);

  const userData = useSelector((state) => state.userById.user);

  const designationId = userData?.data?.designation_id;
  
  useEffect(() => {
    if(designationId){
      dispatch(fetchDesignationById({ token, designationId }));
    }
  }, [dispatch, token, designationId]);

  const designationName = useSelector(
    (state) => state.designationById.designation?.designation_name
  );

  const applicationDetails = userData?.data?.application_details || [];

  const [selectedFile, setSelectedFile] = useState(null);
  function capitalizeFirstLetter(str) {
    return str.charAt(0).toUpperCase() + str.slice(1);
  }
  const handleUploadProfileLogo = async () => {
    if (selectedFile && selectedFile.size > 2 * 1024 * 1024) {
      setErrorMessage(
        "Image size exceeds the maximum allowed size of 2MB, Could not upload, please try again"
      );
      return;
    }

    if (!selectedFile) {
      setErrorMessage("Please select any file");
    } else {
      setErrorMessage(""); 
    }
    if (!errormessage && selectedFile) {
      try {
        setUploading(true);
        const token = getTokenFromLocalStorage();

        const formData = new FormData();
        formData.append("profile_img_url", selectedFile); 

        const response = await axios.post(
          `${process.env.REACT_APP_API_URL}/users/add-profileimage`,
          formData,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );

        handleClose();
        dispatch(fetchUserById(userId, token));
        setSelectedFile(null);
        setErrorMessage("");
        setProfileModalOpen(false);
        successToast("User Profile photo added successfully");
        setUploading(false);
      } catch (error) {
        if (error.response) {
          dispatch(
            setFeedback({
              status: "fail",
              message: error.response.data.message,
              code: error.response.status,
            })
          );
          setUploading(false);
        } else {
          console.error("Error while uploading logo:", error);
          setUploading(false);
        }
        console.error("Error while uploading logo:", error);
        setUploading(false);
      }
    }
  };
  const cancelHandler = () => {
    setProfileModalOpen(false);
    setErrorMessage("");
  };

  return (
    <>
      <>
        <Box className="content-header">
          <Typography variant="h2" component="h2" className="title-with-btn">
            Profile
          </Typography>
        </Box>
        
        <Box className="content-wrapper">
          <Grid container spacing={3} className="profile-detail-wrap">
            <Grid item lg={12} md={12} sm={12} xs={12}>
              <Box className="card-wrapper p-24">
                <Grid container spacing={2} className="profile-view">
                  <Grid item lg={3} md={4} sm={12} xs={12}>
                    <Box variant="div" component="div" className="profile-picture">
                      <Typography variant="h3" component="h3" sx={{marginBottom:"20px"}}>
                        Profile Picture
                      </Typography>
                      {loading ? (
                        <Skeleton variant="circular" width={130} height={130} />
                      ) : (
                        <Box variant="div" component="div" className="image-wrap">
                          {uploading ? (
                            <CircularProgress />
                          ) : (
                            <Box variant="div" component="div" className="image">
                              {userData?.data?.img_path ? (
                                <img
                                  src={userData?.data?.img_path}
                                  alt="app-logo"
                                  loading="lazy"
                                  style={{
                                    objectFit: "contain",
                                    width: "100%",
                                    height: "100%",
                                    borderRadius: "50%",
                                  }}
                                />
                              ) : (
                                <img
                                  src={BigDummyImage}
                                  alt="app-logo"
                                  loading="lazy"
                                />
                              )}
                              
                            </Box>
                          )}
                          <Button
                            variant="outlined"
                            className="action-btn upld-btn"
                            startIcon={<RiCameraFill />}
                            disableRipple
                            onClick={() => setProfileModalOpen(true)}
                          ></Button>
                        </Box>
                      )}
                      <Box variant="div" component="div" className="btn-wrap">
                        <Button
                          className="link-btn"
                          disableRipple
                          onClick={handleOpen}
                        >
                          Remove Profile Picture?
                        </Button>
                      </Box>
                    </Box>
                  </Grid>
                  <Grid item lg={9} md={8} sm={12} xs={12}>
                    <Box variant="div" component="div" className="profile-detail">
                      <Typography variant="h3" component="h3"  sx={{marginBottom:"20px"}}>
                        Profile Details
                      </Typography>
                      <Grid container spacing={2}>
                        <Grid item xs={12} sm={12} md={12} lg={12}>
                          <Box variant="div" component="div">
                            <Typography
                              variant="body1"
                              component="span"
                              className="label"
                            >
                              Type
                            </Typography>
                            {loading ? (
                              <Skeleton variant="text" width={100} height={30} />
                            ) : (
                              <Typography
                                variant="body1"
                                component="p"
                                className="value"
                              >
                                {capitalizeFirstLetter(userData?.data?.type)}
                              </Typography>
                            )}
                          </Box>
                        </Grid>
                        <Grid item lg={12} md={12} sm={12} xs={12}>
                            <Divider className="divider" />
                        </Grid>
                        <Grid item xs={12} sm={6} md={6} lg={6}>
                          <Box variant="div" component="div">
                            <Typography
                              variant="body1"
                              component="span"
                              className="label"
                            >
                              First Name
                            </Typography>
                            {loading ? (
                              <Skeleton variant="text" width={100} height={30} />
                            ) : (
                              <Typography
                                variant="body1"
                                component="p"
                                className="value"
                              >
                                {capitalizeFirstLetter(userData?.data?.first_name)}
                              </Typography>
                            )}
                          </Box>
                        </Grid>
                        <Grid item lg={12} md={12} sm={12} xs={12} sx={{ display: { xs: "block", sm: "none", md: "none", lg: "none" }}}>
                            <Divider className="divider" />
                        </Grid>
                        <Grid item xs={12} sm={6} md={6} lg={6}>
                          <Box variant="div" component="div">
                            <Typography
                              variant="body1"
                              component="span"
                              className="label"
                            >
                              Last Name
                            </Typography>
                            {loading ? (
                              <Skeleton variant="text" width={100} height={30} />
                            ) : (
                              <Typography
                                variant="body1"
                                component="p"
                                className="value"
                              >
                                {capitalizeFirstLetter(userData?.data?.last_name)}
                              </Typography>
                            )}
                          </Box>
                        </Grid>
                        <Grid item lg={12} md={12} sm={12} xs={12} >
                            <Divider className="divider" />
                        </Grid>
                        <Grid item xs={12} sm={6} md={6} lg={6}>
                          <Box variant="div" component="div">
                            <Typography
                              variant="body1"
                              component="span"
                              className="label"
                            >
                              Contact Number
                            </Typography>
                            {loading ? (
                              <Skeleton variant="text" width={100} height={30} />
                            ) : (
                              <Typography
                                variant="body1"
                                component="p"
                                className="value"
                              >
                                {userData?.data?.contact_number}
                              </Typography>
                            )}
                          </Box>
                        </Grid>
                        <Grid item lg={12} md={12} sm={12} xs={12} sx={{ display: { xs: "block", sm: "none", md: "none", lg: "none" }}}>
                            <Divider className="divider" />
                        </Grid>
                        <Grid item xs={12} sm={6} md={6} lg={6}>
                          <Box variant="div" component="div">
                            <Typography
                              variant="body1"
                              component="span"
                              className="label"
                            >
                              Email
                            </Typography>
                            {loading ? (
                              <Skeleton variant="text" width={100} height={30} />
                            ) : (
                              <Typography
                                variant="body1"
                                component="p"
                                className="value"
                              >
                                {userData?.data?.email}
                              </Typography>
                            )}
                          </Box>
                        </Grid>
                        <Grid item lg={12} md={12} sm={12} xs={12}>
                            <Divider className="divider" />
                        </Grid>
                        <Grid item xs={12} sm={6} md={6} lg={6}>
                          <Box variant="div" component="div">
                            <Typography
                              variant="body1"
                              component="span"
                              className="label"
                            >
                              Designation
                            </Typography>
                            {loading ? (
                              <Skeleton variant="text" width={100} height={30} />
                            ) : (
                              <Typography
                                variant="body1"
                                component="p"
                                className="value"
                              >
                                {designationName}
                              </Typography>
                            )}
                          </Box>
                        </Grid>
                      </Grid>
                      <Grid container spacing={2} className="btn-wrap" mt={1}>
                        <Grid item xs={12} sm={12} md={6} lg={6}>
                          <Button
                            variant="outlined"
                            disableRipple
                            className="outline-btn"
                            onClick={() => setEditProfileModalOpen(true)}
                          >
                            Edit Profile
                          </Button>
                        </Grid>
                      </Grid>
                    </Box>
                  </Grid>
                </Grid>
              </Box>
            </Grid>
            <Grid item lg={12} md={12} sm={12} xs={12}>
              <Box className="card-wrapper">
                <Box variant="div" component="div" className="app-details">
                  <Typography variant="h3" component="h3" sx={{paddingLeft:"24px",paddingTop:"24px",marginBottom:"0",}}>
                    Application Details{" "}
                    <Typography variant="body1" component="span">
                      (Assigned Applications & Roles)
                    </Typography>
                  </Typography>
                  <Grid
                    container
                    spacing={2}
                    mt={1}
                    className="app-details-table"
                  >
                    <Grid item xs={6} className="application-column">
                      <Box
                        variant="div"
                        component="div"
                        className="header"
                        mb={2}
                      >
                        <Typography
                          variant="body1"
                          component="span"
                          className="label"
                        >
                          Applications
                        </Typography>
                      </Box>
                      {loading ? (
                        <Skeleton variant="text" width={100} height={30} />
                      ) : applicationDetails.length === 0 ? (
                        <Typography
                          variant="body1"
                          component="p"
                          style={{ marginTop: "28px" }}
                        >
                          No Applications / Roles Assigned{" "}
                        </Typography>
                      ) : (
                        applicationDetails.map((app) => (
                          <Box
                            variant="div"
                            component="div"
                            className="row"
                            key={app.app_id}
                          >
                            <Typography
                              variant="body1"
                              component="p"
                              className="value"
                            >
                              {app.application_name}
                            </Typography>
                          </Box>
                        ))
                      )}
                    </Grid>
                    <Grid item xs={6} p={0} className="role-column">
                      <Box
                        variant="div"
                        component="div"
                        className="header"
                        mb={2}
                      >
                        <Typography
                          variant="body1"
                          component="span"
                          className="label"
                        >
                          Roles
                        </Typography>
                      </Box>
                      {/* Map over application_details to render role names */}
                      {loading ? (
                        <Skeleton variant="text" width={100} height={30} />
                      ) : (
                        applicationDetails.map((app) => (
                          <Box
                            variant="div"
                            component="div"
                            className="row"
                            key={app.role_id}
                          >
                            <Typography
                              variant="body1"
                              component="p"
                              className="value"
                            >
                              {app.role_name}
                            </Typography>
                          </Box>
                        ))
                      )}
                    </Grid>
                  </Grid>
                </Box>
              </Box>
            </Grid>
          </Grid>
        </Box>
        <Modal
          aria-labelledby="transition-modal-title"
          aria-describedby="transition-modal-description"
          open={profileModalOpen}
          onClose={handleClose}
          closeAfterTransition
        >
          <Fade in={profileModalOpen}>
            <Box sx={styled} className="modal-wrapper modal-ui">
              <Box className="modal-body">
                <Box variant="div" component="div" className="title">
                  <Typography variant="h3" component="h3">
                    Profile Picture
                  </Typography>
                  <Typography variant="body1" component="p">
                    You can manage your profile picture from here.
                  </Typography>
                </Box>
                <Box variant="div" component="div" className="body">
                  <Box variant="div" component="div" className="drop-zone">
                    <Paper className="drop-zone-inner" {...rootProps}>
                      <input {...getInputProps()} />

                      <Box className="msg-wrap">
                        <RiUploadCloud2Line />
                        <Typography variant="h6" component="h6">
                          Drag & drop file or Browse
                        </Typography>
                        <Typography variant="body1" component="p" class="sub-msg">
                          <Typography variant="body1" component="span">
                            Supported Formats:{" "}
                          </Typography>
                          JPG,PNG or SVG
                        </Typography>
                        <Typography variant="body1" component="p" class="sub-msg">
                          <Typography variant="body1" component="span">
                            File Size:{" "}
                          </Typography>
                          Not more than 2MB
                        </Typography>
                        <Typography variant="body1" component="p" class="sub-msg">
                          <Typography variant="body1" component="span">
                            Image Dimensions:{" "}
                          </Typography>
                          Min 150px x 60px
                        </Typography>
                      </Box>
                    </Paper>
                  </Box>
                </Box>
                {errormessage && <div className="error">{errormessage}</div>}
                <Box variant="div" component="div" className="footer">
                  <Box variant="div" component="div" className="btn-group">
                    <Button
                      variant="contained"
                      size="large"
                      className="primary-btn btn"
                      disableRipple
                      onClick={handleUploadProfileLogo}
                    >
                      {" "}
                      Upload Picture
                    </Button>
                    <Button
                      variant="contained"
                      size="large"
                      className="secondary-btn btn"
                      disableRipple
                      onClick={cancelHandler}
                    >
                      {" "}
                      Cancel
                    </Button>
                  </Box>
                </Box>
              </Box>
            </Box>
          </Fade>
        </Modal>
        <RemovepicModal open={open} handleClose={handleClose} />
        <EditprofileModal
          open={editprofileModalOpen}
          handleClose={handleEditprofileModalClose}
          userData={userData}
        />
      </>
    </>
  );
};

export default UserProfileview;
