import {
  Box,
  Button,
  FormControl,
  MenuItem,
  Select,
  Typography,
} from "@mui/material";
import React, { useCallback, useEffect, useState } from "react";
import { RiAddCircleLine } from "react-icons/ri";
import { useNavigate } from "react-router-dom";
import ReleaseNote from "./ReleaseNote";
import { RiArrowDownSLine } from "react-icons/ri";
import axios from "axios";
import { useDispatch, useSelector } from "react-redux";
import { setFeedback } from "../../store/slices/feedbackSlice";
import { getLocalTimeZone } from "../../utils/timezone";
import { storeReleaseNotes } from "../../store/slices/releaseNoteSlice";
import "./releaseNotes.scss";

const ReleaseNotes = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [application, setApplication] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const { totalCount, data: releaseNotesData } = useSelector(
    (state) => state.releaseNote
  );

  const [appList, setAppList] = useState([]);
  const [rows, setRows] = useState(5);
  const loadMoreHandler = () => {
    setRows((prev) => prev + 5);
  };

  useEffect(() => {
    const fetchApplication = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_API_URL}/applications?fields=id,app_name`,
          {
            headers: {
              "Content-Type": "application/json",
            },
          }
        );
        if (response.data.status) {
          const appData = [{ id: 0, app_name: "All" }];

          setAppList([...appData, ...response.data.data]);
        } else {
          dispatch(
            setFeedback({
              status: "fail",
              message: response.data.message,
              code: response.status,
            })
          );
        }
      } catch (error) {
        console.log(error);
        dispatch(
          setFeedback({
            status: "fail",
            message: error.response.data.message,
            code: error.response.status,
          })
        );
      }
    };
    fetchApplication();
  }, [dispatch]);

  const fetchReleaseNotesData = useCallback(async () => {
    try {
      setIsLoading(true);
      const paramsData = {
        allNotes: 1,
        page: 1,
        rows: rows,
        timezone: getLocalTimeZone(),
      };
      if (application !== 0) {
        paramsData.app_id = application;
      }
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/release-note`,
        { params: paramsData },
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      if (response.data.status) {
        dispatch(storeReleaseNotes(response.data.data));
      } else {
        dispatch(
          setFeedback({
            status: "fail",
            message: response.data.message,
            code: response.status,
          })
        );
      }
      setIsLoading(false);
    } catch (error) {
      console.log(error);
      dispatch(
        setFeedback({
          status: "fail",
          message: error.response.data.message,
          code: error.response.status,
        })
      );
    } finally {
      setIsLoading(false);
    }
  }, [application, dispatch, rows]);

  useEffect(() => {
    fetchReleaseNotesData();
  }, [fetchReleaseNotesData]);

  return (
    <>
      <Box className="content-header release-module-wrapper">
        <Typography variant="h2" component="h2">
          Release Notes
        </Typography>
        <Box variant="div" component="div" className="content-header-right">
          <FormControl className="relase-select-field" fullWidth sx={{ width: "225px" }}>
            <Select
              value={application}
              onChange={(event) => {
                setApplication(event.target.value);
                setRows(5);
              }}
              placeholder="Select application"
              id="demo-simple-select"
              className="select-field"
              size="small"
              IconComponent={RiArrowDownSLine}
              sx={{
                width: "225px",
                backgroundColor: "white",
                borderRadius: "8px",
                boxShadow: "0px 4px 4px 0px rgba(0, 0, 0, 0.04)",
                border: "none",
              }}
            >
              {appList.map((item) => (
                <MenuItem key={item.id} value={item.id}>
                  {item.app_name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <Button
            variant="contained"
            size="large"
            className="primary-btn btn add-btn"
            endIcon={<RiAddCircleLine />}
            disableRipple
            onClick={() => navigate("/release-notes/create")}
          >
            <Typography variant="body1" component="p">
              Create Release Note
            </Typography>
          </Button>
        </Box>
      </Box>

      <Box className="content-layout release-notes-wrapper">
        {releaseNotesData?.map(
          ({ id, version_name, date, applications }, index) => {
            return (
              <ReleaseNote
                key={id + "_release_notes"}
                id={id}
                version={version_name}
                date={date}
                app_name={applications.app_name}
              />
            );
          }
        )}
      </Box>
      {!releaseNotesData?.length && (
        <Typography variant="p" component={"p"} textAlign={"center"}>
          No release notes
        </Typography>
      )}

      {rows > 5 && isLoading && (
        <Box className="load-more-section">
          <span class="loader"></span>
        </Box>
      )}

      {totalCount > rows && (
        <Box className="load-more-section">
          <Button
            variant="contained"
            size="medium"
            className="primary-btn btn add-btn"
            endIcon={<RiAddCircleLine />}
            disableRipple
            onClick={loadMoreHandler}
          >
            <Typography variant="body1" component="p">
              Load More
            </Typography>
          </Button>
        </Box>
      )}
    </>
  );
};

export default ReleaseNotes;
