import { Box, Button, Typography } from "@mui/material";
import React from "react";
import logo from "../../assets/images/cs-logo-white.svg";
import emailsuccess from "../../assets/images/mailsuccess.png";
import PatternBig from "../../assets/images/Big-Ellipse.png";
import PatternSmall from "../../assets/images/Small-Ellipse.png";
import { Link} from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { forgetPassword } from "../../store/slices/forgetPasswordSlice";
import { successToast } from "../../responses/successToast";
import "./auth.scss";

const EmailSuccess = () => {
  const dispatch = useDispatch();
  const email = useSelector((state) => state.forgetPassword.email);

  const submitHandler = async () => {
    try {
      const forgetPasswordResult = await dispatch(forgetPassword(email));
      if (forgetPasswordResult.success) {
        successToast(forgetPasswordResult.message);
      } else {
      }
    } catch (error) {
      return;
    }
  };

  return (
    <>
      <Box variant="div" component="div" className="main-wrap ">
        <Box variant="div" component="div" className="logo-wrap">
          <img src={logo} alt="logo-main" loading="lazy" />
        </Box>

        <Box variant="div" component="div" className="form-wrap p-24">
          <Box variant="div" component="div" className="title">
            <Typography variant="h3" component="h3">
              Check your email
              <Typography variant="body1" component="p" sx={{margin:"0"}}>
                We have sent a password reset link to
              </Typography>
            </Typography>
          </Box>
          <Box variant="div" component="div" className="forget-pw">
            <div
              style={{
                textAlign: "center",
                fontWeight: "600",
                color: "rgba(35, 35, 35, 0.6)",
              }}
            >
              {email}
            </div>
          </Box>
          <Box variant="div" component="div" className="email-logo-wrap">
            <img src={emailsuccess} alt="logo-main" loading="lazy" />
          </Box>
          <Box variant="div" component="div" className="forgot-pw">
            <div className="unclick-btn" disableRipple>
              Didn't receive the email?{" "}
              <Link onClick={submitHandler}>Click here to Resend</Link>
            </div>
          </Box>
          <Box variant="div" component="div" className="forgot-pw">
            <Button className="link-btn" disableRipple>
              <Link to="/signin">Back to Log in</Link>
            </Button>
          </Box>
        </Box>

        <Box variant="div" component="div" className="pattern-big">
          <img src={PatternBig} alt="pattern" loading="lazy" />
        </Box>
        <Box variant="div" component="div" className="pattern-small">
          <img src={PatternSmall} alt="pattern" loading="lazy" />
        </Box>
      </Box>
    </>
  );
};

export default EmailSuccess;
