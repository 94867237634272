import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
axios.defaults.withCredentials = true;

export const fetchDesignations = createAsyncThunk(
  "designation/fetchDesignations",
  async (token) => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/designations?fields=id,designation_name`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );
      return response.data.data;
    } catch (error) {
      throw error;
    }
  }
);

const designationSlice = createSlice({
  name: "designation",
  initialState: {
    designations: [],
    status: "idle",
    error: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchDesignations.pending, (state) => {
        state.status = "loading";
      })
      .addCase(fetchDesignations.fulfilled, (state, action) => {
        state.status = "succeeded";

        state.designations = Object.entries(action.payload);
      })
      .addCase(fetchDesignations.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
      });
  },
});

export const {} = designationSlice.actions;
export default designationSlice.reducer;
