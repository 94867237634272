import Cookies from "js-cookie";

const USER_DATA_COOKIE = process.env.REACT_APP_USER_DATA_COOKIE;

const getuserDataFromCookies = () => {
  // const userData = JSON.parse(localStorage.getItem("token"));
  const UserToken = Cookies.get(USER_DATA_COOKIE);
  //   const userData = JSON.parse(UserToken);
  // Now, you can work with the parsed userData object
  if (!UserToken) {
    return null; // Return null when userData is not available
  }
  const jsonData = UserToken?.substring(2);
  const userData = JSON.parse(jsonData);

  // Now, you can work with the parsed userData object
  return userData; //   return userData;
};

export default getuserDataFromCookies;
