import { createSlice } from "@reduxjs/toolkit";
import { setFeedback } from "./feedbackSlice";
import axios from "axios";
axios.defaults.withCredentials = true;

const initialState = {
  user: null,
  loading: false,
  error: null,
};

const userByIdSlice = createSlice({
  name: "userById",
  initialState,
  reducers: {
    getUserByIdStart(state) {
      state.loading = true;
      state.error = null;
    },
    getUserByIdSuccess(state, action) {
      state.loading = false;
      state.user = action.payload;
    },
    getUserByIdFailure(state, action) {
      state.loading = false;
      state.error = action.payload;
    },
  },
});

export const { getUserByIdStart, getUserByIdSuccess, getUserByIdFailure } =
  userByIdSlice.actions;
export default userByIdSlice.reducer;

export const fetchUserById = (userId, token) => async (dispatch) => {
  try {
    dispatch(getUserByIdStart());

    const response = await axios.get(
      `${process.env.REACT_APP_API_URL}/users/${userId}`,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      }
    );
    const user = response.data; // Assuming the user data is in the response's "data" property
    dispatch(getUserByIdSuccess(user));
  } catch (error) {
    dispatch(getUserByIdFailure(error.message));
    dispatch(
      setFeedback({
        status: "fail",
        message: error.response.data.message,
        code: error.response.status,
      })
    );
  }
};
