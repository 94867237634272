import React, { useEffect, useLayoutEffect, useState } from "react";
import { Box, useMediaQuery } from "@mui/material";
import Header from "./header/header";
import SideMenu from "./sideMenu/sideMenu";
import PatternBig from "../assets/images/Big-Ellipse.png";
import PatternSmall from "../assets/images/Small-Ellipse.png";
import getuserDataFromCookies from "../utils/userData";
import { useDispatch, useSelector } from "react-redux";
import { handleSidebar } from "../store/slices/drawerHandlerSlice";

const Layout = ({ children }) => {
  const userData = getuserDataFromCookies();
  const sidebarState = useSelector((state) => state.sidebar.sidebarStatus);
  const dispatch = useDispatch();
//  const [isAdmin /*setIsAdmin*/] = useState(true);
 const [isAdmin /*setIsAdmin*/] = useState(userData?.role === "admin");
  const [openSideMenu, setOpenSideMenu] = useState(
    isAdmin ? sidebarState : false
  );
  const isMobile = useMediaQuery("(max-width:991.98px)");

  const handleDrawerClose = () => {
    dispatch(handleSidebar(false));
  };

  useEffect(() => {
    const mediaQuery = window.matchMedia("(min-width: 992px)");

    const handleMediaQueryChange = (event) => {
      dispatch(handleSidebar(event.matches));
    };
    handleMediaQueryChange(mediaQuery);
    mediaQuery.addEventListener("change", handleMediaQueryChange);
    return () => {
      mediaQuery.removeEventListener("change", handleMediaQueryChange);
    };
  }, []);

  useLayoutEffect(() => {
    function handleSideMenu() {
      setOpenSideMenu(window.innerWidth < 767 ? false : true);
    }
    window.addEventListener("resize", handleSideMenu);
    handleSideMenu();
    return () => window.removeEventListener("resize", handleSideMenu);
  }, []);

  return (
    <Box>
      <Header
        setOpenSideMenu={setOpenSideMenu}
        handleDrawerClose={handleDrawerClose}
        openSideMenu={openSideMenu}
        isAdmin={isAdmin}
        isMobile={isMobile}
      />
      {isAdmin ? (
        <SideMenu
          openSideMenu={openSideMenu}
          setOpenSideMenu={setOpenSideMenu}
          isMobile={isMobile}
          handleDrawerClose={handleDrawerClose}
          isAdmin={isAdmin}
        />
      ) : null}

      <main
        className={`main-content ${sidebarState ? "" : "full-width"}  ${
          isAdmin ? "admin" : "non-admin"
        } `}
      >
        {children}
        <Box variant="div" component="div" className="pattern-big">
          <img src={PatternBig} alt="pattern" loading="lazy" />
        </Box>
        <Box variant="div" component="div" className="pattern-small">
          <img src={PatternSmall} alt="pattern" loading="lazy" />
        </Box>
      </main>
    </Box>
  );
};

export default Layout;
