import { Box, Fade, Grid, Modal, TextField, Typography } from "@mui/material";
import React, { useState, useEffect } from "react";
import Button from "@mui/material/Button";
import { editUserById } from "../../store/slices/editByIdSlice";
import getTokenFromLocalStorage from "../../utils/token";
import { useDispatch } from "react-redux";
import { successToast } from "../../responses/successToast";
import { fetchUserById } from "../../store/slices/userByIdSlice";
import { displayErrorMessage1, emailformat } from "../../utils/constant";
import {
  commonerror,
  emailinvaliderror,
  mobileinvaliderror,
} from "../../utils/errormessage";
import "./userprofile.scss";

const EditprofileModal = ({ open, handleClose, userData }) => {
  const dispatch = useDispatch();

  const designationId = userData?.data?.designation_id;
  const role = userData?.data?.role;
  const [firstname, setFirstname] = useState("");
  const [lastname, setLastname] = useState("");
  const [number, setNumber] = useState("");
  const [email, setEmail] = useState("");
  const [erromessage1, setErrorMessage1] = useState("");
  const [erromessage2, setErrorMessage2] = useState("");
  const [erromessage3, setErrorMessage3] = useState("");
  const [erromessage4, setErrorMessage4] = useState("");
  const [erromessage5 /*setErrorMessage5*/] = useState("");
  const [erromessage6 /*setErrorMessage6*/] = useState("");
  const [erromessage7, setErrorMessage7] = useState("");
  const designation = userData?.data?.designation_name;
  const emailRegex = emailformat();

  useEffect(() => {
    if (userData?.data) {
      setFirstname(userData.data.first_name || "");
      setLastname(userData.data.last_name || "");
      setNumber(userData.data.contact_number || "");
      setEmail(userData?.data?.email || "");
    }
  }, [userData]);
  function validateMobileNumber(mobileNumber) {
    const digitsOnly = mobileNumber.replace(/\D/g, "");

    return /^\d{10}$/.test(digitsOnly);
  }

  const cancelHandler = () => {
    handleClose();
    setErrorMessage1("");
    setErrorMessage2("");
    setErrorMessage3("");
    setErrorMessage4("");
    setFirstname(userData.data.first_name || "");
    setLastname(userData.data.last_name || "");
    setNumber(userData.data.contact_number || "");
    setEmail(userData?.data?.email || "");
  };

  const submitHandler = async () => {
    const token = getTokenFromLocalStorage();
    const userId = userData?.data?.id;

    if (!firstname.trim() && !lastname.trim() && !number) {
      displayErrorMessage1("Please enter firstname", setErrorMessage1);
      displayErrorMessage1("Please enter lastname", setErrorMessage2);
      displayErrorMessage1("Please enter contact number", setErrorMessage3);
      displayErrorMessage1("Please enter email", setErrorMessage4);
      return;
    }
    if (!firstname.trim()) {
      displayErrorMessage1("Please enter firstname", setErrorMessage1);
      return;
    }
    if (!lastname.trim()) {
      displayErrorMessage1("Please enter lastname", setErrorMessage2);
      return;
    }
    if (!number) {
      displayErrorMessage1("Please enter contact number", setErrorMessage3);
      return;
    }
    if (!email) {
      displayErrorMessage1("Please enter email", setErrorMessage4);
      return;
    }
    if (!emailRegex.test(email)) {
      displayErrorMessage1(emailinvaliderror, setErrorMessage4);
      return;
    }
    if (!validateMobileNumber(number)) {
      displayErrorMessage1(mobileinvaliderror, setErrorMessage7);
      return;
    }
    try {
      const editData = {
        first_name: firstname.trim(),
        last_name: lastname.trim(),
        contact_number: number,
        email: email,
        designation_id: designationId,
      };
      let editResult;

      try {
        editResult = await dispatch(editUserById(userId, token, editData));
      } catch (error) {
        console.log(error);
        return;
      }

      if (editResult.success) {
        successToast(editResult.message);
        dispatch(fetchUserById(userId, token));
        handleClose();
      } else {
      }
    } catch (error) {
      return;
    }
  };

  return (
    <>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={open}
        onClose={handleClose}
        closeAfterTransition
      >
        <Fade in={open}>
          <Box className="modal-wrapper modal-ui">
            <Box className="modal-body">
              <Box variant="div" component="div" className="title">
                <Typography variant="h3" component="h3">
                  Edit Profile
                  <Typography variant="body1" component="p" sx={{marginTop:"0 !important",marginBottom:"16px !important"}}>
                    Modify Your Personal Details
                  </Typography>
                </Typography>
              </Box>
              <Box variant="div" component="div" className="body">
                <Grid container spacing={2}>
                  <Grid item xs={12} sm={6} md={6}>
                    <Box variant="div" component="div">
                      <label>
                        First Name
                        <Typography
                          variant="body1"
                          component="span"
                          className="asterisk"
                        >
                          *
                        </Typography>
                      </label>
                      <TextField
                        fullWidth
                        id="outlined-basic"
                        label=""
                        variant="outlined"
                        placeholder="Enter first name"
                        size="small"
                        value={firstname}
                        onChange={(e) => {
                          setFirstname(e.target.value);
                          if (e.target.value) {
                            setErrorMessage1(""); // Clear the error message when user enters data
                          }
                          // if (!spaceRegex.test(e.target.value)) {
                          //   setErrorMessage5("");
                          // }
                        }}
                      />
                      {erromessage1 && (
                        <Typography
                          variant="body1"
                          component="p"
                          className="error"
                        >
                          {erromessage1}
                        </Typography>
                      )}
                      {erromessage5 && (
                        <Typography
                          variant="body1"
                          component="p"
                          className="error"
                        >
                          {erromessage5}
                        </Typography>
                      )}
                    </Box>
                  </Grid>
                  <Grid item xs={12} sm={6} md={6}>
                    <Box variant="div" component="div">
                      <label>
                        Last Name
                        <Typography
                          variant="body1"
                          component="span"
                          className="asterisk"
                        >
                          *
                        </Typography>
                      </label>
                      <TextField
                        fullWidth
                        id="outlined-basic"
                        label=""
                        variant="outlined"
                        placeholder="Enter last name"
                        size="small"
                        value={lastname}
                        onChange={(e) => {
                          setLastname(e.target.value);
                          if (e.target.value) {
                            setErrorMessage2(""); // Clear the error message when user enters data
                          }
                          // if (!spaceRegex.test(e.target.value)) {
                          //   setErrorMessage6("");
                          // }
                        }}
                      />
                      {erromessage2 && (
                        <Typography
                          variant="body1"
                          component="p"
                          className="error"
                        >
                          {erromessage2}
                        </Typography>
                      )}
                      {erromessage6 && (
                        <Typography
                          variant="body1"
                          component="p"
                          className="error"
                        >
                          {erromessage6}
                        </Typography>
                      )}
                    </Box>
                  </Grid>
                  <Grid item xs={12} sm={6} md={6}>
                    <Box variant="div" component="div">
                      <label>
                        Contact Number
                        <Typography
                          variant="body1"
                          component="span"
                          className="asterisk"
                        >
                          *
                        </Typography>
                      </label>
                      <TextField
                        type="number"
                        fullWidth
                        id="outlined-basic"
                        label=""
                        variant="outlined"
                        placeholder="Enter contact number"
                        size="small"
                        value={number}
                        onChange={(e) => {
                          setNumber(e.target.value);
                          if (e.target.value) {
                            setErrorMessage3(""); // Clear the error message when user enters data
                          }
                          if (validateMobileNumber(e.target.value)) {
                            setErrorMessage7("");
                          }
                        }}
                      />
                      {erromessage3 && (
                        <Typography
                          variant="body1"
                          component="p"
                          className="error"
                        >
                          {erromessage3}
                        </Typography>
                      )}
                      {erromessage7 && (
                        <Typography
                          variant="body1"
                          component="p"
                          className="error"
                        >
                          {erromessage7}
                        </Typography>
                      )}
                    </Box>
                  </Grid>
                  {role === "admin" ? (
                    <Grid item xs={12} sm={6} md={6} >
                      <Box variant="div" component="div">
                        <label>
                          Email
                          <Typography
                            variant="body1"
                            component="span"
                            className="asterisk"
                          >
                            *
                          </Typography>
                        </label>
                        <TextField
                          fullWidth
                          id="outlined-basic"
                          label=""
                          variant="outlined"
                          placeholder="Enter email"
                          size="small"
                          value={email}
                          onChange={(e) => {
                            setEmail(e.target.value);
                            if (e.target.value) {
                              setErrorMessage4(""); // Clear the error message when user enters data
                            }
                            if (!emailRegex.test(email)) {
                              setErrorMessage4("");
                            }
                          }}
                        />
                        {erromessage4 && (
                          <Typography
                            variant="body1"
                            component="p"
                            className="error"
                          >
                            {erromessage4}
                          </Typography>
                        )}
                      </Box>
                    </Grid>
                  ) : (
                    <Grid item xs={12} sm={6} md={6}>
                      <Box variant="div" component="div">
                        <label>
                          Email
                          <Typography
                            variant="body1"
                            component="span"
                            className="asterisk"
                          >
                            *
                          </Typography>
                        </label>
                        <TextField
                          fullWidth
                          id="outlined-basic"
                          label=""
                          variant="outlined"
                          placeholder=""
                          size="small"
                          value={email}
                          disabled
                        />
                      </Box>
                    </Grid>
                  )}
                  <Grid item xs={12} sm={6} md={6}>
                    <Box variant="div" component="div">
                      <label>
                        Designation
                        <Typography
                          variant="body1"
                          component="span"
                          className="asterisk"
                        >
                          *
                        </Typography>
                      </label>
                      <TextField
                        fullWidth
                        id="outlined-basic"
                        label=""
                        variant="outlined"
                        placeholder=""
                        size="small"
                        value={designation}
                        disabled
                      />
                    </Box>
                  </Grid>
                </Grid>
                <Box variant="div" component="div" className="footer">
                  <Box variant="div" component="div" className="btn-group">
                    <Button
                      variant="contained"
                      size="large"
                      className="primary-btn btn"
                      onClick={submitHandler}
                      disableRipple
                    >
                      {" "}
                      Update
                    </Button>
                    <Button
                      variant="contained"
                      size="large"
                      className="secondary-btn btn"
                      disableRipple
                      onClick={cancelHandler}
                    >
                      {" "}
                      Cancel
                    </Button>
                  </Box>
                </Box>
              </Box>
            </Box>
          </Box>
        </Fade>
      </Modal>
    </>
  );
};

export default EditprofileModal;
