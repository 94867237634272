import { createSlice } from "@reduxjs/toolkit";

  
const releaseNoteSlice = createSlice({
    name: "releaseNote",
    initialState: {
        totalCount: 0,
        data: []
    },
    reducers: {
        storeReleaseNotes: (state, action) => {
            state.totalCount = action.payload.totalCount;
            state.data = action.payload.findAll;
        },
        removeReleaseNote: (state, action) => {
            state.totalCount = state.totalCount - 1;
            state.data = state.data.filter(row => row.id !== action.payload.id);
        }
    },
});
  
export const {
    storeReleaseNotes,
    removeReleaseNote
} = releaseNoteSlice.actions;
  
  export default releaseNoteSlice.reducer;