import { Box, Fade, Modal, Typography } from "@mui/material";
import React from "react";
import Button from "@mui/material/Button";
import { useDispatch } from "react-redux";
import { logout } from "../store/slices/logoutSlice";
import getTokenFromLocalStorage from "../utils/token";
import { useNavigate } from "react-router-dom";
const SignoutModal = ({ open, handleClose }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const submitHandler = async () => {
    try {
      const token = getTokenFromLocalStorage();
      await dispatch(logout(token));
      handleClose();
      navigate("/signin", { replace: true });
    } catch (error) {}
  };

  return (
    <>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={open}
        onClose={handleClose}
        closeAfterTransition
      >
        <Fade in={open}>
          <Box className="modal-wrapper modal-ui">
            <Box class="modal-body">
              <Box variant="div" component="div" className="title">
                <Typography variant="h3" component="h3">
                  Are you sure you want to sign out?
                </Typography>
                <Typography variant="body1" component="p">
                  Signing out will end your current session and require you to log
                  in again.
                </Typography>
              </Box>
              <Box variant="div" component="div" className="body"></Box>
              <Box variant="div" component="div" className="footer">
                <Box variant="div" component="div" className="btn-group">
                  <Button
                    variant="contained"
                    size="large"
                    className="primary-btn btn"
                    onClick={submitHandler}
                    disableRipple
                  >
                    {" "}
                    Sign Out
                  </Button>
                  <Button
                    variant="contained"
                    size="large"
                    className="secondary-btn btn"
                    disableRipple
                    onClick={handleClose}
                  >
                    {" "}
                    Cancel
                  </Button>
                </Box>
              </Box>
            </Box>
          </Box>
        </Fade>
      </Modal>
    </>
  );
};

export default SignoutModal;
