import {
  Box,
  Checkbox,
  FormControl,
  FormControlLabel,
  Grid,
  MenuItem,
  RadioGroup,
  Select,
  TextField,
  Typography,
  Divider,
} from '@mui/material';
import React, { useState, useEffect } from 'react';
import Button from '@mui/material/Button';
import { RiArrowLeftLine, RiArrowDownSLine } from 'react-icons/ri';
import { useLocation, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import getTokenFromLocalStorage from '../../utils/token';
import { useParams } from 'react-router-dom';
import { fetchUserById } from '../../store/slices/userByIdSlice';
import { fetchDesignations } from '../../store/slices/designationSlice';
import { editUserByIdAdmin } from '../../store/slices/editUserByAdminSlice';
import { successToast } from '../../responses/successToast';
import { displayErrorMessage1, emailformat } from '../../utils/constant';
import {
  commonerror,
  firstnameinvaliderror,
  lastnameinvaliderror,
  companynameinvaliderror,
  emailinvaliderror,
  mobileinvaliderror,
} from '../../utils/errormessage';
import axios from 'axios';
import Radio from '@mui/material/Radio';
import Skeleton from '@mui/material/Skeleton';
axios.defaults.withCredentials = true;

const Edituser = () => {
  const location = useLocation();
  let { state } = location;
  if (state === null) {
    state = {
      status: 0,
      rowsPerPage: 10,
      search: '',
      company: '1',
      currentPage: 1,
    };
  }
  const navigate = useNavigate();

  const dispatch = useDispatch();
  const token = getTokenFromLocalStorage();
  const [userType, setUserType] = useState('');
  const [firstname, setFirstname] = useState('');
  const [lastname, setLastname] = useState('');
  const [companyname, setCompanyname] = useState('');

  const [number, setNumber] = useState('');
  const [email, setEmail] = useState('');
  const [loading, setLoading] = useState(true);
  const [designation, setDesignation] = useState('');
  const [empid, setEmpid] = useState(0);
  const [internalrole, setInternalrole] = useState('');
  const designations = useSelector((state) => state.designation.designations);
  const [appNames, setAppNames] = useState([]);
  const [selectedAppRoles, setSelectedAppRoles] = useState();
  const [roleErrors, setRoleErrors] = useState({});
  const [status, setStatus] = useState('');
  const [erromessage1, setErrorMessage1] = useState('');
  const [erromessage2, setErrorMessage2] = useState('');
  const [erromessage12, setErrorMessage12] = useState('');
  const [erromessage3, setErrorMessage3] = useState('');
  const [erromessage4, setErrorMessage4] = useState('');
  const [erromessage5, setErrorMessage5] = useState('');
  const [erromessage6, setErrorMessage6] = useState('');
  const [erromessage16, setErrorMessage16] = useState('');

  const [erromessage7, setErrorMessage7] = useState('');
  const [erromessage8, setErrorMessage8] = useState('');
  const emailRegex = emailformat();
  const spaceRegex = /\s{1,}/;

  const DESIGNATIONS = [
    [
      'Software Development',
      [
        { id: 1, designation_name: 'Software Engineer - Trainee' },
        { id: 2, designation_name: 'Software Engineer' },
        { id: 3, designation_name: 'Software Engineer - Senior' },
        { id: 4, designation_name: 'Software Engineer - Lead' },
      ],
    ],
    [
      'UI/UX Design',
      [
        { id: 5, designation_name: 'UI/UX Designer - Trainee' },
        { id: 6, designation_name: 'UI/UX Designer' },
        { id: 7, designation_name: 'UI/UX Designer - Senior' },
        { id: 8, designation_name: 'UI/UX Designer - Lead' },
      ],
    ],
    [
      'Quality Assurance (QA)',
      [
        { id: 9, designation_name: 'QA Engineer - Trainee' },
        { id: 10, designation_name: 'QA Engineer' },
        { id: 11, designation_name: 'QA Engineer - Senior' },
        { id: 12, designation_name: 'QA Engineer - Lead' },
      ],
    ],
    [
      'DevOps',
      [
        { id: 13, designation_name: 'DevOps Engineer - Trainee' },
        { id: 14, designation_name: 'DevOps Engineer' },
        { id: 15, designation_name: 'DevOps Engineer - Senior' },
        { id: 16, designation_name: 'DevOps Engineer - Lead' },
      ],
    ],
    [
      'Team Leadership',
      [
        { id: 17, designation_name: 'Team Lead - Potential' },
        { id: 18, designation_name: 'Team Lead' },
        { id: 19, designation_name: 'Team Lead - Senior' },
      ],
    ],
    ['Management', [{ id: 20, designation_name: 'Delivery Manager' }]],
    [
      'Digital Marketing',
      [
        { id: 21, designation_name: 'Digital Marketing' },
        { id: 22, designation_name: 'Digital Marketing - Senior' },
        { id: 23, designation_name: 'Digital Marketing - Lead' },
      ],
    ],
    [
      'Content Creation',
      [
        { id: 24, designation_name: 'Content Writer' },
        { id: 25, designation_name: 'Content Writer - Senior' },
      ],
    ],
    [
      'Human Resources (HR)',
      [
        { id: 26, designation_name: 'HR Executive' },
        { id: 27, designation_name: 'HR Executive - Senior' },
        { id: 28, designation_name: 'HR Manager' },
      ],
    ],
    [
      'Business Development (BD)',
      [
        { id: 29, designation_name: 'BD Executive' },
        { id: 30, designation_name: 'BD Executive - Senior' },
        { id: 31, designation_name: 'BD Manager' },
        { id: 32, designation_name: 'BD Manager - Senior' },
      ],
    ],
    [
      'Administration',
      [
        { id: 33, designation_name: 'Admin Executive' },
        { id: 34, designation_name: 'Admin Manager' },
      ],
    ],
    [
      'Accounting',
      [
        { id: 35, designation_name: 'Account Executive' },
        { id: 36, designation_name: 'Account Manager' },
      ],
    ],
    ['Executive Leadership', [{ id: 37, designation_name: 'CEO' }]],
    ['Financial Leadership', [{ id: 38, designation_name: 'CFO' }]],
  ];

  const userData = useSelector((state) => state.userById.user);
  const { userId } = useParams();
  useEffect(() => {
    dispatch(fetchUserById(userId, token))
      .then(() => {
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
        console.error('Error fetching user data:', error);
      });
  }, [dispatch, token, userId]);

  const isChecked = (appId) => {
    const flag = selectedAppRoles?.some((row) => row.app_id === appId);
    return flag;
  };

  const handleRoleChange = (event) => {
    setInternalrole(event.target.value);
  };

  function capitalizeFirstLetter(str) {
    return str.charAt(0).toUpperCase() + str.slice(1);
  }

  function validateMobileNumber(mobileNumber) {
    const digitsOnly = mobileNumber.replace(/\D/g, '');

    return /^\d{10}$/.test(digitsOnly);
  }
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_API_URL}/applications?fields=id,app_name`,
          {
            headers: {
              'Content-Type': 'application/json',
              Authorization: `Bearer ${token}`,
            },
          }
        );
        const data = response.data.data;
        setAppNames(data);
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };
    fetchData();
  }, []);

  const handleChangeForApp = (appName, event) => {
    const selectedRoleId = event.target.value;
    const selectedApp = appNames.find((app) => app.id === appName);

    if (selectedApp) {
      const selectedRole = selectedApp.roles.find(
        (role) => role.id === selectedRoleId
      );

      if (selectedRole) {
        const updatedSelectedAppRoles = selectedAppRoles.map((item) => {
          if (item.app_id === selectedApp.id) {
            return {
              app_id: selectedApp.id,
              role_id: selectedRole.id,
            };
          } else {
            return item;
          }
        });
        setSelectedAppRoles(updatedSelectedAppRoles);
        setRoleErrors((prevState) => ({
          ...prevState,
          [appName]: null,
        }));
      } else {
        setRoleErrors((prevState) => ({
          ...prevState,
          [appName]: 'Role is required',
        }));
      }
    } else {
    }
  };

  const handleCheckboxChange = (appId, event) => {
    const isChecked = event.target.checked;

    if (isChecked) {
      setSelectedAppRoles((prevState) => {
        const existingIndex = prevState.findIndex(
          (item) => item.app_id === appId
        );

        if (existingIndex === -1) {
          return [{ app_id: appId, role_id: -1 }, ...prevState];
        } else {
          // If the app ID is already present, update the existing item and move it to the beginning
          const updatedRoles = [...prevState];
          const existingItem = updatedRoles.splice(existingIndex, 1)[0];
          updatedRoles.unshift(existingItem);
          return updatedRoles;
        }
      });
      // Clear the error message for this app
      setRoleErrors((prevState) => ({
        ...prevState,
        [appId]: null,
      }));
    } else {
      setSelectedAppRoles((prevState) =>
        prevState.filter((item) => item.app_id !== appId)
      );
    }
  };

  useEffect(() => {
    if (userData?.data) {
      setUserType(userData?.data?.type);
      setFirstname(userData?.data?.first_name);
      setLastname(userData?.data?.last_name);
      setCompanyname(userData?.data?.company_id);
      setNumber(userData?.data?.contact_number);
      setEmail(userData?.data?.email);
      setEmpid(userData?.data?.employee_number);
      setInternalrole(userData?.data?.role);
      setDesignation(userData?.data.designation_id);
      setStatus(userData?.data?.status);

      const applicationDetails = userData?.data?.application_details || [];
      setSelectedAppRoles(
        applicationDetails.map((row) => ({
          app_id: row.app_id,
          role_id: row.role_id,
        }))
      );
    }
  }, [userData]);

  useEffect(() => {
    if (designations.length > 0) {
      return;
    }
    dispatch(fetchDesignations(token));
  }, [dispatch, designations]);

  const editHandler = async () => {
    let flag = 0;
    const hasRoleErrors = selectedAppRoles.some((role) => role.role_id === -1);
    if (hasRoleErrors) {
      flag = 1;
    }

    if (!firstname) {
      displayErrorMessage1('Please enter first name', setErrorMessage1);
      flag = 1;
    }
    if (!lastname) {
      displayErrorMessage1('Please enter last name', setErrorMessage2);
      flag = 1;
    }

    if (!companyname && userType === 'internal') {
      displayErrorMessage1('Please enter company', setErrorMessage12);
      flag = 1;
    }

    if (!number) {
      displayErrorMessage1('Please enter contact number', setErrorMessage3);
      flag = 1;
    }
    if (!email) {
      displayErrorMessage1('Please enter email', setErrorMessage4);
      flag = 1;
    }
    if (
      userType === 'internal' &&
      userData?.data?.type === 'internal' &&
      !empid
    ) {
      displayErrorMessage1('Please enter employee number', setErrorMessage7);
      flag = 1;
    }
    if (userType === 'internal' && !designation) {
      displayErrorMessage1('Please select the designation', setErrorMessage8);
      flag = 1;
    }
    if (!emailRegex.test(email)) {
      displayErrorMessage1(emailinvaliderror, setErrorMessage4);
      flag = 1;
    }
    if (spaceRegex.test(firstname)) {
      displayErrorMessage1(firstnameinvaliderror, setErrorMessage5);
      flag = 1;
    }
    if (spaceRegex.test(lastname)) {
      displayErrorMessage1(lastnameinvaliderror, setErrorMessage6);
      flag = 1;
    }
    if (spaceRegex.test(companyname)) {
      displayErrorMessage1(companynameinvaliderror, setErrorMessage16);
      flag = 1;
    }
    if (!validateMobileNumber(number)) {
      displayErrorMessage1(mobileinvaliderror, setErrorMessage3);
      flag = 1;
    }

    if (flag !== 0) {
      return;
    }
    const editData = {
      first_name: firstname,
      last_name: lastname,
      company_id:
        userType === 'internal' && companyname === 2
          ? 2
          : companyname === 1
          ? 1
          : null,
      contact_number: number,
      email: email,
      designation_id: designation,
      type: userType,
      role: internalrole,
      employee_number: userType === 'internal' ? empid : null,
      status: status,
      applicationDetails: selectedAppRoles,
    };
    dispatch(editUserByIdAdmin(userId, token, editData))
      .then((response) => {
        if (response.success) {
          successToast(response.message);
          navigate('/users');
        } else {
          console.error('Error creating user:', response.error);
        }
      })
      .catch((error) => {
        console.error('Error creating user:', error.message);
      });
  };

  return (
    <>
      <>
        <Box className='content-header'>
          <Typography variant='h2' component='h2' className='title-with-btn'>
            {' '}
            <Button
              variant='outlined'
              className='back-btn'
              startIcon={<RiArrowLeftLine />}
              disableRipple
              onClick={() =>
                navigate('/users', {
                  state: {
                    status: state.status,
                    search: state.search,
                    rowsPerPage: state.rowsPerPage,
                    company: state.company,
                    currentPage: state.currentPage,
                  },
                })
              }
            ></Button>{' '}
            Edit User
          </Typography>
        </Box>
        <Box className='content-layout user-field-module-wrapper'>
          <Box variant='div' component='div' className='createuser-wrap p-24'>
            <Grid container spacing={4}>
              <Grid item xs={12} sm={12} md={12} lg={6}>
                <Box variant='div' component='div' className='user-detail'>
                  <Typography variant='h3' component='h3'>
                    User Details
                  </Typography>
                  <Grid container spacing={2}>
                    <Grid item xs={12} sm={12} md={12} lg={12}>
                      <Box variant='div' component='div'>
                        <label>
                          Type{' '}
                          <Typography
                            variant='body1'
                            component='span'
                            className='asterisk'
                          >
                            *
                          </Typography>
                        </label>
                        <RadioGroup
                          row
                          name='row-radio-buttons-group'
                          value={userType}
                          onChange={(e) => {
                            setUserType(e.target.value);
                          }}
                        >
                          <FormControlLabel
                            value='internal'
                            label='Internal User'
                            control={<Radio disableRipple />}
                          />
                          <FormControlLabel
                            value='external'
                            label='External User'
                            control={<Radio disableRipple />}
                          />
                        </RadioGroup>
                      </Box>
                    </Grid>
                    {userType === 'internal' &&
                      userData?.data?.type === 'internal' && (
                        <>
                          <Grid item xs={12} sm={12} md={6} lg={6}>
                            <Box variant='div' component='div'>
                              <label>
                                Company
                                <Typography
                                  variant='body1'
                                  component='span'
                                  className='asterisk'
                                >
                                  *
                                </Typography>
                              </label>
                              {loading ? (
                                <Skeleton
                                  variant='rect'
                                  width='100%'
                                  height={40}
                                />
                              ) : (
                                <FormControl fullWidth>
                                  <Select
                                    id='demo-simple-select'
                                    value={companyname}
                                    onChange={(e) => {
                                      setCompanyname(e.target.value);
                                      if (e.target.value) {
                                        setErrorMessage12('');
                                      }
                                      if (!spaceRegex.test(e.target.value)) {
                                        setErrorMessage16('');
                                      }
                                    }}
                                    size='small'
                                    IconComponent={RiArrowDownSLine}
                                  >
                                    <MenuItem value={1}>
                                      Crest Infosystems Pvt. Ltd.
                                    </MenuItem>
                                    <MenuItem value={2}>
                                      Crest Skillserve Pvt. Ltd.
                                    </MenuItem>
                                  </Select>
                                </FormControl>
                              )}
                              {erromessage12 && (
                                <Typography
                                  variant='body1'
                                  component='p'
                                  className='error'
                                >
                                  {erromessage12}
                                </Typography>
                              )}
                              {erromessage16 && (
                                <Typography
                                  variant='body1'
                                  component='p'
                                  className='error'
                                >
                                  {erromessage16}
                                </Typography>
                              )}
                            </Box>
                          </Grid>

                          <Grid item xs={12} sm={12} md={6} lg={6}>
                            <Box variant='div' component='div'>
                              <label>
                                Employee Id
                                <Typography
                                  variant='body1'
                                  component='span'
                                  className='asterisk'
                                >
                                  *
                                </Typography>
                              </label>
                              {loading ? (
                                <Skeleton
                                  variant='rect'
                                  width='100%'
                                  height={40}
                                />
                              ) : (
                                <TextField
                                  fullWidth
                                  id='outlined-basic'
                                  label=''
                                  variant='outlined'
                                  size='small'
                                  placeholder='Enter Employee Id'
                                  value={empid}
                                  onChange={(e) => {
                                    setEmpid(e.target.value);
                                    if (e.target.value) {
                                      setErrorMessage7('');
                                    }
                                  }}
                                />
                              )}
                              {erromessage7 && userType === 'internal' && (
                                <Typography
                                  variant='body1'
                                  component='p'
                                  className='error'
                                >
                                  {erromessage7}
                                </Typography>
                              )}
                            </Box>
                          </Grid>
                        </>
                      )}{' '}
                    {userType === 'internal' &&
                      userData?.data?.type === 'external' && (
                        <>
                          <Grid xs={12} sm={12} md={6} lg={6}>
                            <Box variant='div' component='div'>
                              <label>
                                Company
                                <Typography
                                  variant='body1'
                                  component='span'
                                  className='asterisk'
                                >
                                  *
                                </Typography>
                              </label>
                              {loading ? (
                                <Skeleton
                                  variant='rect'
                                  width='100%'
                                  height={40}
                                />
                              ) : (
                                <FormControl fullWidth>
                                  <Select
                                    id='demo-simple-select'
                                    value={companyname}
                                    onChange={(e) => {
                                      setCompanyname(e.target.value);
                                      if (e.target.value) {
                                        setErrorMessage12('');
                                      }
                                      if (!spaceRegex.test(e.target.value)) {
                                        setErrorMessage16('');
                                      }
                                    }}
                                    size='small'
                                    IconComponent={RiArrowDownSLine}
                                  >
                                    <MenuItem value={1}>
                                      Crest Infosystems Pvt. Ltd.
                                    </MenuItem>
                                    <MenuItem value={2}>
                                      Crest Skillserve Pvt. Ltd.
                                    </MenuItem>
                                  </Select>
                                </FormControl>
                              )}
                              {erromessage12 && (
                                <Typography
                                  variant='body1'
                                  component='p'
                                  className='error'
                                >
                                  {erromessage12}
                                </Typography>
                              )}
                              {erromessage16 && (
                                <Typography
                                  variant='body1'
                                  component='p'
                                  className='error'
                                >
                                  {erromessage16}
                                </Typography>
                              )}
                            </Box>
                          </Grid>
                        </>
                      )}
                    <Grid item xs={12} sm={12} md={6} lg={6}>
                      <Box variant='div' component='div'>
                        <label>
                          First Name{' '}
                          <Typography
                            variant='body1'
                            component='span'
                            className='asterisk'
                          >
                            *
                          </Typography>
                        </label>
                        {loading ? (
                          <Skeleton variant='rect' width='100%' height={40} />
                        ) : (
                          <TextField
                            fullWidth
                            id='outlined-basic'
                            label=''
                            variant='outlined'
                            size='small'
                            placeholder='Enter First Name'
                            value={capitalizeFirstLetter(firstname)}
                            onChange={(e) => {
                              setFirstname(e.target.value);
                              if (e.target.value) {
                                setErrorMessage1('');
                              }
                              if (!spaceRegex.test(e.target.value)) {
                                setErrorMessage5('');
                              }
                            }}
                          />
                        )}
                        {erromessage1 && (
                          <Typography
                            variant='body1'
                            component='p'
                            className='error'
                          >
                            {erromessage1}
                          </Typography>
                        )}
                        {erromessage5 && (
                          <Typography
                            variant='body1'
                            component='p'
                            className='error'
                          >
                            {erromessage5}
                          </Typography>
                        )}
                      </Box>
                    </Grid>
                    <Grid item xs={12} sm={12} md={6} lg={6}>
                      <Box variant='div' component='div'>
                        <label>
                          Last Name{' '}
                          <Typography
                            variant='body1'
                            component='span'
                            className='asterisk'
                          >
                            *
                          </Typography>
                        </label>
                        {loading ? (
                          <Skeleton variant='rect' width='100%' height={40} />
                        ) : (
                          <TextField
                            fullWidth
                            id='outlined-basic'
                            label=''
                            variant='outlined'
                            size='small'
                            placeholder='Enter Last Name'
                            value={capitalizeFirstLetter(lastname)}
                            onChange={(e) => {
                              setLastname(e.target.value);
                              if (e.target.value) {
                                setErrorMessage2('');
                              }
                              if (!spaceRegex.test(e.target.value)) {
                                setErrorMessage6('');
                              }
                            }}
                          />
                        )}
                        {erromessage2 && (
                          <Typography
                            variant='body1'
                            component='p'
                            className='error'
                          >
                            {erromessage2}
                          </Typography>
                        )}
                        {erromessage6 && (
                          <Typography
                            variant='body1'
                            component='p'
                            className='error'
                          >
                            {erromessage6}
                          </Typography>
                        )}
                      </Box>
                    </Grid>
                    <Grid item xs={12} sm={12} md={6} lg={6}>
                      <Box variant='div' component='div'>
                        <label>
                          Contact Number{' '}
                          <Typography
                            variant='body1'
                            component='span'
                            className='asterisk'
                          >
                            *
                          </Typography>
                        </label>
                        {loading ? (
                          <Skeleton variant='rect' width='100%' height={40} />
                        ) : (
                          <TextField
                            fullWidth
                            id='outlined-basic'
                            label=''
                            variant='outlined'
                            size='small'
                            type='number'
                            placeholder='Enter Contact Number'
                            value={number}
                            onChange={(e) => {
                              setNumber(e.target.value);
                              if (validateMobileNumber(e.target.value)) {
                                setErrorMessage3('');
                                if (e.target.value) {
                                  setErrorMessage3('');
                                }
                              }
                            }}
                          />
                        )}
                        {erromessage3 && (
                          <Typography
                            variant='body1'
                            component='p'
                            className='error'
                          >
                            {erromessage3}
                          </Typography>
                        )}
                      </Box>
                    </Grid>
                    <Grid item xs={12} sm={12} md={6} lg={6}>
                      <Box variant='div' component='div'>
                        <label>
                          Email
                          <Typography
                            variant='body1'
                            component='span'
                            className='asterisk'
                          >
                            *
                          </Typography>
                        </label>
                        {loading ? (
                          <Skeleton variant='rect' width='100%' height={40} />
                        ) : (
                          <TextField
                            fullWidth
                            id='outlined-basic'
                            label=''
                            variant='outlined'
                            size='small'
                            placeholder='Enter Email'
                            value={email}
                            onChange={(e) => {
                              setEmail(e.target.value);
                              if (e.target.value) {
                                setErrorMessage4('');
                              }
                              if (!emailRegex.test(email)) {
                                setErrorMessage4('');
                              }
                            }}
                          />
                        )}
                        {erromessage4 && (
                          <Typography
                            variant='body1'
                            component='p'
                            className='error'
                          >
                            {erromessage4}
                          </Typography>
                        )}
                      </Box>
                    </Grid>
                    {userType === 'internal' && (
                      <>
                        <Grid item xs={12} sm={12} md={6} lg={6}>
                          <Box variant='div' component='div'>
                            <label>
                              Designation
                              <Typography
                                variant='body1'
                                component='span'
                                className='asterisk'
                              >
                                *
                              </Typography>
                            </label>
                            {loading ? (
                              <Skeleton
                                variant='rect'
                                width='100%'
                                height={40}
                              />
                            ) : (
                              <FormControl fullWidth>
                                <Select
                                  id='demo-simple-select'
                                  value={designation}
                                  onChange={(e) => {
                                    setDesignation(e.target.value);
                                    if (e.target.value) {
                                      setErrorMessage8('');
                                    }
                                  }}
                                  size='small'
                                  IconComponent={RiArrowDownSLine}
                                >
                                  {designations.map((category, index) => [
                                    <MenuItem
                                      key={category[0]}
                                      disabled
                                      value={null}
                                      style={{
                                        color: 'var(--green)',
                                        fontWeight: 'bold',
                                        opacity: '1',
                                      }}
                                    >
                                      {category[0]}
                                    </MenuItem>,

                                    ...category[1].map((designation) => (
                                      <MenuItem
                                        key={designation.id}
                                        value={designation.id}
                                        style={{
                                          marginLeft: '1rem',
                                          marginRight: '0.5rem',
                                        }}
                                      >
                                        {designation.designation_name}
                                      </MenuItem>
                                    )),
                                    index !== DESIGNATIONS.length - 1 && (
                                      <Divider />
                                    ),
                                  ])}
                                </Select>
                              </FormControl>
                            )}
                            {erromessage8 && (
                              <Typography
                                variant='body1'
                                component='p'
                                className='error'
                              >
                                {erromessage8}
                              </Typography>
                            )}
                          </Box>
                        </Grid>

                        <Grid item xs={12} sm={12} md={6} lg={6}>
                          <Box variant='div' component='div'>
                            <label>
                              Role
                              <Typography
                                variant='body1'
                                component='span'
                                className='asterisk'
                              >
                                *
                              </Typography>
                            </label>
                            {loading ? (
                              <Skeleton
                                variant='rect'
                                width='100%'
                                height={40}
                              />
                            ) : (
                              <FormControl fullWidth>
                                <Select
                                  id='demo-simple-select'
                                  value={internalrole}
                                  onChange={handleRoleChange}
                                  size='small'
                                  IconComponent={RiArrowDownSLine}
                                >
                                  <MenuItem value={'admin'}>Admin</MenuItem>
                                  <MenuItem value={'user'}>User</MenuItem>
                                </Select>
                              </FormControl>
                            )}
                          </Box>
                        </Grid>
                      </>
                    )}
                    <Grid item xs={12} sm={12} md={6} lg={6}>
                      <Box variant='div' component='div'>
                        <label>
                          Status
                          <Typography
                            variant='body1'
                            component='span'
                            className='asterisk'
                          >
                            *
                          </Typography>
                        </label>
                        {loading ? (
                          <Skeleton variant='rect' width='100%' height={40} />
                        ) : (
                          <FormControl fullWidth>
                            <Select
                              id='demo-simple-select'
                              value={status}
                              onChange={(e) => setStatus(e.target.value)}
                              size='small'
                              IconComponent={RiArrowDownSLine}
                              //defaultValue=""
                            >
                              <MenuItem value={'active'}>Active</MenuItem>
                              <MenuItem value={'archive'}>Archive</MenuItem>
                            </Select>
                          </FormControl>
                        )}
                      </Box>
                    </Grid>
                  </Grid>
                </Box>
              </Grid>
              <Grid item xs={12} sm={12} md={12} lg={6}>
                <Box variant='div' component='div' className='app-details'>
                  <Typography variant='h3' component='h3'>
                    Application Details{' '}
                    <Typography variant='body1' component='span'>
                      (Assign Applications & Role)
                    </Typography>
                  </Typography>
                  {loading
                    ? // Display skeleton loaders while loading
                      Array.from({ length: 3 }).map((_, index) => (
                        <Grid
                          container
                          spacing={2}
                          direction='row'
                          alignItems='center'
                          key={index}
                        >
                          <Grid item xs={12} sm={5} md={5} lg={5}>
                            <Skeleton variant='rect' width='100%' height={40} />
                          </Grid>
                          <Grid item xs={12} sm={7} md={7} lg={7}>
                            <Skeleton variant='rect' width='100%' height={40} />
                          </Grid>
                        </Grid>
                      ))
                    : appNames.length > 0
                    ? appNames.map((appName, index) => {
                        const selectedRole = selectedAppRoles.find(
                          (selectedRole) => selectedRole.app_id === appName.id
                        );
                        const roleError =
                          selectedRole && selectedRole.role_id === -1;

                        return (
                          <Grid
                            container
                            mb={2}
                            columnSpacing={2}
                            rowSpacing={0}
                            direction='row'
                            alignItems='center'
                            key={appName.id}
                          >
                            <Grid item xs={12} sm={5} md={5} lg={5}>
                              {/* <label className="empty-label"></label> */}
                              <FormControlLabel
                                control={
                                  <Checkbox
                                    disableRipple
                                    checked={isChecked(appName.id)}
                                  />
                                }
                                label={appName.app_name}
                                onChange={(e) =>
                                  handleCheckboxChange(appName.id, e)
                                }
                              />
                            </Grid>
                            <Grid item xs={12} sm={7} md={7} lg={7}>
                              <label>Select Role</label>
                              <FormControl fullWidth>
                                <Select
                                  id={`${appName.id}`}
                                  value={
                                    appName.roles.find((item) => {
                                      return (
                                        item.id ===
                                        (selectedRole
                                          ? selectedRole.role_id
                                          : -1)
                                      );
                                    })?.id || null
                                  }
                                  onChange={(event) => {
                                    handleChangeForApp(appName.id, event);
                                  }}
                                  size='small'
                                  closeMenuOnSelect
                                  IconComponent={RiArrowDownSLine}
                                  disabled={!isChecked(appName.id)}
                                  error={roleError}
                                >
                                  {appName.roles.length > 0
                                    ? appName.roles.map((role) => (
                                        <MenuItem key={role.id} value={role.id}>
                                          {role.role_name}
                                        </MenuItem>
                                      ))
                                    : null}
                                </Select>
                                {roleError && (
                                  <Typography variant='caption' color='error'>
                                    Please select a role for this app
                                  </Typography>
                                )}
                              </FormControl>
                            </Grid>
                          </Grid>
                        );
                      })
                    : null}
                </Box>
              </Grid>
            </Grid>
          </Box>
        </Box>
        <Box variant='div' component='div' className='footer-btn'>
          <Box variant='div' component='div' className='btn-group'>
            <Button
              variant='contained'
              size='large'
              className='primary-btn btn'
              onClick={editHandler}
              disableRipple
            >
              {' '}
              Save
            </Button>
            <Button
              variant='contained'
              size='large'
              className='secondary-btn btn'
              disableRipple
              onClick={() => {
                navigate('/users', {
                  state: {
                    status: state.status,
                    internalCurrentPage: state.internalCurrentPage,
                    externalCurrentPage: state.externalCurrentPage,
                    archievedCurrentPage: state.archievedCurrentPage,
                    search: state.search,
                    rowsPerPage: state.rowsPerPage,
                  },
                });
              }}
            >
              {' '}
              Cancel
            </Button>
          </Box>
        </Box>
      </>
    </>
  );
};

export default Edituser;
