import React from "react";
import ReactDOM from "react-dom/client";
import { Provider } from 'react-redux';
import store,{persistor} from "./store/store"; 
import FeedbackModal from "./responses/feedbackModal";
import App from "./App";
import { PersistGate } from 'redux-persist/integration/react';

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
   <Provider store={store}>
   <PersistGate loading={null} persistor={persistor}>
      <App />
      <FeedbackModal />
      </PersistGate>
    </Provider>
  </React.StrictMode>
);
// ReactDOM.render(
//   <React.StrictMode>
//     <Provider store={store}>
//       <PersistGate loading={null} persistor={persistor}>
//         <App />
//         <FeedbackModal />
//       </PersistGate>
//     </Provider>
//   </React.StrictMode>,
//   document.getElementById("root")
// );