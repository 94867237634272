import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import getTokenFromLocalStorage from "../../utils/token";
import { setFeedback } from "./feedbackSlice";
import axios from "axios";
axios.defaults.withCredentials = true;
//import { useDispatch } from 'react-redux';

// Create the asynchronous thunk to fetch application data from the API
export const fetchCreateRoles = createAsyncThunk(
  "applications/fetchCreateRoles",
  async ({ roleData, createroleId }, { rejectWithValue, dispatch }) => {
    try {
      const token = getTokenFromLocalStorage();
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/roles/${createroleId}`,
        roleData,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );
      if (response.data.status) {
        return response.data.data;
      } else {
        return rejectWithValue(response.data.message);
      }
    } catch (error) {
      dispatch(
        setFeedback({
          status: "fail",
          message: error.response.data.message,
          code: error.response.status,
        })
      );
      return rejectWithValue(error.response.data);
    }
  }
);

export const fetchModules = createAsyncThunk(
  "applications/fetchModules",
  async (createroleId, { rejectWithValue, dispatch }) => {
    try {
      const token = getTokenFromLocalStorage();
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/applications/${createroleId}/modules?app_fields=id,app_name&module_fields=id,module_name,permission`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );
      if (response.data.status) {
        return response.data.data;
      } else {
        return rejectWithValue(response.data.message);
      }
    } catch (error) {
      dispatch(
        setFeedback({
          status: "fail",
          message: error.response.data.message,
          code: error.response.status,
        })
      );
      return rejectWithValue(error.response.data);
    }
  }
);

const initialState = {
  role: null, // Initialize role as null or an empty object depending on your use case
  error: null,
  appId: null,
  application: null,
  modules: [],
};

const rolecreateSlice = createSlice({
  name: "rolecreate",
  initialState,
  reducers: {
    roleCreateSuccess: (state, action) => {
      state.role = action.payload;
      state.error = null;
      state.appId = action.payload.app_id;
    },
    roleCreateFailure: (state, action) => {
      state.role = null;

      state.error = action.payload;
    },
  },
  extraReducers: (builder) => {
    // Handle additional reducers from other actions if needed
    builder.addCase(fetchModules.fulfilled, (state, action) => {
      state.application = action.payload.application;
      state.modules = action.payload.modules;
    });
  },
});

// Export action creators
export const { roleCreateSuccess, roleCreateFailure } = rolecreateSlice.actions;

export default rolecreateSlice.reducer;
