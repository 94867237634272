export const commonerror = "This is required field";

export const emailerror = "Please enter your email";

export const passworderror = "Please enter your password";

export const emailinvaliderror = "Please enter valid email";

export const passwordformaterror =
  "Password must be 8+ characters with uppercase, lowercase, digit, and special character";

export const firstnameinvaliderror = "Please enter valid firstname";

export const lastnameinvaliderror = "Please enter valid lastname";

export const companynameinvaliderror = "Please enter valid company";

export const mobileinvaliderror = "Please enter valid mobile number";
