import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { setFeedback } from "./feedbackSlice";
import axios from "axios";
axios.defaults.withCredentials = true;
// Async thunk action to delete the user by ID
export const deleteProfileImgById = createAsyncThunk(
  "users/deleteProfileImageById", // Updated action name
  async ({ userId, token }, { dispatch }) => {
    try {
      const response = await axios.delete(
        `${process.env.REACT_APP_API_URL}/users/${userId}/remove-profileimage`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );
      return { success: true, message: response.data.message };
      //   return response.data; // Return the response data directly
    } catch (error) {
      dispatch(
        setFeedback({
          status: "fail",
          message: error.response.data.message || "Failed to remove image.",
        })
      );
      throw new Error(error.response.data.message || "Failed to remove image.");
    }
  }
);

// Create the user slice with initial state
const deleteImgByIdSlice = createSlice({
  name: "deleteProfileImage", // Updated slice name
  initialState: {
    loading: false,
    error: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(deleteProfileImgById.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(deleteProfileImgById.fulfilled, (state) => {
        state.loading = false;
        state.error = null;
      })
      .addCase(deleteProfileImgById.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      });
  },
});

export default deleteImgByIdSlice.reducer;
