import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import getTokenFromLocalStorage from "../../utils/token";
import { setFeedback } from "./feedbackSlice";
import axios from "axios";
axios.defaults.withCredentials = true;
// Create the asynchronous thunk to fetch application data from the API
export const fetchApplications = createAsyncThunk(
  "applications/fetchApplications",
  async (_, { rejectWithValue, dispatch }) => {
    try {
      const token = getTokenFromLocalStorage(); // Replace with your actual API token
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/applications?fields=id,app_name`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );
      if (response.data.status) {
        return response.data.data;
      } else {
        return rejectWithValue(response.data.message);
      }
    } catch (error) {
      dispatch(
        setFeedback({
          status: "fail",
          message: error.response.data.message,
          code: error.response.status,
        })
      );
      return rejectWithValue(error.response.data);
    }
  }
);

const applicationSlice = createSlice({
  name: "applications",
  initialState: [],
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchApplications.fulfilled, (state, action) => {
      return action.payload;
    });
  },
});

export default applicationSlice.reducer;
