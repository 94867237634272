import { Box, Grid, TextField, Typography, Skeleton } from "@mui/material";
import React, { useState, useEffect } from "react";
import Button from "@mui/material/Button";
import { RiArrowLeftLine } from "react-icons/ri";
import { useNavigate, useParams } from "react-router-dom";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import ToggleButton from "@mui/material/ToggleButton";
import ToggleButtonGroup from "@mui/material/ToggleButtonGroup";
import { useDispatch, useSelector } from "react-redux";
import {
  fetchCreateRoles,
  fetchModules,
} from "../../../store/slices/rolecreateSlice";
import "./role.scss";
import { successToast } from "../../../responses/successToast";

const Rolecreate = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const rolecreate = useSelector((state) => state.rolecreate);
  const { modules, application } = useSelector((state) => state.rolecreate);
  const { id: createroleId } = useParams();
  const [name, setName] = useState("");
  const [errormessage, setErrorMessage] = useState("");
  const [loading, setLoading] = useState(true);
  const defaultToggles = modules.reduce((toggles, module) => {
    toggles[module.id] = "none";
    return toggles;
  }, {});
  const defaultPermissions = modules.reduce((permissions, module) => {
    permissions[module.id] = "none";
    return permissions;
  }, {});
  const [rolePermissions, setRolePermissions] = useState(defaultPermissions);
  const [moduleToggles, setModuleToggles] = useState(defaultToggles);

  const handleAddRole = async () => {
    const finalPermissions =
      Object.keys(rolePermissions).length === 0
        ? defaultPermissions
        : rolePermissions;

    const permissionsData = Object.keys(rolePermissions).map((moduleId) => ({
      module_id: moduleId,
      permission: finalPermissions[moduleId],
    }));

    const roleData = {
      role_name: name,
      permissions: permissionsData,
    };
    if (!name) {
      setErrorMessage("Please Enter a Role Name");
      return;
    }

    try {
      await dispatch(fetchCreateRoles({ roleData, createroleId })).then(
        (response) => {
          if (response.meta.requestStatus === "fulfilled") {
            successToast("Role Created Successfully");
            navigate(`/applications/${createroleId}/roles`);
          } else if (response.meta.requestStatus === "rejected") {
            console.error("Error editing role:", response.payload);
          }
        }
      );
    } catch (error) {}
  };
  useEffect(() => {
    setLoading(false);
  }, []);

  useEffect(() => {
    if (rolecreate.status === true) {
      navigate(`/applications/${createroleId}/roles`);
    }
  }, [rolecreate.status]);

  useEffect(() => {
    const timer = setTimeout(() => {
      dispatch(fetchModules(createroleId));
    }, 1000); 

    return () => clearTimeout(timer);
  }, []);


  const handleAlignment = (moduleId, newValue) => {
    const updatedValue = newValue === "full" ? "full" : newValue;

    setModuleToggles((prevToggles) => ({
      ...prevToggles,
      [moduleId]: updatedValue,
    }));
    setRolePermissions((prevPermissions) => ({
      ...prevPermissions,
      [moduleId]: updatedValue,
    }));
  };
  return (
    <>
      <Box className="content-header">
        <Typography variant="h2" component="h2" className="title-with-btn">
          {" "}
          <Button
            variant="outlined"
            className="back-btn"
            startIcon={<RiArrowLeftLine />}
            disableRipple
            onClick={() => navigate(`/applications/${createroleId}/roles`)}
          ></Button>{" "}
          Create Role for {application?.app_name}
        </Typography>
      </Box>
      <Box className="content-layout role-form-wrapper">
        <Box variant="div" component="div" className="create-role-wrap p-24">
          <Box variant="div" component="div" className="role-detail">
            <Typography variant="h3" component="h3">
              Role Details
            </Typography>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={12} md={6} lg={6}>
                <Box variant="div" component="div">
                  <label>
                    Role Name
                    <Typography
                      variant="body1"
                      component="span"
                      className="asterisk"
                    >
                      *
                    </Typography>
                  </label>
                  {loading ? (
                    <Skeleton variant="text" width={200} height={40} />
                  ) : (
                    <TextField
                      fullWidth
                      id="outlined-basic"
                      label=""
                      variant="outlined"
                      size="small"
                      placeholder="Enter Role Name"
                      onChange={(e) => {
                        setName(e.target.value);
                        if (e.target.value) {
                          setErrorMessage("");
                        }
                      }}
                    />
                  )}

                  {errormessage && <div className="error">{errormessage}</div>}
                </Box>
              </Grid>
            </Grid>
          </Box>
        </Box>
        <Box variant="div" component="div" className="create-role-wrap">
          <Box variant="div" component="div" className="role-info">
            <Typography variant="h3" component="h3" className="role-title" sx={{marginBottom:"20px !important"}}>
              Role Permissions
            </Typography>
            <Box item xs={12} className="role-list-wrapper-scroll">
              <Table
                sx={{ minWidth: 380 }}
                aria-label="table"
                className="listing-table role-list-wrapper"
              >
                <TableHead>
                  <TableRow>
                    <TableCell align="left">Module</TableCell>
                    <TableCell align="left" className="permission-th">
                      Permissions
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {loading
                    ? Array.from({ length: 4 }).map((_, index) => (
                        <TableRow>
                          <TableCell colSpan={2}>
                            <Skeleton
                              variant="text"
                              width="100%"
                              height={40}
                            />
                          </TableCell>
                        </TableRow>
                      ))
                    : modules.map((module) => (
                        <TableRow
                          key={module.id}
                          sx={{
                            "&:last-child td, &:last-child th": { border: 0 },
                          }}
                        >
                          <TableCell align="left">
                            <Typography variant="body1" component="p">
                              {module.module_name}
                            </Typography>
                          </TableCell>
                          <TableCell align="left">
                            <Box
                              variant="div"
                              component="div"
                              className="permission-button"
                            >
                              {module.permission === "2" ? (
                                <ToggleButtonGroup
                                  value={
                                    moduleToggles[module.id] !== null
                                      ? moduleToggles[module.id]
                                      : "full"
                                  }
                                  exclusive
                                  onChange={(e, newValue) =>
                                    handleAlignment(module.id, newValue)
                                  }
                                  aria-label="text alignment"
                                >
                                  <ToggleButton
                                    value="view"
                                    aria-label="right aligned"
                                  >
                                    View Only
                                  </ToggleButton>
                                  <ToggleButton
                                    value="none"
                                    aria-label="justified"
                                  >
                                    None
                                  </ToggleButton>
                                </ToggleButtonGroup>
                              ) : (
                                <ToggleButtonGroup
                                  value={
                                    moduleToggles[module.id] !== null
                                      ? moduleToggles[module.id]
                                      : "full"
                                  }
                                  exclusive
                                  onChange={(e, newValue) =>
                                    handleAlignment(module.id, newValue)
                                  }
                                  aria-label="text alignment"
                                >
                                  <ToggleButton
                                    value="full"
                                    aria-label="left aligned"
                                  >
                                    Full
                                  </ToggleButton>
                                  <ToggleButton
                                    value="add_update"
                                    aria-label="right aligned"
                                  >
                                    Add & Update Only
                                  </ToggleButton>
                                  <ToggleButton
                                    value="view"
                                    aria-label="right aligned"
                                  >
                                    View Only
                                  </ToggleButton>
                                  <ToggleButton
                                    value="none"
                                    aria-label="justified"
                                  >
                                    None
                                  </ToggleButton>
                                </ToggleButtonGroup>
                              )}
                            </Box>
                          </TableCell>
                        </TableRow>
                      ))}
                </TableBody>
              </Table>
            </Box>
          </Box>
        </Box>
      </Box>
      <Box variant="div" component="div" className="footer-btn">
        <Box variant="div" component="div" className="btn-group">
          <Button
            variant="contained"
            size="large"
            className="primary-btn btn"
            disableRipple
            onClick={async () => {
              await handleAddRole();
            }}
          >
            {" "}
            Save
          </Button>
          <Button
            variant="contained"
            size="large"
            className="secondary-btn btn"
            disableRipple
            onClick={() => navigate(`/applications/${createroleId}/roles`)}
          >
            {" "}
            Cancel
          </Button>
        </Box>
      </Box>
    </>
  );
};

export default Rolecreate;
