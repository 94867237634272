import { createSlice } from "@reduxjs/toolkit";
import { setFeedback } from "./feedbackSlice";
import axios from "axios";
axios.defaults.withCredentials = true;

const initialState = {
  isLoading: false,
  success: false,
  error: null,
  email: "",
};

const forgetPasswordSlice = createSlice({
  name: "forgetPassword",
  initialState,
  reducers: {
    forgetPasswordStart: (state) => {
      state.isLoading = true;
      state.success = false;
      state.error = null;
    },
    forgetPasswordSuccess: (state, action) => {
      state.isLoading = false;
      state.success = true;
      state.error = null;
      state.email = action.payload.email;
    },
    forgetPasswordFailure: (state, action) => {
      state.isLoading = false;
      state.success = false;
      state.error = action.payload;
    },
  },
});

export const {
  forgetPasswordStart,
  forgetPasswordSuccess,
  forgetPasswordFailure,
} = forgetPasswordSlice.actions;

export default forgetPasswordSlice.reducer;

export const forgetPassword = (email) => async (dispatch) => {
  try {
    dispatch(forgetPasswordStart());

    const response = await axios.post(
      `${process.env.REACT_APP_API_URL}/users/forgot-password`,
      { email },
      {
        headers: {
          "Content-Type": "application/json",
        },
      }
    );

    const data = response.data;
    dispatch(forgetPasswordSuccess({ email }));
    return { success: true, message: data.message };
  } catch (error) {
    dispatch(forgetPasswordFailure(error.message));
    dispatch(
      setFeedback({
        status: "fail",
        message: error.response.data.message,
        code: error.response.status,
      })
    );
    return { success: false, error: error.message };
  }
};
