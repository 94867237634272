import { Box, Button, Typography } from "@mui/material";
import React from "react";
import pagenotfound from "../../assets/images/404Text.png";
import { useNavigate } from "react-router-dom";

const Pagenotfound = () => {
  const navigate = useNavigate();
  return (
    <>
      <Box className="page-not-found">
        <Box className="page-inner">
          <img src={pagenotfound} alt="logo-main" loading="lazy" />
          <Typography variant="h5" component="h5">
            Oops, it appears that we're unable to locate the page you're
            searching for.
          </Typography>
          <Typography variant="body1" component="p">
            The page you're seeking could have been deleted, undergone a name
            change, or might be temporarily inaccessible.
          </Typography>

          <Button
            variant="contained"
            size="large"
            className="primary-btn btn add-btn"
            disableRipple
            onClick={() => navigate(`/`)}
          >
            Back to Home
          </Button>
        </Box>
      </Box>
    </>
  );
};

export default Pagenotfound;
