import { Box, Button, Grid, TextField, Typography } from "@mui/material";
import React, { useEffect } from "react";
import { useState } from "react";
import logo from "../../assets/images//cs-logo-white.svg";
import PatternBig from "../../assets/images/Big-Ellipse.png";
import PatternSmall from "../../assets/images/Small-Ellipse.png";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import { resetPassword } from "../../store/slices/resetPasswordSlice";
import { verifyForgotPasswordToken } from "../../store/slices/verifyForgetTokenSlice";
import { successToast } from "../../responses/successToast";
import { passwordformat } from "../../utils/constant";
import { displayErrorMessage1 } from "../../utils/constant";
import { commonerror } from "../../utils/errormessage";
import "./auth.scss";

const ResetPassword = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [erromessage1, setErrorMessage1] = useState("");
  const [erromessage2, setErrorMessage2] = useState("");
  const [erromessage3, setErrorMessage3] = useState("");
  const [params] = useSearchParams();
  const token = params.get("token");
  const passwordRegex = passwordformat();

  useEffect(() => {
    const verifyToken = async () => {
      try {
        const verifyResult = await dispatch(verifyForgotPasswordToken(token));
        if (verifyResult.success) {
        } else {
        }
      } catch (error) {
      }
    };
    verifyToken();
  }, [dispatch, token]);

  const submitHandler = async (event) => {
    event.preventDefault();

    let flag=0
    if (!newPassword) {
      displayErrorMessage1("Please enter new password", setErrorMessage2);
      flag=1
    }

    if (!confirmPassword) {
      displayErrorMessage1("Please re-enter new password", setErrorMessage3);
      flag=1
    }

    if (confirmPassword && newPassword && !passwordRegex.test(newPassword)) {
      displayErrorMessage1(
        "Password must be 8+ characters with uppercase, lowercase, digit, and special character",
        setErrorMessage1
      );
      flag=1
    }

    if (newPassword && confirmPassword && newPassword !== confirmPassword) {
      displayErrorMessage1(
        "New and confirm password must be same",
        setErrorMessage1
      );
      flag=1
    }

    if(flag!==0){
      return
    }

    try {
      const credentials = {
        password: newPassword,
        repassword: confirmPassword,
      };
      const resetResult = await dispatch(resetPassword(credentials, token));
      if (resetResult.success) {
        successToast(resetResult.message);
        navigate("/signin");
      } else {
      }
    } catch (error) {
      return;
    }
  };

  const handleKeyPress = (event) => {
    if (event.key === "Enter") {
      submitHandler(event);
    }
  };

  return (
    <>
      <Box variant="div" component="div" className="main-wrap ">
        <Box variant="div" component="div" className="logo-wrap">
          <img src={logo} alt="logo-main" loading="lazy" />
        </Box>

        <Box variant="div" component="div" className="form-wrap p-24">
          <Box variant="div" component="div" className="title">
            <Typography variant="h3" component="h3">
              Set New Password
            </Typography>
            <Typography variant="body1" component="p">
              New Password must not match the previous ones.
            </Typography>
          </Box>

          <Grid container spacing={2} mt={1}>
            <Grid item lg={12} md={12} sm={12} xs={12}>
              <Box variant="div" component="div">
                <label>
                  New Password
                  <Typography
                    variant="body1"
                    component="span"
                    className="asterisk"
                  >
                    *
                  </Typography>
                </label>
                <TextField
                  fullWidth
                  id="outlined-basic"
                  label=""
                  variant="outlined"
                  placeholder="Enter Password"
                  size="small"
                  type="password"
                  value={newPassword}
                  onChange={(e) => {
                    setNewPassword(e.target.value);
                    if (e.target.value) {
                      setErrorMessage2("");
                      setErrorMessage1("");
                    }
                  }}
                  onKeyPress={handleKeyPress}
                />
                {erromessage2 && (
                  <Typography variant="body1" component="p" className="error">
                    {erromessage2}
                  </Typography>
                )}
              </Box>
            </Grid>
            <Grid item lg={12} md={12} sm={12} xs={12} mt={1}>
              <Box variant="div" component="div">
                <label>
                  Confirm Password
                  <Typography
                    variant="body1"
                    component="span"
                    className="asterisk"
                  >
                    *
                  </Typography>
                </label>
                <TextField
                  fullWidth
                  id="outlined-basic"
                  label=""
                  variant="outlined"
                  placeholder="Enter Password"
                  size="small"
                  type="password"
                  value={confirmPassword}
                  onChange={(e) => {
                    setConfirmPassword(e.target.value);
                    if (e.target.value) {
                      setErrorMessage3("");
                      setErrorMessage1("");
                    }
                  }}
                  onKeyPress={handleKeyPress}
                />
                {erromessage1 && (
                  <Typography variant="body1" component="p" className="error">
                    {erromessage1}
                  </Typography>
                )}
                {erromessage3 && (
                  <Typography variant="body1" component="p" className="error">
                    {erromessage3}
                  </Typography>
                )}
              </Box>
            </Grid>
          </Grid>
          <Button
            variant="contained"
            size="large"
            className="primary-btn btn"
            disableRipple
            type="submit"
            onClick={submitHandler}
          >
            {" "}
            Reset Password
          </Button>
        </Box>

        <Box variant="div" component="div" className="pattern-big">
          <img src={PatternBig} alt="pattern" loading="lazy" />
        </Box>
        <Box variant="div" component="div" className="pattern-small">
          <img src={PatternSmall} alt="pattern" loading="lazy" />
        </Box>
      </Box>
    </>
  );
};

export default ResetPassword;
