import { createSlice } from "@reduxjs/toolkit";
import { setFeedback } from "./feedbackSlice";
import axios from "axios";
axios.defaults.withCredentials = true;

const initialState = {
  isLoading: false,
  success: false,
  error: null,
};

const changePasswordByAdminSlice = createSlice({
  name: "changePasswordByAdmin",
  initialState,
  reducers: {
    changePasswordByAdminStart: (state) => {
      state.isLoading = true;
      state.success = false;
      state.error = null;
    },
    changePasswordByAdminSuccess: (state) => {
      state.isLoading = false;
      state.success = true;
      state.error = null;
    },
    changePasswordByAdminFailure: (state, action) => {
      state.isLoading = false;
      state.success = false;
      state.error = action.payload;
    },
  },
});

export const {
  changePasswordByAdminStart,
  changePasswordByAdminSuccess,
  changePasswordByAdminFailure,
} = changePasswordByAdminSlice.actions;

export default changePasswordByAdminSlice.reducer;

export const changePasswordByAdmin =
  (userId, credentials, token) => async (dispatch) => {
    try {
      dispatch(changePasswordByAdminStart());

      const response = await axios.patch(
        `${process.env.REACT_APP_API_URL}/users/${userId}/change-password`,
        credentials,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );

      const data = response.data;
      dispatch(changePasswordByAdminSuccess());
      return { success: true, message: data.message };
    } catch (error) {
      dispatch(changePasswordByAdminFailure(error.message));
      dispatch(
        setFeedback({
          status: "fail",
          message: error.response.data.message,
          code: error.response.status,
        })
      );
      return { success: false, error: error.message };
    }
  };
