import { Box, Button, Grid, TextField, Typography } from "@mui/material";
import React from "react";
import { useState } from "react";
import logo from "../../assets/images/cs-logo-white.svg";
import PatternBig from "../../assets/images/Big-Ellipse.png";
import PatternSmall from "../../assets/images/Small-Ellipse.png";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { successToast } from "../../responses/successToast";
import { forgetPassword } from "../../store/slices/forgetPasswordSlice";
import { emailformat } from "../../utils/constant";
import { displayErrorMessage } from "../../utils/constant";
import { commonerror } from "../../utils/errormessage";
import { emailinvaliderror } from "../../utils/errormessage";
import "./auth.scss";

const ForgetPassword = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [email, setEmail] = useState("");
  const [erromessage, setErrorMessage] = useState("");
  const emailRegex = emailformat();

  const submitHandler = async (event) => {
    event.preventDefault();

    if (!email) {
      displayErrorMessage("Please enter your email", setErrorMessage);
      return;
    }

    if (email && !emailRegex.test(email)) {
      displayErrorMessage(emailinvaliderror, setErrorMessage);
      return;
    }

    try {
      const forgetPasswordResult = await dispatch(forgetPassword(email));
      if (forgetPasswordResult.success) {
        successToast(forgetPasswordResult.message);
        navigate("/emailsuccess");
      } else {
      }
    } catch (error) {
      return;
    } finally {
      setEmail("");
    }
  };

  const handleKeyPress = (event) => {
    if (event.key === "Enter") {
      submitHandler(event);
    }
  };

  return (
    <>
      <Box variant="div" component="div" className="main-wrap ">
        <Box variant="div" component="div" className="logo-wrap">
          <img src={logo} alt="logo-main" loading="lazy" />
        </Box>

        <Box variant="div" component="div" className="form-wrap p-24">
          <Box variant="div" component="div" className="title">
            <Typography variant="h3" component="h3">
              Forgot Password?
              <Typography variant="body1" component="p" sx={{margin:"0"}}>
                No worries, we'll send you the reset instructions
              </Typography>
            </Typography>
          </Box>

          <Grid container spacing={2}>
            <Grid item lg={12} md={12} sm={12} xs={12}>
              <Box variant="div" component="div">
                <label>
                  Email Id
                  <Typography
                    variant="body1"
                    component="span"
                    className="asterisk"
                  >
                    *
                  </Typography>
                </label>
                <TextField
                  fullWidth
                  id="outlined-basic"
                  label=""
                  variant="outlined"
                  placeholder="Enter Email"
                  size="small"
                  value={email}
                  onChange={(e) => {
                    setEmail(e.target.value);
                    if (e.target.value) {
                      setErrorMessage("");
                    }
                    if (!emailRegex.test(email)) {
                      setErrorMessage("");
                    }
                  }}
                  onKeyPress={handleKeyPress}
                />
              </Box>
            </Grid>
            {erromessage && (
              <Typography variant="body1" component="p" className="error-msg">
                {erromessage}
              </Typography>
            )}
          </Grid>
          <Button
            variant="contained"
            size="large"
            className="primary-btn btn"
            disableRipple
            type="submit"
            onClick={submitHandler}
          >
            {" "}
            Reset Password
          </Button>
          <Box variant="div" component="div" className="link-btn-wrap">
            <Button className="link-btn" disableRipple>
              <Link to="/signin">Back to Log in</Link>
            </Button>
          </Box>
        </Box>

        <Box variant="div" component="div" className="pattern-big">
          <img src={PatternBig} alt="pattern" loading="lazy" />
        </Box>
        <Box variant="div" component="div" className="pattern-small">
          <img src={PatternSmall} alt="pattern" loading="lazy" />
        </Box>
      </Box>
    </>
  );
};

export default ForgetPassword;
