import Cookies from "js-cookie";
const COOKIE_NAME = process.env.REACT_APP_COOKIE_NAME;

const getTokenFromLocalStorage = () => {
  // const userData = JSON.parse(localStorage.getItem("token"));
  const UserToken = Cookies.get(COOKIE_NAME);
  //const token = userData?.token;
  return UserToken;
};

export default getTokenFromLocalStorage;
