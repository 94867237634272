import {
  Box,
  Fade,
  Grid,
  Modal,
  Paper,
  Typography,
  Skeleton,
} from "@mui/material";
import React, { useState, useEffect } from "react";
import Button from "@mui/material/Button";
import {
  RiCameraFill,
  RiArrowLeftLine,
  RiUploadCloud2Line,
} from "react-icons/ri";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import styled from "@emotion/styled";
import { useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useDropzone } from "react-dropzone";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { fetchViews } from "../../../store/slices/viewSlice";
import axios from "axios";
import getTokenFromLocalStorage from "../../../utils/token";
import FormData from "form-data";
import "./application.scss";
import { successToast } from "../../../responses/successToast";
import { errorToast } from "../../../responses/errorToast";
import { setFeedback } from "../../../store/slices/feedbackSlice";

axios.defaults.withCredentials = true;
const Applicationview = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [open, setOpen] = useState(false);
  const [value, setValue] = useState(0);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const { id: viewId } = useParams();
  const views = useSelector((state) => state.views);
  const [loading, setLoading] = useState(true);
  const [errormessage, setErrorMessage] = useState("");

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const formatPermissions = (str) => {
    return str
      .split(",")
      .map((permission) => {
        const formattedPermission = permission
          .split("_")
          .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
          .join(" & ");
        return formattedPermission;
      })
      .join(", ");
  };

  const [selectedFile, setSelectedFile] = useState(null);

  const { getRootProps, getInputProps } = useDropzone({
    accept: {
      "image/png": [".png"],
      "image/jpeg": [".jpeg"],
      "image/svg": [".svg"],
    },
    maxFiles: 1,
    maxSize: 5 * 1024 * 1024,
    onDrop: (acceptedFiles, fileRejections) => {
      if (fileRejections.length > 0) {
        const rejectedFile = fileRejections[0];

        if (rejectedFile.errors.some((err) => err.code === "file-too-large")) {
          setErrorMessage(
            "Image size greater than 5MB, Could not upload, please try again"
          );
        } else if (rejectedFile.file) {
          const acceptedFormats = ["image/png", "image/jpeg", "image/svg+xml"];
          const fileFormat = rejectedFile.file.type;

          if (!acceptedFormats.includes(fileFormat)) {
            setErrorMessage(
              "Unsupported image format, Could not upload, please try again"
            );
          }
        }
      } else if (acceptedFiles.length > 0) {
        setSelectedFile(acceptedFiles[0]);
        setErrorMessage("");
      }
    },
  });

  const handleUploadLogo = async () => {
    if (selectedFile && selectedFile.size > 5 * 1024 * 1024) {
      return;
    }

    if (!selectedFile) {
      setErrorMessage("Please select any file");
    } else {
      setErrorMessage("");
    }
    if (!errormessage && selectedFile) {
      try {
        const token = getTokenFromLocalStorage();
        const formData = new FormData();
        formData.append("logo_url", selectedFile);

        const response = await axios.patch(
          `${process.env.REACT_APP_API_URL}/applications/${viewId}/addapplogo`,
          formData,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        handleClose();
        successToast("Image uploaded successfully");
        dispatch(fetchViews(viewId));
        setSelectedFile(null);
        setErrorMessage("");
      } catch (error) {
        if (error.response) {
          dispatch(
            setFeedback({
              status: "fail",
              message: error.response.data.message,
              code: error.response.status,
            })
          );
        } else {
        }
        console.error("Error while uploading logo:", error);
      }
    }
  };

  const cancelHandler = () => {
    handleClose();
    setErrorMessage("");
  };
  const { ref, ...rootProps } = getRootProps();

  function CustomTabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}
      >
        {value === index && (
          <Box>
            <Typography>{children}</Typography>
          </Box>
        )}
      </div>
    );
  }
  function a11yProps(index) {
    return {
      id: `simple-tab-${index}`,
      "aria-controls": `simple-tabpanel-${index}`,
    };
  }

  const tabLabel = (role, modules) => {
    return (
      <Box>
        <Typography variant="body1" component="span" className="role-name">
          {role}
        </Typography>
        <Typography variant="body1" component="span" className="module-count">
          {modules}
        </Typography>
      </Box>
    );
  };

  useEffect(() => {
    setLoading(true);
    dispatch(fetchViews(viewId)).then(() => {
      setLoading(false);
    });
  }, [dispatch, viewId]);

  return (
    <>
      <Box className="content-header">
        <Typography variant="h2" component="h2" className="title-with-btn">
          <Button
            variant="outlined"
            className="back-btn"
            startIcon={<RiArrowLeftLine />}
            disableRipple
            onClick={() => navigate("/applications")}
          ></Button>
          {views.app_name}
        </Typography>
      </Box>
      <Box className="content-layout">
        <Box variant="div" component="div" className="appdetail-wrap p-24">
          <Box variant="div" component="div" className="app-info">
            <Grid container spacing={3}>
              <Grid item xs={12} sm={12} md={6} lg={6}>
                <Typography variant="h3" component="h3">
                  Application Information
                </Typography>
                <Box variant="div" component="div">
                  <Typography
                    variant="body1"
                    component="span"
                    className="label"
                  >
                    Application Name
                  </Typography>

                  {loading ? (
                    <Skeleton variant="text" width={100} height={30} />
                  ) : (
                    <Typography variant="body1" component="p" className="value">
                      {views.app_name}
                    </Typography>
                  )}
                </Box>
              </Grid>
              <Grid item xs={12} sm={12} md={6} lg={6}>
                <Box variant="div" component="div" className="app-logo">
                  <Typography variant="h3" component="h3">
                    Application Logo
                  </Typography>
                  <Box variant="div" component="div">
                    <Typography
                      variant="body1"
                      component="span"
                      className="label"
                    >
                      Logo
                    </Typography>
                    <Box
                      variant="div"
                      component="div"
                      className="logo-image-wrap"
                    >
                      <Box
                        variant="div"
                        component="div"
                        className="logo-image"
                      >
                        {loading ? (
                          <Skeleton
                            variant="rectangular"
                            width="100%"
                            height={130}
                          />
                        ) : (
                          <img
                            src={views?.img_path}
                            alt="app-logo"
                            loading="lazy"
                            style={{
                              objectFit: "contain",
                              width: "100%",
                              height: "100%",
                            }}
                          />
                        )}
                      </Box>

                      <Button
                        variant="outlined"
                        className="action-btn upld-btn"
                        startIcon={<RiCameraFill />}
                        disableRipple
                        onClick={handleOpen}
                      ></Button>
                    </Box>
                  </Box>
                </Box>
              </Grid>
            </Grid>
          </Box>
        </Box>
        <Box variant="div" component="div" className="appdetail-wrap">
          <Box variant="div" component="div" className="role-info">
            <Typography variant="h3" component="h3" className="role-title">
              Roles{" "}
              <Typography variant="body1" component="span">
                ({views.totalRoles})
              </Typography>
            </Typography>
            <Grid container spacing={2}>
              <Grid item lg={12} md={12} sm={12} xs={12}>
                <Box variant="div" component="div" className="tab-wrapper">
                  <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                    <Tabs
                      value={value}
                      onChange={handleChange}
                      aria-label="basic tabs example"
                      className="custom-auto"
                    >
                      {loading ? (
                        <Skeleton variant="text" width="100%" height={30} />
                      ) : views.roles && views.roles.length > 0 ? (
                        views.roles.map((view, index) => (
                          <Tab
                            key={index}
                            label={tabLabel(view.role_name, view.user_count)}
                            {...a11yProps(index)}
                          />
                        ))
                      ) : null}
                    </Tabs>
                  </Box>

                  {loading
                    ? Array.from({ length: 4 }).map((_, index) => (
                        <Skeleton variant="text" width="100%" height={40} />
                      ))
                    : views.roles && views.roles.length > 0
                    ? views.roles.map((role, index) => (
                        <CustomTabPanel
                          value={value}
                          index={index}
                          key={role.id}
                          className="12 role-list-wrapper-scroll"
                        >
                          <Table
                            sx={{ minWidth: 380 }}
                            aria-label="table"
                            className="listing-table role-list-wrapper"
                          >
                            <TableHead>
                              <TableRow className="table-head">
                                <TableCell></TableCell>
                                <TableCell align="left">Module Name</TableCell>
                                <TableCell align="left">Permissions</TableCell>
                              </TableRow>
                            </TableHead>
                            <TableBody>
                              {loading
                                ? Array.from({ length: 4 }).map((_, index) => (
                                    <TableRow>
                                      <TableCell colSpan={2}>
                                        <Skeleton
                                          variant="text"
                                          width="100%"
                                          height={40}
                                        />
                                      </TableCell>
                                      <TableCell colSpan={2}>
                                        <Skeleton
                                          variant="text"
                                          width="40%"
                                          height={40}
                                        />
                                      </TableCell>
                                    </TableRow>
                                  ))
                                : role.modules_permissions.map(
                                    (modulePermission, permissionIndex) => (
                                      <TableRow
                                        key={modulePermission.module_id}
                                        sx={{
                                          "&:last-child td, &:last-child th": {
                                            border: 0,
                                          },
                                        }}
                                      >
                                        <TableCell component="th" scope="row">
                                          {permissionIndex + 1}
                                        </TableCell>
                                        <TableCell align="left">
                                          <Typography
                                            variant="body1"
                                            component="p"
                                          >
                                            {modulePermission.module_name}
                                          </Typography>
                                        </TableCell>
                                        <TableCell align="left">
                                          <Typography
                                            variant="body1"
                                            component="p"
                                          >
                                            {formatPermissions(
                                              modulePermission.permissions
                                            )}
                                          </Typography>
                                        </TableCell>
                                      </TableRow>
                                    )
                                  )}
                            </TableBody>
                          </Table>
                        </CustomTabPanel>
                      ))
                    : null}
                </Box>
              </Grid>
            </Grid>
          </Box>
        </Box>
      </Box>

      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={open}
        onClose={handleClose}
        closeAfterTransition
      >
        <Fade in={open}>
          <Box sx={styled} className="modal-wrapper modal-ui">
            <Box class="modal-body">
              <Box variant="div" component="div" className="title">
                <Typography variant="h3" component="h3">
                  Upload Application Logo
                </Typography>
                <Typography variant="body1" component="p">
                  You can manage application logo from here.
                </Typography>
              </Box>
              <Box variant="div" component="div" className="body">
                <Box variant="div" component="div" className="drop-zone">
                  <Paper className="drop-zone-inner" {...rootProps}>
                    <input {...getInputProps()} />

                    <Box className="msg-wrap">
                      <RiUploadCloud2Line />
                      <Typography variant="h3" component="h3">
                        Drag & drop file or Browse
                      </Typography>
                      <Typography variant="body1" component="p" class="sub-msg">
                        <Typography variant="body1" component="span">
                          Supported Formats:
                        </Typography>
                        SVG, JPG or PNG
                      </Typography>
                      <Typography variant="body1" component="p" class="sub-msg">
                        <Typography variant="body1" component="span">
                          File Size:
                        </Typography>
                        not more than 5MB
                      </Typography>
                      <Typography variant="body1" component="p" class="sub-msg">
                        <Typography variant="body1" component="span">
                          Image Dimensions:
                        </Typography>
                        min 150px x 60px
                      </Typography>
                    </Box>
                  </Paper>
                </Box>
              </Box>
              {errormessage && <div className="error">{errormessage}</div>}
              <Box variant="div" component="div" className="footer">
                <Box variant="div" component="div" className="btn-group">
                  <Button variant="contained" size="large" className="primary-btn btn" disableRipple onClick={handleUploadLogo}> Upload Logo </Button>
                  <Button variant="contained" size="large" className="secondary-btn btn" disableRipple onClick={cancelHandler}> Cancel </Button>
                </Box>
              </Box>
            </Box>
          </Box>
        </Fade>
      </Modal>
    </>
  );
};

export default Applicationview;
