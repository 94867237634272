import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import getTokenFromLocalStorage from "../../utils/token";
import { setFeedback } from "./feedbackSlice";
import axios from "axios";

export const fetchApplicationPermissions = createAsyncThunk(
  "applications/fetchApplicationsPermissions",
  async (_, { rejectWithValue, dispatch }) => {
    try {
      const token = getTokenFromLocalStorage();

      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/users/applications`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );
      if (response.status) {
        return response.data;
      } else {
        return rejectWithValue(response.data.message);
      }
    } catch (error) {
      dispatch(
        setFeedback({
          status: "fail",
          message: error.response.data.message,
          code: error.response.status,
        })
      );
      return rejectWithValue(error.response.data);
    }
  }
);
const initialState = {
  status: "idle",
  error: null,
  applicationPermissions: {},
};
const applicationPermissionSlice = createSlice({
  name: "applicationPermissions",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchApplicationPermissions.fulfilled, (state, action) => {
      state.applicationPermissions = action.payload;
    });
  },
});

export default applicationPermissionSlice.reducer;
