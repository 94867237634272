import { createSlice } from "@reduxjs/toolkit";
import { setFeedback } from "./feedbackSlice";
import axios from "axios";
axios.defaults.withCredentials = true;

const initialState = {
  user: null,
  loading: false,
  error: null,
};

const editUserByAdminSlice = createSlice({
  name: "editUserByAdmin",
  initialState,
  reducers: {
    editUserByAdminSliceStart(state) {
      state.loading = true;
      state.error = null;
    },
    editUserByAdminSliceSuccess(state, action) {
      state.loading = false;
      state.user = action.payload;
    },
    editUserByAdminSliceFailure(state, action) {
      state.loading = false;
      state.error = action.payload;
    },
  },
});

export const {
  editUserByAdminSliceStart,
  editUserByAdminSliceSuccess,
  editUserByAdminSliceFailure,
} = editUserByAdminSlice.actions;
export default editUserByAdminSlice.reducer;

export const editUserByIdAdmin =
  (userId, token, editData) => async (dispatch) => {
    try {
      dispatch(editUserByAdminSliceStart());
      const response = await axios.patch(
        `${process.env.REACT_APP_API_URL}/users/${userId}`,
        editData,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );
      const user = response;
      const data = response.data;
      dispatch(editUserByAdminSliceSuccess(user));
      return { success: true, message: data.message };
    } catch (error) {
      dispatch(editUserByAdminSliceFailure(error.message));
      dispatch(
        setFeedback({
          status: "fail",
          message: error.response.data.message,
          code: error.response.status,
        })
      );
      return { success: false, error: error.response.data.message };
    }
  };
