import { createSlice } from "@reduxjs/toolkit";
import { setFeedback } from "./feedbackSlice";
import axios from "axios";
axios.defaults.withCredentials = true;

const initialState = {
  isLoading: false,
  success: false,
  error: null,
};

const createUserSlice = createSlice({
  name: "createUser",
  initialState,
  reducers: {
    createUserStart: (state) => {
      state.isLoading = true;
      state.success = false;
      state.error = null;
    },
    createUserSuccess: (state) => {
      state.isLoading = false;
      state.success = true;
      state.error = null;
    },
    createUserFailure: (state, action) => {
      state.isLoading = false;
      state.success = false;
      state.error = action.payload;
    },
  },
});

export const { createUserStart, createUserSuccess, createUserFailure } =
  createUserSlice.actions;

export default createUserSlice.reducer;

export const createUser = (userData, token) => async (dispatch) => {
  try {
    dispatch(createUserStart());

    const response = await axios.post(
      `${process.env.REACT_APP_API_URL}/users`,
      userData,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      }
    );

    const data = response.data;
    dispatch(createUserSuccess());
    return { success: true, message: data.message };
  } catch (error) {
    dispatch(createUserFailure(error.message));
    dispatch(
      setFeedback({
        status: "fail",
        message: error.response.data.message,
        code: error.response.status,
      })
    );
    return { success: false, error: error.response.data.message };
  }
};
