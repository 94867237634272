import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import getTokenFromLocalStorage from "../../utils/token";
import { setFeedback } from "./feedbackSlice";
import { successToast } from "../../responses/successToast";
import axios from "axios";
axios.defaults.withCredentials = true;

const initialState = {
  application: null,
  roles: [],
  appId: null,
  status: "idle",
  error: null,
};

export const fetchRoles = createAsyncThunk(
  "roles/fetchRoles",
  async (applicationId, { rejectWithValue, dispatch }) => {
    try {
      const token = getTokenFromLocalStorage();
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/applications/${applicationId}/roles?fields=id,role_name,app_id&appfields=id,app_name`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );
      if (response.data.status) {
        return response.data.data;
      } else {
        return rejectWithValue(response.data.message);
      }
    } catch (error) {
      dispatch(
        setFeedback({
          status: "fail",
          message: error.response.data.message,
          code: error.response.status,
        })
      );
      return rejectWithValue(error.response.data);
    }
  }
);

export const DeleteRoles = createAsyncThunk(
  "roles/DeleteRoles",
  async (roleId, { rejectWithValue, dispatch }) => {
    try {
      const token = getTokenFromLocalStorage();
      const response = await axios.delete(
        `${process.env.REACT_APP_API_URL}/roles/${roleId}`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );
      if (response.data.status) {
        successToast("Role Deleted successfully"); // Call the API to delete the role
        return response.data;
      } else {
        return rejectWithValue(response.data.message);
      }
    } catch (error) {
      dispatch(
        setFeedback({
          status: "fail",
          message: error.response.data.message,
          code: error.response.status,
        })
      );
      return rejectWithValue(error.response.data);
    }
  }
);

const roleSlice = createSlice({
  name: "roles",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchRoles.pending, (state) => {
        state.status = "loading";
      })
      .addCase(fetchRoles.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.application = action.payload.application;
        state.roles = action.payload.roles;
        state.appId = action.payload.application?.id ?? null;
      })
      .addCase(fetchRoles.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.payload;
      })
      .addCase(DeleteRoles.fulfilled, (state, action) => {
        const deletedRoleId = action.meta.arg;
        state.roles = state.roles.filter((role) => role.id !== deletedRoleId);
      });
  },
});

export default roleSlice.reducer;
