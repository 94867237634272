import { createSlice } from "@reduxjs/toolkit";
import { setFeedback } from "./feedbackSlice";
// import Cookies from "js-cookie";
import axios from "axios";
axios.defaults.withCredentials = true;

const initialState = {
  user: null,
  isLoading: false,
  error: null,
};

const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    loginStart: (state) => {
      state.isLoading = true;
      state.error = null;
    },
    loginSuccess: (state, action) => {
      state.isLoading = false;
      state.user = action.payload;
      state.error = null;
    },
    loginFailure: (state, action) => {
      state.isLoading = false;
      state.error = action.payload;
    },
  },
});

export const { loginStart, loginSuccess, loginFailure } = authSlice.actions;

export default authSlice.reducer;

//API CALL
export const login = (credentials) => async (dispatch) => {
  try {
    dispatch(loginStart());

    const response = await axios.post(
      `${process.env.REACT_APP_API_URL}/users/login`,
      credentials,
      {
        headers: {
          "Content-Type": "application/json",
        },
      }
    );
    const data = response.data;
    dispatch(loginSuccess(data));
    // localStorage.setItem("token", JSON.stringify(data.token));

    // const reqFields = {
    //   // userId: data.data.id,
    //   fname: data?.data?.first_name,
    //   lname: data?.data?.last_name,
    //   imgUrl: data?.data?.img_path,
    // };
    // localStorage.setItem("userData", JSON.stringify(reqFields));
    // // localStorage.setItem("userId", JSON.stringify(data?.data?.id));
    // window.dispatchEvent(new Event("storage"));
    return { success: true, message: data.message };
  } catch (error) {
    dispatch(loginFailure(error.response.data.message));
    dispatch(
      setFeedback({
        status: "fail",
        message: error.response.data.message,
        code: error.response.status,
      })
    );
    return { success: false, error: error.response.data.message };
  }
};
