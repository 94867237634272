import {
  Box,
  Checkbox,
  FormControl,
  FormControlLabel,
  Grid,
  MenuItem,
  RadioGroup,
  Select,
  TextField,
  Typography,
  FormHelperText,
  Divider,
} from "@mui/material";
import React, { useState, useEffect, useLayoutEffect } from "react";
import Button from "@mui/material/Button";
import {
  RiArrowLeftLine,
  RiArrowDownSLine,
  RiContactsBookLine,
} from "react-icons/ri";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import getTokenFromLocalStorage from "../../utils/token";
import { createUser } from "../../store/slices/createUserSlice";
import { fetchDesignations } from "../../store/slices/designationSlice";
import Radio from "@mui/material/Radio";
import axios from "axios";
import { successToast } from "../../responses/successToast";
import { emailformat } from "../../utils/constant";
import { displayErrorMessage1 } from "../../utils/constant";
import { emailerror } from "../../utils/errormessage";
import {
  emailinvaliderror,
  mobileinvaliderror,
} from "../../utils/errormessage";
import { commonerror } from "../../utils/errormessage";
import "./user.scss";
import Skeleton from "@mui/material/Skeleton";
axios.defaults.withCredentials = true;
const DESIGNATIONS = [
  [
    "Software Development",
    [
      { id: 1, designation_name: "Software Engineer - Trainee" },
      { id: 2, designation_name: "Software Engineer" },
      { id: 3, designation_name: "Software Engineer - Senior" },
      { id: 4, designation_name: "Software Engineer - Lead" },
    ],
  ],
  [
    "UI/UX Design",
    [
      { id: 5, designation_name: "UI/UX Designer - Trainee" },
      { id: 6, designation_name: "UI/UX Designer" },
      { id: 7, designation_name: "UI/UX Designer - Senior" },
      { id: 8, designation_name: "UI/UX Designer - Lead" },
    ],
  ],
  [
    "Quality Assurance (QA)",
    [
      { id: 9, designation_name: "QA Engineer - Trainee" },
      { id: 10, designation_name: "QA Engineer" },
      { id: 11, designation_name: "QA Engineer - Senior" },
      { id: 12, designation_name: "QA Engineer - Lead" },
    ],
  ],
  [
    "DevOps",
    [
      { id: 13, designation_name: "DevOps Engineer - Trainee" },
      { id: 14, designation_name: "DevOps Engineer" },
      { id: 15, designation_name: "DevOps Engineer - Senior" },
      { id: 16, designation_name: "DevOps Engineer - Lead" },
    ],
  ],
  [
    "Team Leadership",
    [
      { id: 17, designation_name: "Team Lead - Potential" },
      { id: 18, designation_name: "Team Lead" },
      { id: 19, designation_name: "Team Lead - Senior" },
    ],
  ],
  ["Management", [{ id: 20, designation_name: "Delivery Manager" }]],
  [
    "Digital Marketing",
    [
      { id: 21, designation_name: "Digital Marketing" },
      { id: 22, designation_name: "Digital Marketing - Senior" },
      { id: 23, designation_name: "Digital Marketing - Lead" },
    ],
  ],
  [
    "Content Creation",
    [
      { id: 24, designation_name: "Content Writer" },
      { id: 25, designation_name: "Content Writer - Senior" },
    ],
  ],
  [
    "Human Resources (HR)",
    [
      { id: 26, designation_name: "HR Executive" },
      { id: 27, designation_name: "HR Executive - Senior" },
      { id: 28, designation_name: "HR Manager" },
    ],
  ],
  [
    "Business Development (BD)",
    [
      { id: 29, designation_name: "BD Executive" },
      { id: 30, designation_name: "BD Executive - Senior" },
      { id: 31, designation_name: "BD Manager" },
      { id: 32, designation_name: "BD Manager - Senior" },
    ],
  ],
  [
    "Administration",
    [
      { id: 33, designation_name: "Admin Executive" },
      { id: 34, designation_name: "Admin Manager" },
    ],
  ],
  [
    "Accounting",
    [
      { id: 35, designation_name: "Account Executive" },
      { id: 36, designation_name: "Account Manager" },
    ],
  ],
  ["Executive Leadership", [{ id: 37, designation_name: "CEO" }]],
  ["Financial Leadership", [{ id: 38, designation_name: "CFO" }]],
];

const Usercreate = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const designations = useSelector((state) => state.designation.designations);
  const token = getTokenFromLocalStorage();
  const [userType, setUserType] = useState("internal");
  const [firstname, setFirstname] = useState("");
  const [lastname, setLastname] = useState("");
  const [number, setNumber] = useState(null);
  const [email, setEmail] = useState("");
  const [selectedDesignation, setSelectedDesignation] = useState("");
  const [selectedCompany, setSelectedCompany] = useState("");

  const [appNames, setAppNames] = useState([]);
  const [internalrole, setInternalrole] = useState("user");
  const [selectedAppRoles, setSelectedAppRoles] = useState([]);
  const [erromessage1, setErrorMessage1] = useState("");
  const [erromessage2, setErrorMessage2] = useState("");
  const [erromessage3, setErrorMessage3] = useState("");
  const [erromessage4, setErrorMessage4] = useState("");
  const [erromessage5, setErrorMessage5] = useState("");
  const [erromessage15, setErrorMessage15] = useState("");

  const [erromessage7, setErrorMessage7] = useState("");
  const [erromessage17, setErrorMessage17] = useState("");

  const [erromessage8, setErrorMessage8] = useState("");
  const [erromessage9, setErrorMessage9] = useState("");
  const [erromessage10, setErrorMessage10] = useState("");
  const [loading, setLoading] = useState(true);
  const [checkedState, setCheckedState] = useState({});
  const [roleErrors, setRoleErrors] = useState({});
  const emailRegex = emailformat();
  const [status, setStatus] = useState("active");

  const handleChange = (appName, appId, event) => {
    const isChecked = event.target.checked;
    setCheckedState((prevState) => {
      const currentAppState = prevState[appName] || {
        isChecked: false,
        counter: 0,
      };

      const newCounter = isChecked
        ? currentAppState.counter + 1
        : currentAppState.counter;

      return {
        ...prevState,
        [appName]: { isChecked, counter: newCounter },
      };
    });

    if (isChecked) {
      setSelectedAppRoles((prevState) => {
        const existingIndex = prevState.findIndex(
          (item) => item.app_id === appId
        );

        if (existingIndex === -1) {
          return [...prevState];
        } else {
          const updatedRoles = [...prevState];
          const existingItem = updatedRoles.splice(existingIndex, 1)[0];
          updatedRoles.unshift(existingItem);

          return updatedRoles;
        }
      });
    } else {
      setSelectedAppRoles((prevState) => {
        let updatedState = [...prevState];
        let newState = updatedState.filter((item) => item.app_id !== appId);
        return newState;
      });
    }
    const existingIndex = selectedAppRoles.findIndex(
      (item) => item.app_id === appId
    );
    if (existingIndex === -1) {
      const updatedRoleErrors = {
        ...roleErrors,
        [appName]: event.target.checked,
      };

      setRoleErrors(updatedRoleErrors);
    }
  };

  const handleChangeForApp = (appName, event) => {
    const selectedRoleName = event.target.value;
    // console.log("event.target", event.target);
    // console.log(selectedRoleName, "selectedRoleName");
    const selectedApp = appNames.find((app) => app.app_name === appName);
    // console.log(selectedApp, "selectedApp");
    if (selectedApp) {
      const selectedRole = selectedApp.roles.find(
        (role) => role.id === selectedRoleName
      );
      if (selectedRole) {
        const selectedAppRole = {
          app_id: selectedApp.id,
          role_id: selectedRole.id,
        };

        const existingIndex = selectedAppRoles.findIndex(
          (item) => item.app_id === selectedAppRole.app_id
        );
        if (existingIndex !== -1) {
          setSelectedAppRoles((prevState) => {
            const newState = [...prevState];
            newState[existingIndex] = selectedAppRole;
            return newState;
          });
        } else {
          setSelectedAppRoles((prevState) => [...prevState, selectedAppRole]);
          const updatedRoleErrors = {
            ...roleErrors,
            [appName]: false,
          };
          setRoleErrors(updatedRoleErrors);
        }
      } else {
      }
    } else {
      console.log("Selected app not found!");
    }
  };

  const handleDesignationChange = (event) => {
    setSelectedDesignation(event.target.value);
    if (event.target.value) {
      setErrorMessage5("");
      setErrorMessage7("");
    }
  };

  const handleCompanyChange = (event) => {
    setSelectedCompany(event.target.value);
    if (event.target.value) {
      setErrorMessage15("");
      setErrorMessage17("");
    }
  };

  const handleRoleChange = (event) => {
    setInternalrole(event.target.value);
  };

  useLayoutEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_API_URL}/applications?fields=id,app_name`,
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${token}`,
            },
          }
        );
        const data = response.data.data;

        setAppNames(data);
        setLoading(false);
      } catch (error) {
        console.error("Error fetching data:", error);
        setLoading(false);
      }
    };
    fetchData();
  }, []);

  useEffect(() => {
    if (designations.length > 0) {
      return;
    }

    dispatch(fetchDesignations(token));
  }, [dispatch, token, designations]);

  function validateMobileNumber(mobileNumber) {
    const digitsOnly = mobileNumber?.replace(/\D/g, "");

    return /^\d{10}$/.test(digitsOnly);
  }
  const handleSubmit = async () => {
    let flag = 0;

    //   !firstname &&
    //   !lastname &&
    //   !number &&
    //   !email &&
    //   !selectedDesignation

    if (!firstname) {
      displayErrorMessage1("Please enter first name", setErrorMessage1);
      flag = 1;
    }

    if (!lastname) {
      displayErrorMessage1("Please enter last name", setErrorMessage2);
      flag = 1;
    }

    if (!number || !validateMobileNumber(number)) {
      displayErrorMessage1(mobileinvaliderror, setErrorMessage8);
      flag = 1;
    }

    if (!email) {
      displayErrorMessage1(emailerror, setErrorMessage4);
      flag = 1;
    }

    if (!emailRegex.test(email)) {
      displayErrorMessage1(emailinvaliderror, setErrorMessage4);
      flag = 1;
    }

    if (userType === "internal" && !selectedDesignation) {
      displayErrorMessage1("Please select designation", setErrorMessage5);
      flag = 1;
    }

    if (userType === "internal" && !selectedCompany) {
      displayErrorMessage1("Please select company", setErrorMessage15);
      flag = 1;
    }

    const hasRoleErrors = Object.values(roleErrors).some((error) => error);

    if (hasRoleErrors) {
      flag = 1;
    }

    if (flag !== 0) {
      return;
    }

    const userData = {
      first_name: firstname,
      last_name: lastname,
      company_id:
        userType === "internal" && selectedCompany === "Skillserve"
          ? 2
          : selectedCompany === "Infosystems"
          ? 1
          : null,
      contact_number: number,
      email: email,
      designation_id: userType === "internal" ? selectedDesignation : null,
      type: userType,
      role: internalrole,
      status: status,
      applicationDetails: selectedAppRoles,
    };

    dispatch(createUser(userData, token))
      .then((response) => {
        if (response.success) {
          successToast(response.message);
          navigate("/users");
        } else {
          console.error("Error creating user:", response.error);
        }
      })
      .catch((error) => {
        console.error("Error creating user:", error.message);
      });
  };
  return (
    <>
      <>
        <Box className="content-header">
          <Typography variant="h2" component="h2" className="title-with-btn">
            <Button
              variant="outlined"
              className="back-btn"
              startIcon={<RiArrowLeftLine />}
              disableRipple
              onClick={() => navigate("/users")}
            ></Button>
            Create User
          </Typography>
        </Box>
        <Box className="content-layout user-field-module-wrapper">
          <Box variant="div" component="div" className="createuser-wrap p-24">
            <Grid container spacing={4}>
              <Grid item xs={12} sm={12} md={12} lg={6}>
                <Box variant="div" component="div" className="user-detail">
                  <Typography variant="h3" component="h3">
                    User Details
                  </Typography>
                  <Grid container spacing={2}>
                    <Grid item xs={12} sm={12} md={12} lg={12}>
                      <Box variant="div" component="div">
                        <label>
                          Type{" "}
                          <Typography
                            variant="body1"
                            component="span"
                            className="asterisk"
                          >
                            *
                          </Typography>
                        </label>
                        <RadioGroup
                          row
                          name="row-radio-buttons-group"
                          defaultValue="internal"
                          value={userType}
                          onChange={(e) => {
                            setUserType(e.target.value);
                          }}
                        >
                          <FormControlLabel
                            value="internal"
                            label="Internal User"
                            control={<Radio disableRipple />}
                          />
                          <FormControlLabel
                            value="external"
                            label="External User"
                            control={<Radio disableRipple />}
                          />
                        </RadioGroup>
                      </Box>
                    </Grid>

                    {userType === "internal" && (
                      <Grid item xs={12} sm={12} md={12} lg={12}>
                        <Box variant="div" component="div">
                          <label>
                            Company{" "}
                            <Typography
                              variant="body1"
                              component="span"
                              className="asterisk"
                            >
                              *
                            </Typography>
                          </label>
                          {loading ? (
                            <Skeleton variant="rect" width="100%" height={40} />
                          ) : (
                            <FormControl fullWidth>
                              <Select
                                id="demo-simple-select"
                                value={selectedCompany}
                                onChange={handleCompanyChange}
                                size="small"
                                IconComponent={RiArrowDownSLine}

                                // defaultValue="user"
                              >
                                <MenuItem value={"Infosystems"}>
                                  Crest Infosystems Pvt. Ltd.
                                </MenuItem>
                                <MenuItem value={"Skillserve"}>
                                  Crest Skillserve Pvt. Ltd.
                                </MenuItem>
                              </Select>
                            </FormControl>
                          )}
                        </Box>
                        {erromessage15 && (
                          <Typography
                            variant="body1"
                            component="p"
                            className="error"
                          >
                            {erromessage15}
                          </Typography>
                        )}
                        {erromessage17 && (
                          <Typography
                            variant="body1"
                            component="p"
                            className="error"
                          >
                            {erromessage17}
                          </Typography>
                        )}
                      </Grid>
                    )}

                    <Grid item xs={12} sm={12} md={6} lg={6}>
                      <Box variant="div" component="div">
                        <label>
                          First Name{" "}
                          <Typography
                            variant="body1"
                            component="span"
                            className="asterisk"
                          >
                            *
                          </Typography>
                        </label>
                        {loading ? (
                          <Skeleton variant="rect" width="100%" height={40} />
                        ) : (
                          <TextField
                            fullWidth
                            id="outlined-basic"
                            label=""
                            variant="outlined"
                            size="small"
                            placeholder="Enter First Name"
                            onChange={(e) => {
                              setFirstname(e.target.value);
                              if (e.target.value) {
                                setErrorMessage7("");
                                setErrorMessage1("");
                              }
                              // if (!spaceRegex.test(e.target.value)) {
                              //   setErrorMessage9("");
                              // }
                            }}
                          />
                        )}
                      </Box>
                      {erromessage1 && (
                        <Typography
                          variant="body1"
                          component="p"
                          className="error"
                        >
                          {erromessage1}
                        </Typography>
                      )}
                      {erromessage7 && (
                        <Typography
                          variant="body1"
                          component="p"
                          className="error"
                        >
                          {erromessage7}
                        </Typography>
                      )}
                      {erromessage9 && (
                        <Typography
                          variant="body1"
                          component="p"
                          className="error"
                        >
                          {erromessage9}
                        </Typography>
                      )}
                    </Grid>
                    <Grid item xs={12} sm={12} md={6} lg={6}>
                      <Box variant="div" component="div">
                        <label>
                          Last Name{" "}
                          <Typography
                            variant="body1"
                            component="span"
                            className="asterisk"
                          >
                            *
                          </Typography>
                        </label>
                        {loading ? (
                          <Skeleton variant="rect" width="100%" height={40} />
                        ) : (
                          <TextField
                            fullWidth
                            id="outlined-basic"
                            label=""
                            variant="outlined"
                            size="small"
                            placeholder="Enter Last Name"
                            onChange={(e) => {
                              setLastname(e.target.value);
                              if (e.target.value) {
                                setErrorMessage7("");
                                setErrorMessage2("");
                              }
                              // if (!spaceRegex.test(e.target.value)) {
                              //   setErrorMessage10("");
                              // }
                            }}
                          />
                        )}
                      </Box>
                      {erromessage2 && (
                        <Typography
                          variant="body1"
                          component="p"
                          className="error"
                        >
                          {erromessage2}
                        </Typography>
                      )}
                      {erromessage10 && (
                        <Typography
                          variant="body1"
                          component="p"
                          className="error"
                        >
                          {erromessage10}
                        </Typography>
                      )}
                      {erromessage7 && (
                        <Typography
                          variant="body1"
                          component="p"
                          className="error"
                        >
                          {erromessage7}
                        </Typography>
                      )}
                    </Grid>

                    <Grid item xs={12} sm={12} md={6} lg={6}>
                      <Box variant="div" component="div">
                        <label>
                          Contact Number{" "}
                          <Typography
                            variant="body1"
                            component="span"
                            className="asterisk"
                          >
                            *
                          </Typography>
                        </label>
                        {loading ? (
                          <Skeleton variant="rect" width="100%" height={40} />
                        ) : (
                          <TextField
                            type="number"
                            fullWidth
                            id="outlined-basic"
                            label=""
                            variant="outlined"
                            size="small"
                            placeholder="Enter Contact Number"
                            onChange={(e) => {
                              setNumber(e.target.value);
                              if (validateMobileNumber(e.target.value)) {
                                setErrorMessage7("");
                                setErrorMessage8("");
                              }
                              if (e.target.value) {
                                setErrorMessage3("");
                              }
                            }}
                          />
                        )}
                        {erromessage8 && (
                          <Typography
                            variant="body1"
                            component="p"
                            className="error"
                          >
                            {erromessage8}
                          </Typography>
                        )}
                      </Box>
                      {erromessage3 && (
                        <Typography
                          variant="body1"
                          component="p"
                          className="error"
                        >
                          {erromessage3}
                        </Typography>
                      )}
                      {erromessage7 && (
                        <Typography
                          variant="body1"
                          component="p"
                          className="error"
                        >
                          {erromessage7}
                        </Typography>
                      )}
                    </Grid>
                    <Grid item xs={12} sm={12} md={6} lg={6}>
                      <Box variant="div" component="div">
                        <label>
                          Email
                          <Typography
                            variant="body1"
                            component="span"
                            className="asterisk"
                          >
                            *
                          </Typography>
                        </label>
                        {loading ? (
                          <Skeleton variant="rect" width="100%" height={40} />
                        ) : (
                          <TextField
                            fullWidth
                            id="outlined-basic"
                            label=""
                            variant="outlined"
                            size="small"
                            placeholder="Enter Email"
                            onChange={(e) => {
                              setEmail(e.target.value);
                              if (e.target.value) {
                                setErrorMessage7("");
                                setErrorMessage4("");
                              }
                            }}
                          />
                        )}
                      </Box>
                      {erromessage4 && (
                        <Typography
                          variant="body1"
                          component="p"
                          className="error"
                        >
                          {erromessage4}
                        </Typography>
                      )}
                      {erromessage7 && (
                        <Typography
                          variant="body1"
                          component="p"
                          className="error"
                        >
                          {erromessage7}
                        </Typography>
                      )}
                    </Grid>

                    {userType === "internal" && (
                      <>
                        <Grid item xs={12} sm={12} md={6} lg={6}>
                          <Box variant="div" component="div">
                            <label>
                              Designation
                              <Typography
                                variant="body1"
                                component="span"
                                className="asterisk"
                              >
                                *
                              </Typography>
                            </label>
                            {loading ? (
                              <Skeleton
                                variant="rect"
                                width="100%"
                                height={40}
                              />
                            ) : (
                              <FormControl fullWidth>
                                <Select
                                  value={selectedDesignation}
                                  onChange={handleDesignationChange}
                                  id="demo-simple-select"
                                  size="small"
                                  IconComponent={RiArrowDownSLine}
                                >
                                  {designations.map((category, index) => [
                                    // Render the category as a disabled MenuItem
                                    <MenuItem
                                      key={category[0]}
                                      disabled
                                      value={null}
                                      style={{
                                        color: "var(--green)",
                                        fontWeight: "bold",
                                        opacity: "1",
                                      }}
                                    >
                                      {category[0]}
                                    </MenuItem>,

                                    ...category[1].map((designation) => (
                                      <MenuItem
                                        key={designation.id}
                                        value={designation.id}
                                        style={{
                                          marginLeft: "1rem",
                                          marginRight: "0.5rem",
                                        }}
                                      >
                                        {designation.designation_name}
                                      </MenuItem>
                                    )),
                                    index !== designations.length - 1 && (
                                      <Divider />
                                    ),
                                  ])}
                                </Select>
                              </FormControl>
                            )}
                          </Box>
                          {erromessage5 && (
                            <Typography
                              variant="body1"
                              component="p"
                              className="error"
                            >
                              {erromessage5}
                            </Typography>
                          )}
                          {erromessage7 && (
                            <Typography
                              variant="body1"
                              component="p"
                              className="error"
                            >
                              {erromessage7}
                            </Typography>
                          )}
                        </Grid>
                        <Grid item xs={12} sm={12} md={6} lg={6}>
                          <Box variant="div" component="div">
                            <label>
                              Role{" "}
                              <Typography
                                variant="body1"
                                component="span"
                                className="asterisk"
                              >
                                *
                              </Typography>
                            </label>
                            {loading ? (
                              <Skeleton
                                variant="rect"
                                width="100%"
                                height={40}
                              />
                            ) : (
                              <FormControl fullWidth>
                                <Select
                                  id="demo-simple-select"
                                  value={internalrole}
                                  onChange={handleRoleChange}
                                  size="small"
                                  IconComponent={RiArrowDownSLine}
                                  defaultValue="user"
                                >
                                  <MenuItem value={"admin"}>Admin</MenuItem>
                                  <MenuItem value={"user"}>User</MenuItem>
                                </Select>
                              </FormControl>
                            )}
                          </Box>
                        </Grid>
                      </>
                    )}
                    <Grid item xs={12} sm={12} md={6} lg={6}>
                      <Box variant="div" component="div">
                        <label>
                          Status
                          <Typography
                            variant="body1"
                            component="span"
                            className="asterisk"
                          >
                            *
                          </Typography>
                        </label>
                        {loading ? (
                          <Skeleton variant="rect" width="100%" height={40} />
                        ) : (
                          <FormControl fullWidth>
                            <Select
                              id="demo-simple-select"
                              value={status}
                              onChange={(e) => setStatus(e.target.value)}
                              size="small"
                              IconComponent={RiArrowDownSLine}
                              //defaultValue=""
                            >
                              <MenuItem value={"active"}>Active</MenuItem>
                              <MenuItem value={"archive"}>Archive</MenuItem>
                            </Select>
                          </FormControl>
                        )}
                      </Box>
                    </Grid>
                  </Grid>
                </Box>
              </Grid>
              <Grid item xs={12} sm={12} md={12} lg={6}>
                <Box variant="div" component="div" className="app-details">
                  <Typography variant="h3" component="h3">
                    Application Details{" "}
                    <Typography variant="body1" component="span">
                      (Assign Applications & Role)
                    </Typography>
                  </Typography>
                  {loading ? (
                    Array.from({ length: 3 }).map((_, index) => (
                      <Grid
                        container
                        spacing={2}
                        direction="row"
                        alignItems="center"
                        key={index}
                      >
                        <Grid item xs={12} sm={5} md={5} lg={5}>
                          <Skeleton variant="rect" width="100%" height={40} />
                        </Grid>
                        <Grid item xs={12} sm={7} md={7} lg={7}>
                          <Skeleton variant="rect" width="100%" height={40} />
                        </Grid>
                      </Grid>
                    ))
                  ) : appNames.length > 0 ? (
                    appNames.map((appName) => {
                      const val = selectedAppRoles.find(
                        (item) => item.app_id === appName.id
                      )?.role_id;
                      return (
                        <Grid
                          container
                          mb={2}
                          columnSpacing={2}
                          rowSpacing={0}
                          direction="row"
                          alignItems="center"
                          key={appName.id}
                        >
                          <Grid item xs={12} sm={5} md={5} lg={5}>
                            {/* <label className="empty-label"></label> */}
                            <FormControlLabel
                              control={
                                <Checkbox
                                  disableRipple
                                  checked={
                                    checkedState[appName.app_name]?.isChecked ||
                                    false
                                    // isChecked(appName.id)
                                  }
                                />
                              }
                              onChange={(event) =>
                                handleChange(
                                  appName.app_name,
                                  appName.id,
                                  event
                                )
                              }
                              label={appName.app_name}
                            />
                          </Grid>
                          <Grid item xs={12} sm={7} md={7} lg={7}>
                            <label>
                              Select Role{" "}
                              <Typography
                                variant="body1"
                                component="span"
                                className="asterisk"
                              >
                                *
                              </Typography>{" "}
                            </label>
                            <FormControl fullWidth>
                              <Select
                                id={`${appName.id}`}
                                value={
                                  selectedAppRoles.find(
                                    (item) => item.app_id === appName.id
                                  )?.role_id || null
                                }
                                onChange={(event) =>
                                  handleChangeForApp(appName.app_name, event)
                                }
                                size="small"
                                IconComponent={RiArrowDownSLine}
                                disabled={
                                  !checkedState[appName.app_name]?.isChecked
                                }
                                error={roleErrors[appName.app_name]}
                              >
                                {appName.roles.length > 0
                                  ? appName.roles.map((role) => {
                                      return (
                                        <MenuItem
                                          key={role.id}
                                          value={
                                            !checkedState[appName.app_name]
                                              ?.isChecked
                                              ? ""
                                              : // : checkedState[appName.app_name]
                                                //     ?.counter > 1
                                                // ? ""
                                                role.id
                                          }
                                        >
                                          {role.role_name}
                                        </MenuItem>
                                      );
                                    })
                                  : null}
                              </Select>
                              {roleErrors[appName.app_name] && (
                                <FormHelperText error className="select-error">
                                  Please select a role for this app
                                </FormHelperText>
                              )}
                            </FormControl>
                          </Grid>
                        </Grid>
                      );
                    })
                  ) : (
                    <Typography variant="body1" component="p">
                      No applications available.
                    </Typography>
                  )}
                </Box>
              </Grid>
            </Grid>
          </Box>
        </Box>
        <Box variant="div" component="div" className="footer-btn">
          <Box variant="div" component="div" className="btn-group">
            <Button
              variant="contained"
              size="large"
              className="primary-btn btn"
              onClick={handleSubmit}
              disableRipple
              // disabled={!canSubmit}
            >
              {" "}
              Save
            </Button>
            <Button
              variant="contained"
              size="large"
              className="secondary-btn btn"
              disableRipple
              onClick={() => {
                navigate("/users");
              }}
            >
              {" "}
              Cancel
            </Button>
          </Box>
        </Box>
      </>
      {/* // )} */}
    </>
  );
};
export default Usercreate;
