import React, { useState, useEffect } from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Button from "@mui/material/Button";
import { useDispatch, useSelector } from "react-redux";
import { fetchRoles } from "../../../store/slices/roleSlice";

import {
  RiAddCircleLine,
  RiArrowLeftLine,
  RiDeleteBinLine,
  RiPencilLine,
} from "react-icons/ri";
import { useNavigate, useParams } from "react-router-dom";
import { Box, Typography } from "@mui/material";
import nodata from "../../../assets/images/empty-table.png";
import DeleteModal from "../../../common/deletemodal";
import { DeleteRoles } from "../../../store/slices/roleSlice";
import "./role.scss";
import Skeleton from "@mui/material/Skeleton";

const Rolelist = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [open, setOpen] = useState(false);
  const handleClose = () => setOpen(false);

  const [selectedRoleId, setSelectedRoleId] = useState(null);

  const handleDeleteRole = async (roleId) => {
    try {
      await dispatch(DeleteRoles(roleId));
      handleClose();
    } catch (error) {
    }
  };
  const handleOpen = (roleId) => {
    setSelectedRoleId(roleId);
    setOpen(true);
  };

  const { id: applicationId } = useParams(); 
  const { application, roles, status, error } = useSelector(
    (state) => state.roles
  );

  useEffect(() => {
    dispatch(fetchRoles(applicationId));
  }, [dispatch, applicationId]);

  if (status === "failed") {
    return <div>Error: {error}</div>;
  }

  return (
    <>
      <Box className="content-header application-role-module-wrapper">
        <Typography variant="h2" component="h2" className="title-with-btn">
          <Button
            variant="outlined"
            className="back-btn"
            startIcon={<RiArrowLeftLine />}
            disableRipple
            onClick={() => navigate("/applications")}
          ></Button>{" "}
          Roles of
          <Typography variant="body1" component="span">
            {application?.app_name}
          </Typography>
        </Typography>
        <Box className="content-header-right">
          <Button variant="contained" size="large" className="primary-btn btn add-btn" endIcon={<RiAddCircleLine />} disableRipple onClick={() =>navigate(`/applications/${application.id}/roles/create`)}>Create Role</Button>
        </Box>
      </Box>
      <Box className="content-layout role-list">
        <TableContainer component={Table}>
          <Table
            sx={{ minWidth: 650 }}
            aria-label="table"
            className="listing-table"
          >
            <TableHead>
              <TableRow>
                <TableCell align="left" className="th-role-name">
                  Role Name
                </TableCell>
                <TableCell align="left" className="th-action">
                  Actions
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {status === "loading" ? (
                Array.from({ length: roles?.length || 5 }).map((_, index) => (
                  <TableRow
                    sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                    key={`skeleton-${index}`}
                  >
                    <TableCell>
                      <Skeleton animation="wave" height={20} width="80%" />
                    </TableCell>
                    <TableCell>
                      <Skeleton animation="wave" height={40} width={40} />
                    </TableCell>
                  </TableRow>
                ))
              ) : roles?.length > 0 ? (
                roles.map((role, index) => (
                  <>
                    <TableRow
                      sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                      key={`${role}-${index}`}
                    >
                      <TableCell align="left">{role.role_name}</TableCell>
                      <TableCell align="left">
                        {" "}
                        <Box className="action-btn-wrap">
                          <Button
                            variant="outlined"
                            className="action-btn edit-btn"
                            startIcon={<RiPencilLine />}
                            disableRipple
                            title="Edit"
                            onClick={() =>
                              navigate(
                                `/applications/${application.id}/roles/${role.id}/edit`
                              )
                            }
                          ></Button>

                          <Button
                            variant="outlined"
                            className="action-btn dlt-btn"
                            startIcon={<RiDeleteBinLine />}
                            disableRipple
                            title="Delete"
                            onClick={() => handleOpen(role.id)}
                          ></Button>
                        </Box>
                      </TableCell>
                    </TableRow>
                  </>
                ))
              ) : (
                <Box className="table-no-data">
                  <img src={nodata} alt="logo-main" loading="lazy" />
                  <Typography variant="h5" component="h5">
                    There are no roles currently added for this Application.
                  </Typography>
                  <Typography variant="body1" component="p">
                    You can create a new role for this application by
                  </Typography>
                  <Typography variant="body1" component="p">
                    clicking the button provided below.
                  </Typography>
                  <Button
                    variant="contained"
                    size="large"
                    className="primary-btn btn add-btn"
                    endIcon={<RiAddCircleLine />}
                    disableRipple
                    onClick={() =>
                      navigate(`/applications/${application.id}/roles/create`)
                    }
                  >
                    Create Role
                  </Button>
                </Box>
              )}
            </TableBody>
          </Table>
        </TableContainer>
      </Box>
      <DeleteModal
        open={open}
        handleClose={handleClose}
        roleId={selectedRoleId}
        handleDeleteRole={handleDeleteRole}
      />
    </>
  );
};

export default Rolelist;
