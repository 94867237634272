import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import getTokenFromLocalStorage from "../../utils/token";
import { setFeedback } from "./feedbackSlice";
import axios from "axios";
axios.defaults.withCredentials = true;
//import { useDispatch } from 'react-redux';

// Create the asynchronous thunk to fetch application data from the API
export const fetchEditRoles = createAsyncThunk(
  "applications/fetchEditRoles",
  async ({ roleData, editroleId }, { rejectWithValue, dispatch }) => {
    try {
      const token = getTokenFromLocalStorage();
      const response = await axios.patch(
        `${process.env.REACT_APP_API_URL}/roles/${editroleId}`,
        roleData,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );
      if (response.data.status) {
        return response.data.data;
      } else {
        return rejectWithValue(response.data.message);
      }
    } catch (error) {
      dispatch(
        setFeedback({
          status: "fail",
          message: error.response.data.message,
          code: error.response.status,
        })
      );
      return rejectWithValue(error.response.data);
    }
  }
);

export const fetchRoles = createAsyncThunk(
  "roles/fetchRoles",
  async (applicationId, { rejectWithValue, dispatch }) => {
    try {
      const token = getTokenFromLocalStorage();
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/applications/${applicationId}/roles?fields=id,role_name&appfields=id,app_name`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );
      if (response.data.status) {
        return response.data.data;
      } else {
        return rejectWithValue(response.data.message);
      }
    } catch (error) {
      dispatch(
        setFeedback({
          status: "fail",
          message: error.response.data.message,
          code: error.response.status,
        })
      );
      return rejectWithValue(error.response.data);
    }
  }
);

export const fetchModules = createAsyncThunk(
  "applications/fetchModules",
  async (editroleId, { rejectWithValue, dispatch }) => {
    try {
      const token = getTokenFromLocalStorage();
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/applications/${editroleId}/modules?app_fields=id,app_name&module_fields=id,module_name,app_id,permission`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );
      if (response.data.status) {
        return response.data.data;
      } else {
        return rejectWithValue(response.data.message);
      }
    } catch (error) {
      dispatch(
        setFeedback({
          status: "fail",
          message: error.response.data.message,
          code: error.response.status,
        })
      );
      return rejectWithValue(error.response.data);
    }
  }
);

export const fetchViews = createAsyncThunk(
  "applications/fetchApplications",
  async (viewId, { rejectWithValue, dispatch }) => {
    try {
      const token = getTokenFromLocalStorage();
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/applications/${viewId}?fields=id,app_name`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );
      if (response.data.status) {
        return response.data.data;
      } else {
        return rejectWithValue(response.data.message);
      }
    } catch (error) {
      dispatch(
        setFeedback({
          status: "fail",
          message: error.response.data.message,
          code: error.response.status,
        })
      );
      return rejectWithValue(error.response.data);
    }
  }
);

const initialState = {
  role: null, // Initialize role as null or an empty object depending on your use case
  error: null,
  roles: [],
  application: null,
  modules: [],
  rolesModules: [],
};

const roleeditSlice = createSlice({
  name: "roleedit",
  initialState,
  reducers: {
    roleEditSuccess: (state, action) => {
      state.role = action.payload;
      state.error = null;
    },
    roleEditFailure: (state, action) => {
      state.role = null;

      state.error = action.payload;
    },
  },
  extraReducers: (builder) => {
    // Handle additional reducers from other actions if needed
    builder
      .addCase(fetchModules.fulfilled, (state, action) => {
        state.application = action.payload.application;
        state.modules = action.payload.modules;
      })
      .addCase(fetchRoles.fulfilled, (state, action) => {
        state.roles = action.payload.roles;
      })
      .addCase(fetchViews.fulfilled, (state, action) => {
        state.rolesModules = action.payload.roles;
      });
  },
});

// Export action creators
export const { roleEditSuccess, roleEditFailure } = roleeditSlice.actions;

export default roleeditSlice.reducer;
