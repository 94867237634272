import { Box, Grid, TextField, Typography, Skeleton } from "@mui/material";
import React, { useState, useEffect } from "react";
import Button from "@mui/material/Button";
import { RiArrowLeftLine } from "react-icons/ri";
import { useNavigate, useParams } from "react-router-dom";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import ToggleButton from "@mui/material/ToggleButton";
import ToggleButtonGroup from "@mui/material/ToggleButtonGroup";
import { useDispatch, useSelector } from "react-redux";
import {
  fetchEditRoles,
  fetchModules,
} from "../../../store/slices/roleeditSlice";
import { fetchRoles } from "../../../store/slices/roleeditSlice";
import { fetchViews } from "../../../store/slices/roleeditSlice";
import "./role.scss";
import { successToast } from "../../../responses/successToast";

const Roleedit = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const roleedit = useSelector((state) => state.roleedit);
  const { application } = useSelector((state) => state.rolecreate);
  const [editedPermissions, setEditedPermissions] = useState({});

  const { id: editroleId } = useParams();
  const appId = useSelector((state) => state.roles.appId);
  const roles = useSelector((state) => state.roleedit.roles);
  const rolesModules = useSelector((state) => state.roleedit.rolesModules);

  const [setRoleNameConflict] = useState(false);

  const selectedRole = roles.find((role) => role.id === Number(editroleId));
  const selectedRoleModules = rolesModules?.find(
    (role) => role.id === Number(editroleId)
  );

  const initialModuleToggles = selectedRoleModules?.modules_permissions?.reduce(
    (acc, module) => {
      acc[module.module_id] = module.permissions;
      return acc;
    },
    {}
  );
  const [moduleToggles, setModuleToggles] = useState(
    initialModuleToggles || {}
  );
  const [name, setName] = useState(selectedRole?.role_name || "");
  const [errormessage, setErrorMessage] = useState("");
  const [formSubmitted] = useState(false);
  const [loading, setLoading] = useState(true);
  const [/*initialRoleName*/, setInitialRoleName] = useState("");

  const generateToggleButtons = (permissionId) => {
    if (permissionId === "2") {
      return [
        <ToggleButton key="view" value="view" aria-label="centered">
          View Only
        </ToggleButton>,
        <ToggleButton key="none" value="none" aria-label="justified">
          None
        </ToggleButton>,
      ];
    } else {
      return [
        <ToggleButton key="full" value="full" aria-label="left aligned">
          Full
        </ToggleButton>,
        <ToggleButton key="add_update" value="add_update" aria-label="centered">
          Add & Update Only
        </ToggleButton>,
        <ToggleButton key="view" value="view" aria-label="right aligned">
          View Only
        </ToggleButton>,
        <ToggleButton key="none" value="none" aria-label="justified">
          None
        </ToggleButton>,
      ];
    }
  };
  const handleAddRole = async () => {
    const permissionsData = Object.keys(editedPermissions).map((moduleId) => ({
      module_id: moduleId,
      permission: editedPermissions[moduleId],
    }));

    const roleData = {
      role_name: name,
      app_id: appId,
      permissions: permissionsData,
    };
    if (!name) {
      setErrorMessage("Please enter a role name");
      return;
    }

    try {
      await dispatch(fetchEditRoles({ roleData, editroleId })).then(
        (response) => {
          if (response.meta.requestStatus === "fulfilled") {
            successToast("Role Updated Successfully");
            navigate(`/applications/${appId}/roles`);
          } else if (response.meta.requestStatus === "rejected") {
            console.error("Error editing role:", response.payload);
          }
        }
      );
    } catch (error) {
      if (error.response && error.response.status === 500) {
        setRoleNameConflict(true);
      }

    }
  };

  useEffect(() => {
    if (roleedit.status === true) {
      navigate(`/applications/${appId}/roles`);
    }
  }, [roleedit.status]);

  useEffect(() => {
    setLoading(false);
    setInitialRoleName(selectedRole?.role_name || "");
  }, []);

  useEffect(() => {
    dispatch(fetchModules(appId));
  }, []);

  useEffect(() => {
    dispatch(fetchRoles(appId));
  }, []);

  useEffect(() => {
    dispatch(fetchViews(appId));
  }, []);

  useEffect(() => {
    const initialEditedPermissions =
      selectedRoleModules?.modules_permissions?.reduce((acc, module) => {
        acc[module.module_id] = module.permissions;
        return acc;
      }, {});
    setEditedPermissions(initialEditedPermissions || {});
  }, [selectedRoleModules?.modules_permissions]);
  useEffect(() => {
    const updatedEditedPermissions = Object.keys(moduleToggles).reduce(
      (acc, moduleId) => {
        acc[moduleId] = moduleToggles[moduleId];
        return acc;
      },
      {}
    );
    setEditedPermissions(updatedEditedPermissions);
  }, [moduleToggles]);
  useEffect(() => {
    const initialModuleToggles =
      selectedRoleModules?.modules_permissions?.reduce((acc, module) => {
        acc[module.module_id] = module.permissions;
        return acc;
      }, {});
    setModuleToggles(initialModuleToggles || {});
  }, [selectedRoleModules]);

  if (!selectedRole || !selectedRoleModules) {
    return;
  }

  if (!appId) {
    return;
  }

  const handleAlignment = (moduleId, newValue) => {
    const updatedValue = newValue === "full" ? "full" : newValue;

    setModuleToggles((prevToggles) => ({
      ...prevToggles,
      [moduleId]: updatedValue,
    }));
    setEditedPermissions((prevPermissions) => ({
      ...prevPermissions,
      [moduleId]: updatedValue,
    }));
  };

  return (
    <>
      <Box className="content-header">
        <Typography variant="h2" component="h2" className="title-with-btn">
          {" "}
          <Button
            variant="outlined"
            className="back-btn"
            startIcon={<RiArrowLeftLine />}
            disableRipple
            onClick={() => navigate(`/applications/${appId}/roles`)}
          ></Button>{" "}
          Edit Role of {" "} {application.app_name}
        </Typography>
      </Box>
      <Box className="content-layout role-form-wrapper">
        <Box variant="div" component="div" className="create-role-wrap p-24">
          <Box variant="div" component="div" className="role-detail">
            <Typography variant="h3" component="h3">
              Role Details
            </Typography>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={6} md={6}>
                <Box variant="div" component="div">
                  <label>
                    Role Name{" "}
                    <Typography
                      variant="body1"
                      component="span"
                      className="asterisk"
                    >
                      *
                    </Typography>
                  </label>
                  {loading ? (
                    <Skeleton variant="text" width={200} height={40} />
                  ) : (
                    <TextField
                      fullWidth
                      id="outlined-basic"
                      label=""
                      variant="outlined"
                      size="small"
                      placeholder="Enter Role Name"
                      value={name}
                      onChange={(e) => {
                        setName(e.target.value);
                        if (e.target.value) {
                          setErrorMessage("");
                        }
                      }}
                    />
                  )}
                  {errormessage && <div className="error">{errormessage}</div>}
                </Box>
              </Grid>
            </Grid>
          </Box>
        </Box>
        <Box variant="div" component="div" className="create-role-wrap">
          <Box variant="div" component="div">
            <Typography variant="h3" component="h3" className="role-title" sx={{ marginBottom: "20px !important" }}>
              Role Permissions
            </Typography>
            <Box className="role-list-wrapper-scroll">
              <Table
                sx={{ minWidth: 380 }}
                aria-label="table"
                className="listing-table role-list-wrapper"
              >
                <TableHead>
                  <TableRow>
                    <TableCell align="left">Module</TableCell>
                    <TableCell align="left" className="permission-th">
                      Permissions
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {loading
                    ? Array.from({ length: 4 }).map((_, index) => (
                      <TableRow key={index}>
                        <TableCell colSpan={2}>
                          <Skeleton
                            variant="text"
                            width="100%"
                            height={40}
                          />
                        </TableCell>
                      </TableRow>
                    ))
                    : selectedRoleModules.modules_permissions.length > 0
                      ? selectedRoleModules.modules_permissions.map(
                        (module) => (
                          <TableRow
                            key={module.id}
                            sx={{
                              "&:last-child td, &:last-child th": {
                                border: 0,
                              },
                            }}
                          >
                            <TableCell align="left">
                              <Typography variant="body1" component="p">
                                {module.module_name}
                              </Typography>
                            </TableCell>
                            <TableCell align="left">
                              <Box
                                variant="div"
                                component="div"
                                className="permission-button"
                              >
                                <ToggleButtonGroup
                                  value={
                                    moduleToggles[module.module_id] ?? "full"
                                  }
                                  exclusive
                                  onChange={(e, newValue) =>
                                    handleAlignment(
                                      module.module_id,
                                      newValue
                                    )
                                  }
                                  aria-label="text alignment"
                                >
                                  {generateToggleButtons(module.permission)}
                                </ToggleButtonGroup>
                              </Box>
                            </TableCell>
                          </TableRow>
                        )
                      )
                      : null}
                </TableBody>
              </Table>
            </Box>
          </Box>
        </Box>
      </Box>
      <Box variant="div" component="div" className="footer-btn">
        <Box variant="div" component="div" className="btn-group">
          <Button
            variant="contained"
            size="large"
            className="primary-btn btn"
            disableRipple
            onClick={async () => {
              await handleAddRole();
            }}
          >
            {" "}
            Save
          </Button>
          <Button
            variant="contained"
            size="large"
            className="secondary-btn btn"
            disableRipple
            onClick={() => navigate(`/applications/${appId}/roles`)}
          >
            {" "}
            Cancel
          </Button>
        </Box>
      </Box>
    </>
  );
};

export default Roleedit;
