import { Box, Fade, Modal, Typography } from "@mui/material";
import React from "react";
import Button from "@mui/material/Button";
import { useDispatch } from "react-redux";
import getTokenFromLocalStorage from "../utils/token";
import getIdFromLocalStorage from "../utils/userid";
import { successToast } from "../responses/successToast";
import { deleteProfileImgById } from "../store/slices/deleteProfileImgSlice";
import { fetchUserById } from "../store/slices/userByIdSlice";

const RemovepicModal = ({ open, handleClose }) => {
  const dispatch = useDispatch();
  const token = getTokenFromLocalStorage();
  const userId = getIdFromLocalStorage();
  const submitHandler = async () => {
    try {
      await dispatch(deleteProfileImgById({ userId, token }));
      successToast("Profile Image removed");
      handleClose();
      await dispatch(fetchUserById(userId, token));
    } catch (error) {
    }
  };

  return (
    <>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={open}
        onClose={handleClose}
        closeAfterTransition
      >
        <Fade in={open}>
          <Box className="modal-wrapper modal-ui">
            <Box className="modal-body">
              <Box variant="div" component="div" className="title">
                <Typography variant="h3" component="h3">
                  Do you want to remove your current Profile Picture?
                </Typography>
                <Typography variant="body1" component="p">
                  If you remove your profile picture, it will be replaced with a
                  default avatar.
                </Typography>
              </Box>
              <Box variant="div" component="div" className="footer">
                <Box variant="div" component="div" className="btn-group">
                  <Button
                    variant="contained"
                    size="large"
                    className="primary-btn btn"
                    disableRipple
                    onClick={submitHandler}
                  >
                    {" "}
                    Remove Picture
                  </Button>
                  <Button
                    variant="contained"
                    size="large"
                    className="secondary-btn btn"
                    disableRipple
                    onClick={handleClose}
                  >
                    {" "}
                    Cancel
                  </Button>
                </Box>
              </Box>
            </Box>
          </Box>
        </Fade>
      </Modal>
    </>
  );
};

export default RemovepicModal;
