import { createSlice } from '@reduxjs/toolkit';
import { setFeedback } from "../../store/slices/feedbackSlice";
import axios from 'axios';
axios.defaults.withCredentials = true;

const initialState = {
  isLoading: false,
  success: false,
  error: null,
  data: null,
};

const verifyForgotPasswordTokenSlice = createSlice({
  name: 'verifyForgotPasswordToken',
  initialState,
  reducers: {
    verifyForgotPasswordTokenStart: (state) => {
      state.isLoading = true;
      state.success = false;
      state.error = null;
      state.data = null;
    },
    verifyForgotPasswordTokenSuccess: (state, action) => {
      state.isLoading = false;
      state.success = true;
      state.error = null;
      state.data = action.payload;
    },
    verifyForgotPasswordTokenFailure: (state, action) => {
      state.isLoading = false;
      state.success = false;
      state.error = action.payload;
      state.data = null;
    },
  },
});

export const {
  verifyForgotPasswordTokenStart,
  verifyForgotPasswordTokenSuccess,
  verifyForgotPasswordTokenFailure,
} = verifyForgotPasswordTokenSlice.actions;

export default verifyForgotPasswordTokenSlice.reducer;

export const verifyForgotPasswordToken = (token) => async (dispatch) => {
    try {
      dispatch(verifyForgotPasswordTokenStart());
  
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/users/verify`,
        { token },
        {
          headers: {
            'Content-Type': 'application/json',
          },
        }
      );
  
      const data = response.data;
      dispatch(verifyForgotPasswordTokenSuccess(data));
      return { success: true, message: data.data};
    } catch (error) {
      dispatch(verifyForgotPasswordTokenFailure(error.response.data.message));
      dispatch(
        setFeedback({
          status: 'fail',
          message: error.response.data.message,
          code: error.response.data.code,
        })
      );
      return { success: false, error: error.message };
    }
  };