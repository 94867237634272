import { createSlice } from "@reduxjs/toolkit";
import { setFeedback } from "./feedbackSlice";
import axios from "axios";
axios.defaults.withCredentials = true;

const initialState = {
  data: {
    id: "",
    application_details: [],
  },
  isLoading: false,
  error: null,
};

const userDashboardSlice = createSlice({
  name: "userDashboard",
  initialState,
  reducers: {
    toggleLoading(state) {
      state.isLoading = !state.isLoading;
    },
    storeData(state, action) {
      state.isLoading = false;
      state.data.id = action.payload.id;
      state.data.application_details = action.payload.application_details;
    },
    getDataFailure(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },
  },
});

export const { storeData, toggleLoading, getDataFailure } =
  userDashboardSlice.actions;

export default userDashboardSlice.reducer;

export const getApplicationsData = (user_id, token) => async (dispatch) => {
  try {
    dispatch(toggleLoading());

    const response = await axios.get(
      `${process.env.REACT_APP_API_URL}/users/${user_id}/applications`,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      }
    );
    if (response.data.status) {
      dispatch(storeData(response.data.data));
      return { success: true, message: response.data.message };
    } else {
    }
  } catch (error) {
    dispatch(getDataFailure(error.message));
    dispatch(
      setFeedback({
        status: "fail",
        message: error.response.data.message,
        code: error.response.status,
      })
    );
    return { success: false, error: error.message };
  } finally {
    dispatch(toggleLoading());
  }
};
