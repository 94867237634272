import { Box, Fade, Modal, Typography } from "@mui/material";
import React from "react";
import Button from "@mui/material/Button";
import { deleteUserById } from "../store/slices/deleteByIdSlice";
import getTokenFromLocalStorage from "../utils/token";
import { useDispatch } from "react-redux";
import { successToast } from "../responses/successToast";
import { fetchUsersByType } from "../store/slices/userSlice";

const DeleteUserModal = ({ open, handleClose, selectedUserId, value }) => {
  const dispatch = useDispatch();
  const token = getTokenFromLocalStorage();
  const userId = selectedUserId;
  const submitHandler = () => {
    dispatch(deleteUserById({ userId, token }))
      .unwrap()
      .then((result) => {
        const { success, message } = result;
        if (success) {
          successToast(message);
          dispatch(
            fetchUsersByType({
              userType: "",
              searchQuery: "",
              page: 1,
              status: "archive",
            })
          );
          handleClose();
        } else {
        }
      })
      .catch((error) => {
        console.error("Failed to delete user:", error);
      });
  };

  return (
    <>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={open}
        onClose={handleClose}
        closeAfterTransition
      >
        <Fade in={open}>
          <Box className="modal-wrapper modal-ui">
            <Box className="modal-body">
              <Box variant="div" component="div" className="title">
                <Typography variant="h3" component="h3">
                  Do you want to delete this user?
                </Typography>
                <Typography variant="body1" component="p">
                  This action can’t be undone.
                </Typography>
              </Box>
              <Box variant="div" component="div" className="footer">
                <Box variant="div" component="div" className="btn-group">
                  <Button
                    variant="contained"
                    size="large"
                    className="primary-btn btn"
                    disableRipple
                    onClick={() => {
                      submitHandler();
                    }}
                  >
                    {" "}
                    Delete User
                  </Button>
                  <Button
                    variant="contained"
                    size="large"
                    className="secondary-btn btn"
                    disableRipple
                    onClick={handleClose}
                  >
                    {" "}
                    Cancel
                  </Button>
                </Box>
              </Box>
            </Box>
          </Box>
        </Fade>
      </Modal>
    </>
  );
};

export default DeleteUserModal;
